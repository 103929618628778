<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSTable
    :columns="tableHeaders"
    data-automation="user-api-keys-table"
  >
    <RSTableRow
      v-for="(apiKey, index) in keys"
      :key="apiKey.id"
      :row-id="apiKey.id"
      :deletable="true"
      :delete-button-label="$t('apiKeys.table.deleteKeyLabel', { name: apiKey.name })"
      :row-label="$t('apiKeys.table.rowLabel', { name: apiKey.name })"
      :data-automation="`api-key-item-${index}`"
      @delete="() => $emit('delete-intention', apiKey)"
    >
      <RSTableCell
        :cell-id="`api-key-${index}-name`"
        :data-automation="`api-key-${index}-name`"
        :has-icon="true"
      >
        <span class="key-icon typeAPIKey" />
        {{ apiKey.name }}
      </RSTableCell>
      <RSTableCell
        :cell-id="`api-key-${index}-key`"
        :data-automation="`api-key-${index}-key`"
      >
        {{ apiKey.key }}
      </RSTableCell>
      <RSTableCell
        :cell-id="`api-key-${index}-created`"
        :data-automation="`api-key-${index}-created`"
      >
        <time>{{ apiKey.created }}</time>
      </RSTableCell>
      <RSTableCell
        :cell-id="`api-key-${index}-lastused`"
        :data-automation="`api-key-${index}-lastused`"
      >
        <time>{{ apiKey.lastUsed }}</time>
      </RSTableCell>
    </RSTableRow>
  </RSTable>
</template>

<script>
import RSTable from '@/elements/RSTable';
import RSTableRow from '@/elements/RSTableRow';
import RSTableCell from '@/elements/RSTableCell';

export default {
  name: 'KeysTable',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    keys: {
      type: Array,
      required: true,
    },
  },
  emits: ['delete-intention'],
  data() {
    return {
      tableHeaders: [
        { name: 'name', label: this.$t('apiKeys.table.cols.name') },
        { name: 'key', label: this.$t('apiKeys.table.cols.key') },
        { name: 'created', label: this.$t('apiKeys.table.cols.created') },
        { name: 'lastUsed', label: this.$t('apiKeys.table.cols.lastUsed'), size: 2 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.key-icon {
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  height: 2rem;
  padding-right: 1rem;
  vertical-align: middle;
  width: 2rem;
}
</style>
