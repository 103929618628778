<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__publish-rconsole wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.publishRConsole.title') }}
      </StepHeading>
      <p>
        {{ $t('publish.publishRConsole.body.preamble') }}
      </p>

      <i18n-t
        keypath="publish.publishRConsole.body.account"
        tag="p"
      >
        <template #connectRStudio>
          <a
            :href="publishingDocs"
            target="_blank"
          >
            {{ $t('publish.publishRConsole.body.connectRStudio') }}
          </a>
        </template>
      </i18n-t>

      <p>
        {{ $t('publish.publishRConsole.body.publish') }}
      </p>

      <div class="wizard-panel__copy">
        <pre class="wizard-panel__copy-code"><code>{{ publishCode }}</code></pre>
        <CopyButton
          class="wizard-panel__copy-button"
          :copy-text="publishCode"
        />
      </div>

      <p class="wizard-panel__notice">
        <strong>{{ $t('publish.publishRConsole.warning.note') }}</strong>
        {{ $t('publish.publishRConsole.warning.text') }}
      </p>

      <div class="wizard-panel__learn-more">
        <ul class="wizard-panel__links">
          <li
            v-for="link in links"
            :key="link.title"
            class="wizard-panel__link"
          >
            <a
              :href="link.target"
              target="_blank"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import { serverURL, docsPath } from '@/utils/paths';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PublishRConsole',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  data() {
    return {
      links: [
        { title: this.$t('publish.publishRConsole.links.publish'), target: docsPath('user/publishing-overview') },
        { title: this.$t('publish.publishRConsole.links.package'), target: 'https://rstudio.github.io/rsconnect/' },
      ],
    };
  },
  computed: {
    publishingDocs() {
      return docsPath('user/publishing-overview');
    },
    apiKey() {
      return this.$t('publish.keys.CONNECT_API_KEY');
    },
    serverAddress() {
      return serverURL('/');
    },
    publishCode() {
      return `rsconnect::deployApp()`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.wizard-panel {
  &__copy {
    display: flex;

    &-code {
      flex-grow: 1;
       margin-bottom: 0;
    }

    &-button {
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  &__learn-more {
    margin-top: 1rem;
  }
}
</style>
