<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="tags-selected-filters">
    <TagFilterCategory
      v-for="category in tags"
      :key="category.id"
      :category="category"
      @deselect="$emit('deselect', { ...$event })"
    />
    <a
      v-show="hasSelectedTags"
      href="#"
      class="rs-link"
      @click.prevent="$emit('deselect-all')"
      @keydown.prevent.space="$emit('deselect-all')"
      @keydown.prevent.enter="$emit('deselect-all')"
    >
      {{ $t('common.actions.clearAllTags') }}
    </a>
  </div>
</template>

<script>
import TagFilterCategory from './TagFilterCategory';

export default {
  name: 'TagsSelectedFilters',
  components: {
    TagFilterCategory,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  emits: ['deselect', 'deselect-all'],
  computed: {
    hasSelectedTags() {
      return (
        (this.tags.length === 1 && this.tags[0].tags.length > 1) ||
        this.tags.length > 1
      );
    },
  },
};
</script>

<style scoped lang="scss">
.tags-selected-filters {
  display: flex;
  flex-wrap: wrap;
  a {
    align-self: center;
    line-height: 2rem;
    margin-bottom: 0.75rem;
    padding-top: 0.5rem;
  }
}
</style>
