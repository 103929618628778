// Copyright (C) 2024 by Posit Software, PBC.

/**
 * Factory function to get content type filters.
 * @returns {Array} Array of content type filters as { label, value }
 */
export const contentTypeFilterOptions = () => ([
  {
    label: 'Unknown',
    value: 'unknown',
  },
  {
    label: 'Shiny',
    value: 'shiny',
  },
  {
    label: 'Shiny Rmd',
    value: 'rmd-shiny',
  },
  {
    label: 'Static Rmd',
    value: 'rmd-static',
  },
  {
    label: 'Static',
    value: 'static',
  },
  {
    label: 'Plumber API',
    value: 'api',
  },
  {
    label: 'Static Jupyter',
    value: 'jupyter-static',
  },
  {
    label: 'Python Shiny',
    value: 'python-shiny',
  },
  {
    label: 'Python API',
    value: 'python-api',
  },
  {
    label: 'Python Dash',
    value: 'python-dash',
  },
  {
    label: 'Python Streamlit',
    value: 'python-streamlit',
  },
  {
    label: 'Python Bokeh',
    value: 'python-bokeh',
  },
  {
    label: 'Python FastAPI',
    value: 'python-fastapi',
  },
  {
    label: 'Shiny Quarto',
    value: 'quarto-shiny',
  },
  {
    label: 'Static Quarto',
    value: 'quarto-static',
  },
  {
    label: 'Jupyter Voila',
    value: 'jupyter-voila',
  },
]);

/**
 * Factory function to get sorting options.
 * @returns {Array} Array of sorting options as { label, value }
 */
export const sortOptions = () => ([
  // TODO: these will change when the API terms get updated
  {
    label: 'Newest',
    value: 'created_time-desc',
  },
  {
    label: 'Oldest',
    value: 'created_time-asc',
  },
  {
    label: 'Recently Deployed',
    value: 'last_deployed_time-desc',
  },
  {
    label: 'Latest Recently Deployed',
    value: 'last_deployed_time-asc',
  },
]);
