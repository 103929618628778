<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="app"
    :class="cssClass"
  >
    <div v-if="loaded">
      <main
        id="main"
      >
        <router-view name="header" />
        <AdminView v-if="adminPage">
          <router-view name="adminContainer" />
        </AdminView>
        <router-view />
        <router-view name="footer" />
      </main>

      <UserPanel v-if="showUserPanel" />
      <NavPanel v-if="showNavPanel" />
    </div>
    <div
      v-else
      class="spinner-center-holder"
    >
      <Spinner />
    </div>

    <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
    <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
    <!--
      Disabling this linting rules for the following element because those key event expectations
      are met within the indivicual panels handling the "esc" key.
      This one is to maintain backwards funtionality that panels close when clicking outside.
    -->
    <div
      id="mainCover"
      data-automation="main-cover"
      @click="hideSidePanels"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { SERVER_SETTINGS_LOAD } from '@/store/modules/server';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';
import {
  SHOW_NAV_PANEL,
  SHOW_USER_PANEL,
  SHRINK_HEADER
} from '@/store/modules/navigation';
import AdminView from '@/views/admin/AdminView/AdminView';
import Spinner from '@/components/Spinner';
import NavPanel from '@/components/NavPanel/NavPanel';
import UserPanel from '@/components/UserPanel/UserPanel';

const FIVE_MINUTES = 300 * 1_000;

export default {
  name: 'App',
  components: {
    AdminView,
    Spinner,
    NavPanel,
    UserPanel,
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
      serverSettingsLoaded: state => state.server.loaded,
      currentUserLoaded: state => state.currentUser.loaded,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      lastKnownSession: state => state.currentUser.lastKnownSession,
      shrinkHeader: state => state.navigation.shrinkHeader,
      showUserPanel: state => state.navigation.showUserPanel,
      showNavPanel: state => state.navigation.showNavPanel,
    }),
    loaded() {
      return this.serverSettingsLoaded && this.currentUserLoaded;
    },
    adminPage() {
      return this.$route.name?.includes('admin');
    },
    cssClass() {
      const className = [];
      if (this.showNavPanel) {
        className.push('showNavPanel');
      } else if (this.showUserPanel) {
        className.push('showUserPanel');
      }
      if (this.shrinkHeader || this.isAuthenticated) {
        className.push('shrinkHeader');
      }
      return className;
    },
  },
  watch: {
    '$route.name'() {
      // Don't allow large header except on welcome route
      if (this.$route.name !== 'welcome') {
        if (!this.shrinkHeader) {
          this.shrinkMainHeader(true);
        }
      }
    }
  },
  created() {
    this.checkServerAndWarnings();

    if (!this.currentUserLoaded) {
      this.loadCurrentUser();
    }

    this.periodicSessionCheck();
  },
  methods: {
    ...mapActions({
      statusErrorMessage: SHOW_ERROR_MESSAGE,
      loadServerSettings: SERVER_SETTINGS_LOAD,
      loadCurrentUser: CURRENT_USER_LOAD,
    }),
    ...mapMutations({
      showingUserPanel: SHOW_USER_PANEL,
      showingNavPanel: SHOW_NAV_PANEL,
      shrinkMainHeader: SHRINK_HEADER
    }),
    hideSidePanels() {
      this.showingNavPanel(false);
      this.showingUserPanel(false);
    },
    async checkServerAndWarnings() {
      if (!this.serverSettingsLoaded) {
        await this.loadServerSettings();
      }
      if (this.serverSettings?.httpWarning && window.location.protocol !== 'https') {
        // This is a warning, not an error, but we show the message as an error
        // To have more predominant color schemes for this kind of message.
        this.statusErrorMessage({ message: this.$t('notices.httpWarning') });
      }
    },
    periodicSessionCheck() {
      // Session expired check to run every 5 mins.
      // If there was an existing session and expires,
      // user is redirected to login again with a ?url to get back
      // to where it was.
      const checkFrequency = FIVE_MINUTES;
      const sessionCheck = async() => {
        // Do not do anything if there was not a previous session.
        if (!this.lastKnownSession) {
          return;
        }

        const lastKnownUser = this.currentUser.guid;

        await this.loadCurrentUser(false);
        // If user lost the session, redirect to login
        if (!this.isAuthenticated) {
          this.$router.push({
            name: 'login_view',
            query: {
              url: window.location.href,
            },
          });
        } else if (lastKnownUser !== this.currentUser.guid) {
          // Now we are a different user, reload the page.
          window.location.reload();
        }
      };

      // We were elsewhere, check session when getting back
      window.onfocus = sessionCheck;

      // Start the periodic check
      setInterval(sessionCheck, checkFrequency);
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner-center-holder {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  & > * {
    height: 10rem;
    width: 10rem;
  }
}
</style>
