<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="add-item-input"
  >
    <div class="add-item-input__icon" />
    <input
      ref="input"
      :value="modelValue"
      :aria-label="placeholder"
      :placeholder="placeholder"
      class="add-item-input__input"
      type="text"
      @click.stop
      @keypress.enter="$emit('submit')"
      @blur="$emit('blur')"
      @input="updateValue"
    >
  </div>
</template>

<script>
export default {
  name: 'AddItemInput',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  emits: [
    'blur',
    'input',
    'submit',
    'update:modelValue',
  ],
  compatConfig: { COMPONENT_V_MODEL: false },
  methods: {
    updateValue(ev) {
      this.$emit('input', ev.target.value);
      this.$emit('update:modelValue', ev.target.value);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.add-item-input {
  align-content: center;
  display: flex;

  &__icon {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 20px 60px;
    height: 29px;
    margin-right: 4px;
    width: 20px;
    background-image: url(/images/elements/iconNewTag.svg);
  }

  &__input {
    background: transparent;
    border: none;
    padding: 6px;
    text-overflow: ellipsis;

    &:focus {
      background: $color-white;
    }
  }
}
</style>
