<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rs-action">
    <RSButton
      :label="$t('users.title.confirmAccount')"
      data-automation="rp-trigger"
      @click="confirmAccount"
    />
    <CopyTextDialog
      v-if="copyUrlDialog.show"
      :title="$t('users.linkCopy.confirmAccount.title')"
      :description="$t('users.help.confirmAccount')"
      :copied-message="$t('users.linkCopy.confirmAccount.copiedMessage')"
      :text-label="$t('users.linkCopy.confirmAccount.textLabel')"
      :text="copyUrlDialog.url"
      @close="copyUrlDialog.show = false"
    />

    <RSModal
      v-if="sendEmailDialog.show"
      :active="true"
      :subject="$t('users.title.confirmAccount')"
      @close="sendEmailDialog.show = false"
    >
      <template #content>
        {{ $t('users.email.confirmAccount', { email: userProfile.email }) }}
      </template>
      <template #controls>
        <RSButton
          :label="$t('users.email.send')"
          data-automation="rp-send"
          @click="sendEmail"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

import { sendOrGetAccountConfirmationLink } from '@/api/authentication';
import CopyTextDialog from '@/components/CopyTextDialog';
import { mapMutations, mapActions } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';

export default {
  name: 'ConfirmAccount',
  components: {
    CopyTextDialog,
    RSButton,
    RSModal,
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
    canEmail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copyUrlDialog: {
        show: false,
        url: '',
      },
      sendEmailDialog: {
        show: false,
      },
    };
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    confirmAccount() {
      if (this.canEmail) {
        this.sendEmailDialog.show = true;
      } else {
        // show copyable 'reset password' link
        return sendOrGetAccountConfirmationLink(this.userProfile.guid)
          .then(({ url }) => {
            this.copyUrlDialog.url = url;
            this.copyUrlDialog.show = true;
          })
          .catch(e => {
            this.copyUrlDialog.show = false;
            this.setErrorMessageFromAPI(e);
          });
      }
    },
    sendEmail() {
      return sendOrGetAccountConfirmationLink(this.userProfile.guid)
        .then(() => {
          this.setInfoMessage({
            message: this.$t('users.email.sentConfirmAccount', {
              email: this.userProfile.email,
            }),
          });
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.sendEmailDialog.show = false;
        });
    },
  },
};
</script>
