<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      :message="$t('appSettings.info.usage.loading')"
      :show-close="false"
      type="activity"
      data-automation="usage-loading"
    />
    <div
      v-if="!loading"
      class="formSection"
      data-automation="usage-graph-panel"
    >
      <div class="rsc-row header flex">
        <div>{{ $t('appSettings.info.usage.thirtyDaysUsage') }}</div>
        <div class="actionBar inline tight">
          <div
            :class="{'infoToggle': true, 'selected': usageInfoToggled}"
            tabindex="0"
            role="button"
            aria-label="More Information"
            @click.prevent="toggleUsageInfo"
            @keydown.space.prevent="toggleUsageInfo"
          />
        </div>
      </div>
      <div
        v-show="usageInfoToggled"
        id="runasSettingsNote"
        class="subPanel"
        data-automation="usage-graph-info"
      >
        <div class="settingsNote">
          <p>
            {{ $t('appSettings.info.usage.help') }}
            {{ $t(`appSettings.info.usage.${apiType}Help`) }}
          </p>
          <p v-if="partial">
            {{ $t('appSettings.info.usage.partial') }}
          </p>
          <p>
            {{ $t('appSettings.info.usage.cookbookRef') }}
            <a
              target="_blank"
              :href="usageDocs(apiType)"
            >{{ $t('appSettings.info.usage.apiCookbook') }}</a>.
          </p>
        </div>
      </div>
      <div class="flexCenter">
        <div class="usageDetails">
          <div
            title="Views"
            class="usageDetail count"
            data-automation="usage-detail-count"
            aria-describedby="usageDetailDesc"
          >
            <span
              id="usageDetailDesc"
              style="display:none"
            >
              Views
            </span>
            <span
              data-automation="usage-detail-count-value"
            >{{ accessCount }}</span>
          </div>
          <div
            v-show="totalSessionTime"
            title="Total session duration"
            class="usageDetail duration"
            data-automation="usage-detail-session"
            aria-describedby="sessionDetailDesc"
          >
            <span
              id="sessionDetailDesc"
              style="display:none"
            >
              Total Session Duration
            </span>
            <span>{{ sessionTimeString }}</span>
          </div>
        </div>
        <div class="usageGraphContainer">
          <canvas
            ref="canvas"
            data-automation="usage-graph-canvas"
            aria-label="Usage Graph"
            role="img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { ContentUsageAPIType, RESET_CHART_DATA } from '@/store/modules/infoSettings';
import { docsPath } from '@/utils/paths';
import { limitedPrecisionDuration } from '@/utils/timeFormatting';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { markRaw } from 'vue';
import { mapMutations, mapState } from 'vuex';

function chartOptions(contentCreationDateLabel, maxXValue) {
  return {
    annotation: {
      // Defines when the annotations are drawn.
      // This allows positioning of the annotation relative to the other
      // elements of the graph.
      //
      // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
      // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
      drawTime: 'afterDatasetsDraw',

      // Draw a vertical line at the point the content was initially deployed
      // IF and ONLY IF that date exists within this chart as an x-axis.
      annotations: [
        {
          // drawTime: 'afterDraw', // overrides annotation.drawTime if set
          // id: 'a-line-1', // optional
          type: 'line',
          // mode: 'horizontal',
          mode: 'vertical',
          // scaleID: 'x-axis-0',
          value: contentCreationDateLabel, // label to draw line at... ok if doesn't exist
          scaleID: 'x-axis-0',
          borderColor: '#ececec',
          borderWidth: 2,
        },
      ],
    },
    legend: {
      display: false, // no legend desired
    },
    elements: {
      point: {
        // we do not want points to be drawn - need to disable both of these.
        radius: 0,
        hoverRadius: 0,
      },
    },
    plugins: {
      legend: { display: false }, // no legend desired
      tooltips: {
        enabled: false, // no fly-bys wanted
      },
    },
    scales: {
      y: {
        display: false, // style desired does not have axes displayed
        ticks: {
          // set the y axis to include negative values
          // (25% of the positive max), so annotation line
          // goes through and below zero graph line
          min: -(maxXValue * 0.25),
          max: maxXValue,
        },
      },
      x: {
        display: false, // style desired does not have axes displayed
      },
    },
    responsive: true, // dynamic sizing.
    maintainAspectRatio: false, // we desire sizing per our dimensions, not chart.js aspect ratio
    animation: { duration: 0 }, // general animation time (better performance)
    responsiveAnimationDuration: 0, // animation duration after a resize (better performance)
  };
}

export default {
  name: 'UsagePanel',
  components: { EmbeddedStatusMessage },
  data() {
    return {
      usageInfoToggled: false,
      chart: null,
    };
  },
  computed: {
    ...mapState({
      usageData: state => state.infoSettings.usageData,
      chartData: state => state.infoSettings.chartData,
      chartError: state => state.infoSettings.chartError,
      apiType: state => state.infoSettings.apiType || 'general',
      accessCount: state => state.infoSettings.accessCount,
      totalSessionTime: state => state.infoSettings.sessionTime,
      accessByDay: state => state.infoSettings.accessByDay,
      contentCreatedDateLabel: state => state.infoSettings.contentCreatedDateLabel,
      maxAccessCount: state => state.infoSettings.maxAccessCount,
      partial: state => state.infoSettings.partial,
    }),
    loading() {
      return this.usageData === null;
    },
    sessionTimeString() {
      return this.totalSessionTime ? limitedPrecisionDuration(this.totalSessionTime.asSeconds()) : '';
    }
  },
  watch: {
    chartData(newData) {
      // clone the state objects- important! chartjs likes to own its copy :(
      this.chart.data.datasets = newData.datasets.map(dataset => ({ ...dataset }));
      this.chart.data.labels = newData.labels;
      this.chart.options = chartOptions(this.contentCreatedDateLabel, this.maxAccessCount);
      this.chart.update();
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeUnmount() {
    this.chart.destroy();
    this.resetChartData();
  },
  methods: {
    ...mapMutations({
      resetChartData: RESET_CHART_DATA,
    }),
    renderChart() {
      this.chart = markRaw(new Chart(this.$refs.canvas.getContext('2d'), {
        type: 'line',
        data: { datasets: [], labels: [] },
        options: chartOptions(this.contentCreatedDateLabel, this.maxAccessCount),
      }));
    },
    toggleUsageInfo() {
      this.usageInfoToggled = !this.usageInfoToggled;
    },
    usageDocs(apiType) {
      if (apiType === ContentUsageAPIType.Shiny) {
        return docsPath(
          'cookbook/user-activity/#user-activity-shiny-applications'
        );
      } else if (apiType === ContentUsageAPIType.General) {
        return docsPath(
          'cookbook/user-activity/#user-activity-content'
        );
      }
    }
  }
};
</script>
