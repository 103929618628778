<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      v-if="showLabel"
      :class="{ small }"
      class="rs-field__help"
    >
      <label
        :for="name"
        :class="{ small }"
        class="rs-field__help-label"
      >{{
         label
       }}
        <span
          v-if="required"
          aria-hidden="true"
        >*</span>
      </label>
      <i
        v-if="hasHelp"
        :class="{ active: helpShown }"
        tabindex="0"
        class="rs-field__help-icon"
        role="button"
        aria-label="More Information"
        @click="toggleHelp"
        @keypress="toggleHelp"
      />
    </div>

    <!-- help text -->
    <div
      v-if="helpShown"
      class="rs-field__text"
    >
      <span v-if="$slots.help">
        <slot name="help" />
      </span>
      <span v-else>{{ help }}</span>
    </div>
    <div
      :class="controlClass"
      class="rs-field__control"
    >
      <input
        v-if="lines < 2"
        v-bind="$attrs"
        :id="name"
        ref="input"
        :aria-label="label"
        :class="{ error: hasError, warning: hasWarning, info: hasInfo, small }"
        :name="name"
        class="rs-input"
        :value="modelValue"
        :type="type"
        :aria-describedby="`${ name }-message`"
        :aria-invalid="hasError"
        :aria-required="required"
        @input="handleValue"
      >
      <!-- Note: was specifying :rows="lines" below, but then had the mismatch
           of using CSS height to set minimum. Better to simply use lines as
           number of rems for height. Will need to generate a class for this later-->
      <textarea
        v-if="lines > 1"
        v-bind="$attrs"
        :id="name"
        :aria-label="label"
        :class="{ error: hasError, warning: hasWarning, info: hasInfo, small }"
        :name="name"
        class="rs-input"
        type="text"
        :value="modelValue"
        :style="'max-width: 100%; min-width: 100%; width: 100%; min-height: '+lines+'rem; height: '+lines+'rem;'"
        :aria-describedby="`${ name }-message`"
        :aria-invalid="hasError"
        :aria-required="required"
        @input="handleValue"
      />
      <div
        v-if="icon"
        :class="iconClass"
      />
    </div>

    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      data-automation="rs-field__error"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

const icons = ['search'];

export default {
  name: 'RSInputText',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: null
    },
    dataAutomation: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    help: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: ErrorMessage
    },
    small: {
      type: Boolean,
      default: false
    },
    lines: {
      type: Number,
      default: 1,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['change', 'update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      helpShown: false
    };
  },
  computed: {
    hasMessage() {
      return Boolean(this.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
    controlClass() {
      if (this.icon) {
        return 'rs-field__control--icon';
      }
      return '';
    },
    iconClass() {
      if (this.icon) {
        const classes = ['rs-icon', `rs-icon--${this.icon}`];
        if (this.small) {
          classes.push('rs-icon--small');
        }
        return classes.join(' ');
      }
      return '';
    },
  },
  created() {
    if (this.icon && !icons.includes(this.icon)) {
      throw new Error(
        `'${this.icon}' is an invalid icon name. Must be one of: ${icons.join(
          ', '
        )}`
      );
    }
  },
  methods: {
    handleValue(ev) {
      this.$emit('change', ev.target.value);
      this.$emit('update:modelValue', ev.target.value);
    },
    toggleHelp() {
      this.helpShown = !this.helpShown;
    },
    focusElement() {
      this.$nextTick(() => this.$refs.input && this.$refs.input.focus());
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

input {
  box-sizing: border-box;
  padding: 5px 10px;
}

input, textarea {
  border: 1px solid $color-medium-grey;
  &:disabled, &:read-only {
    @include control-disabled-input;
  }
}

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__text {
    font-size: $rs-font-size-small;
    border: 1px solid $color-light-grey-3;
    background-color: $color-light-grey-2;
    margin: 3px 0 5px 0;
    padding: 0.5rem;
    line-height: 1.5;

    p, ul, ol, li {
      margin: 0.3rem 0;
      padding: 0;
    }

    ul, ol {
      padding-left: 1rem;
    }
  }

  &__label {
    display: block;
    @include label;

    &.small {
      @include label(true);
    }
  }

  &__control {
    & .rs-input {
      flex: 1;
    }

    &--icon {
      & .rs-icon {
        position: absolute;
        top: 4px;
        left: 4px;
        bottom: 0;
      }
    }
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    &-label {
      @include label;

      &.small {
        @include label(true);
      }
    }

    &-icon {
      width: $rs-icon-size-smaller;
      height: $rs-icon-size-smaller;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      background-repeat: no-repeat;
      background-size: $rs-icon-size-smaller $rs-icon-size-smaller*2;
      background-position: 0 0;
      background-image: url(/images/elements/actionToggleInfo.svg);
      cursor: pointer;

      &.active {
        background-position: 0 (-$rs-icon-size-smaller);
      }

      &:focus {
        @include control-focus;
        border-radius: 50%;
      }
    }
  }
}

.rs-input {
  width: 100%;
  max-width: 100%;
  font-size: $rs-font-size-normal;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: $color-secondary-inverse;

  &:focus {
    @include control-focus;
  }

  &.small {
    font-size: $rs-font-size-small;
    padding: 0.25rem 0.4rem;
  }

  @include message-state;
}
</style>
