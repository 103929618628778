// Copyright (C) 2024 by Posit Software, PBC.

import { reactive, toRefs, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { searchContent } from '@/api/content';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';

/**
 * @typedef {Object} ComposeContentSearch
 * @property {String} query - Reactive Ref string of the query used to search content items.
 * @property {String} sortBy - Reactive Ref string of the sort instruction used to search.
 * @property {String} order - Reactive Ref string of the order instruction used to search.
 * @property {Number} page - Reactive Ref integer of the page used to search, undefined is equivalent to page 1.
 * @property {Boolean} isFetching - Reactive Ref boolean indicating if there is a search request in progress.
 * @property {Function} search - The search function that retreives content items from the server.
 */

/**
 * Composable that handles searching content items via reactive "query", "sortBy" and "order" Refs.
 * @example
 * const { query, sortBy, order, page, search } = useContentSearch(onResults);
 *
 * // Below reactive refs can be used as the v-model of components.
 * query.value = '2020 reports owner:hisaishi';
 * sortBy.value = 'created';
 * order.value = 'asc';
 *
 * // Search is triggered with above values
 * search();
 *
 * // Browser's URL query params are updated automatically after search too
 * // ?q=2020+reports+owner:hisaishi&sort=created&order=asc
 *
 * @example
 * // Initial values are picked from URL if applicable
 * // E.g: ?q=fastest+cars+2023&sort=created&order=asc&page=3
 * const { query, sortBy, order, page, search } = useContentSearch(onResults);
 * // Refs values are automatically picked up to:
 * // query.value === 'fastest cars 2023';
 * // sortBy.value === 'created';
 * // order.value === 'asc';
 * // page.value === 3;
 *
 * The initial value of "query" is pulled from the route "?q=" query parameter.
 * @param {Function} onResults Callback function to be run when search results are fetched.
 * @returns {ComposeContentSearch} search function and the reactive Refs query and isFetching
 */
export const useContentSearch = ({
  onResults = () => {},
} = {}) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const {
    q: initialQuery,
    sort: initialSort,
    order: initialOrder,
    page: initialPage,
  } = route.query;

  const state = reactive({
    query: initialQuery || '',
    sortBy: initialSort || '',
    order: initialOrder || '',
    page: initialPage ? parseInt(initialPage, 10) : undefined, // Leave it undefined, as "no page" === "page 1"
    perPage: 20, // This is static, but may change in the future
    isFetching: false,
  });

  const syncQueryToURL = () => {
    const query = {};
    if (state.query) {
      query.q = state.query;
    }
    if (state.sortBy) {
      query.sort = state.sortBy;
      query.order = state.order;
    }
    if (state.page) {
      query.page = state.page;
    }
    router.push({ query });
  };

  const search = async() => {
    if (state.isFetching) {
      return;
    }
    state.isFetching = true;

    try {
      const results = await searchContent({
        query: state.query,
        sortBy: state.sortBy,
        order: state.order,
        page: state.page,
      });
      onResults(results);
      syncQueryToURL();
    } catch (err) {
      store.commit(SET_ERROR_MESSAGE_FROM_API, err);
    }

    state.isFetching = false;
  };

  onBeforeMount(() => {
    search(state.query);
  });

  return { search, ...toRefs(state) };
};
