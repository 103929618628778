<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="band pushFooter">
    <div class="bandContent mainPage">
      <div
        class="fullPageFormContainer"
        data-automation="register-view"
      >
        <h1 class="formTitle">
          {{ $t("common.signUp") }}
        </h1>
        <form
          class="signup"
          name="registrationForm"
          novalidate
          autocomplete="off"
          @submit.prevent="submitRegisterForm"
        >
          <fieldset>
            <div
              id="username-wrapper"
              class="textInputContainer"
              :class="{ hasError: v$.form.username.$error }"
            >
              <RSInputText
                ref="username"
                v-model.trim="form.username"
                autocomplete="off"
                name="username"
                data-automation="username"
                :label="$t('authentication.label.username')"
                :message="v$.form.username.$error ? errorMessages.username : null"
                :required="true"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="first-name-wrapper"
              class="textInputContainer"
            >
              <RSInputText
                v-model.trim="form.firstName"
                autocomplete="off"
                name="first_name"
                data-automation="first_name"
                :label="$t('users.title.firstName')"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="last-name-wrapper"
              class="textInputContainer"
            >
              <RSInputText
                v-model.trim="form.lastName"
                autocomplete="off"
                name="last_name"
                data-automation="last_name"
                :label="$t('users.title.lastName')"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="email-wrapper"
              class="textInputContainer"
              :class="{
                hasError: v$.form.email.$error
              }"
            >
              <RSInputText
                ref="email"
                v-model.trim="form.email"
                autocomplete="off"
                name="email"
                type="email"
                data-automation="email"
                :label="$t('authentication.label.email')"
                :message="v$.form.email.$error ? errorMessages.email : null"
                :required="true"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="password-wrapper"
              class="textInputContainer"
              :class="{
                hasError: v$.form.password.$error
              }"
            >
              <RSInputPassword
                key="password"
                ref="password"
                v-model="form.password"
                autocomplete="off"
                name="password"
                data-automation="password"
                :label="$t('authentication.label.password')"
                :message="v$.form.password.$error ? errorMessages.password : null"
                :required="true"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="password2-wrapper"
              class="textInputContainer"
              :class="{
                hasError: v$.form.password2.$error
              }"
            >
              <RSInputPassword
                key="password2"
                ref="password2"
                v-model="form.password2"
                autocomplete="off"
                name="password2"
                data-automation="password2"
                :label="$t('authentication.label.password2')"
                :message="v$.form.password2.$error ? errorMessages.password2 : null"
                :required="true"
              />
            </div>
          </fieldset>

          <div class="actions">
            <RSButton
              :disabled="isCreatingUser"
              :label="submitBtnText"
              data-automation="signup-button"
            />
          </div>
          <p
            v-if="server.authentication.notice"
            data-automation="authentication-notice"
          >
            {{ server.authentication.notice }}
          </p>
        </form>
        <div class="formFooter">
          {{ $t("authentication.label.alreadyHaveAccount") }}
          <router-link :to="routeWithRedirect('login_view')">
            {{ $t("common.logIn") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import RSInputText from '@/elements/RSInputText.vue';
import RSInputPassword from '@/elements/RSInputPassword.vue';
import RSButton from '@/elements/RSButton';
import { login } from '@/api/authentication';
import { vueI18n } from '@/i18n/index';
import _ from 'lodash';
import { getHashQueryParameter, serverURL } from '@/utils/paths';
import { addNewLocalUser } from '@/api/users';
import * as Validators from '@/utils/validators';
import { routeWithRedirect } from '@/router';
import { mapState, mapMutations } from 'vuex';
import {
  SET_ERROR_MESSAGE_FROM_API,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';

export default {
  name: 'Register',
  components: {
    RSInputText,
    RSInputPassword,
    RSButton
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      routeWithRedirect,
      isCreatingUser: false,
      form: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
      },
      validationMessages: {
        username: {
          required: vueI18n.global.t('authentication.validation.username.blank'),
          minLength: vueI18n.global.t('authentication.validation.username.minLength'),
          maxLength: vueI18n.global.t('authentication.validation.username.maxLength'),
          prohibited: vueI18n.global.t(
            'authentication.validation.username.prohibited'
          ),
          firstLetter: vueI18n.global.t(
            'authentication.validation.username.firstLetter'
          ),
          validCharacters: vueI18n.global.t(
            'authentication.validation.username.validCharacters'
          )
        },
        email: {
          required: vueI18n.global.t('authentication.validation.email.required'),
          email: vueI18n.global.t('authentication.validation.email.email'),
          noSpaces: vueI18n.global.t('authentication.validation.email.email'),
          noEmojis: vueI18n.global.t('authentication.validation.email.email')
        },
        password: {
          required: vueI18n.global.t('authentication.validation.password.required'),
          minLength: vueI18n.global.t('authentication.validation.password.minLength')
        },
        password2: {
          required: vueI18n.global.t('authentication.validation.password.required'),
          same: vueI18n.global.t('authentication.validation.password.sameAsPassword')
        }
      }
    };
  },
  validations() {
    return this.formValidations();
  },
  computed: {
    submitBtnText() {
      return this.isCreatingUser
        ? vueI18n.global.t('common.pleaseWait')
        : vueI18n.global.t('common.signUp');
    },
    errorMessages() {
      const validations = _.pick(this.v$.form, [
        'username',
        'email',
        'password',
        'password2'
      ]);

      const errors = {};
      Object.entries(validations).forEach(([field, fieldValidationObj]) => {
        if (fieldValidationObj.$error) {
          const errorKey = fieldValidationObj.$errors[0].$validator;
          errors[field] = this.validationMessages[field][errorKey];
        }
      });

      return errors;
    },
    ...mapState({
      server: state => state.server.settings,
    })
  },
  mounted() {
    this.$refs.username.focusElement();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    submitRegisterForm() {
      this.v$.form.$touch();

      if (this.v$.form.$invalid) {
        this.focusFirstInvalidField();
        return;
      }

      this.isCreatingUser = true;

      addNewLocalUser({
        username: this.form.username,
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        password: this.form.password,
        userRole: '',
        userMustSetPassword: false
      })
        .then(() => {
          this.clearStatusMessage();
          login({ username: this.form.username, password: this.form.password }).then(
            () => {
              let homeUrl = serverURL('connect');
              const [urlQueryParam] = getHashQueryParameter('url') || [];
              if (urlQueryParam) {
                try {
                  const url = new URL(urlQueryParam);
                  const newUrl = `${url.pathname}${url.hash}`;
                  homeUrl = `${homeUrl}?url=${newUrl}`;
                } catch (e) {
                  // ignore the error, and redirect to the home page.
                }
              }

              window.location = homeUrl;
            }
          );
        })
        .catch(error => {
          this.setErrorMessageFromAPI(error);
        })
        .finally(() => {
          this.isCreatingUser = false;
        });
    },
    focusFirstInvalidField() {
      const firstInvalidField = Object.keys(this.v$.form).find(
        key => this.v$.form[key].$error
      );
      this.$refs[firstInvalidField].$el.querySelector('input').focus();
    },
    formValidations() {
      return {
        form: {
          username: Validators.usernameValidator(this.server),
          email: {
            ...Validators.emailValidator(),
            noSpaces: value => !value || !value.match(/\s/),
            noEmojis: value => !value || !value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/)
          },
          password: Validators.password(),
          password2: Validators.repeatPassword(this.form.password),
        },
      };
    },
  }
};
</script>
