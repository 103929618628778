<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="formSection">
    <div
      v-if="readOnly"
      class="itemTitle"
      data-automation="settings-info-title"
    >
      {{ title }}
    </div>
    <RSInputText
      v-else
      v-model="newTitle"
      name="itemTitle"
      class="itemTitle"
      :label="$t('appSettings.info.title.title')"
      :message="error"
      :show-label="false"
      :placeholder="$t('appSettings.info.title.placeholder')"
      data-automation="settings-info-title"
      @change="updateTitle"
    />
  </div>
</template>

<script>

import RSInputText from '@/elements/RSInputText';

const MAX_TITLE_LENGTH = 1024;

export default {
  name: 'ItemTitle',
  components: { RSInputText },
  props: {
    title: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['input'],
  data() {
    return {
      newTitle: this.title,
      pristine: true,
    };
  },
  computed: {
    error() {
      if ((this.newTitle.length < 3 || this.newTitle.length > MAX_TITLE_LENGTH) && !this.pristine){
        return this.$t('appSettings.info.title.error');
      }
      return null;
    }
  },
  watch: {
    title(newValue) {
      // if changes are discarded, title is populated by parent
      if (newValue !== this.newTitle) {
        this.newTitle = newValue;
        this.pristine = true;
      }
    }
  },
  methods: {
    updateTitle(value) {
      this.pristine = false;
      this.$emit('input', value);
    },
  }
};
</script>
