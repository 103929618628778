// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath, apiV1Path } from '@/utils/paths';
import { keysToCamel, keysToSnake } from './transform';
import { App } from './dto/app';
import AppRoles from './dto/appRole';
import ContentTypes from '@/views/content/contentList/contentType';

export const Filters = {
  minVisibilityAppRole(role) {
    return `filter=min_role:${encodeURIComponent(role)}`;
  },
  deployedContent() {
    return `filter=deployed:1`;
  },
  undeployedContent() {
    return `filter=deployed:0`;
  },
  ownedByUser(userGuid) {
    return `filter=account_guid:${encodeURIComponent(userGuid)}`;
  },
  contentType(type) {
    return `filter=content_type:${encodeURIComponent(type)}`;
  },
  tags(tags) {
    return tags.map(tag => `filter=${tag}`).join('&');
  },
};

function hasMinVisibilityAppRole(minVisibilityAppRole) {
  return [AppRoles.Owner, AppRoles.Editor, AppRoles.Viewer].includes(
    minVisibilityAppRole
  );
}

function hasContentType(contentType) {
  return [
    ContentTypes.Application,
    ContentTypes.Document,
    ContentTypes.Plot,
    ContentTypes.Pin,
    ContentTypes.Api,
    ContentTypes.TensorFlow,
  ].includes(contentType);
}

export const buildFilters = ({
  minVisibilityAppRole = AppRoles.None,
  deployedContent = false,
  undeployedContent = false,
  ownedByUser = '',
  contentType = ContentTypes.All,
  tags = [],
} = {}) => {
  const filters = [];
  if (hasMinVisibilityAppRole(minVisibilityAppRole)) {
    filters.push(Filters.minVisibilityAppRole(AppRoles.stringOf(minVisibilityAppRole)));
  }
  if (deployedContent) {
    filters.push(Filters.deployedContent());
  }
  if (undeployedContent) {
    filters.push(Filters.undeployedContent());
  }
  if (ownedByUser) {
    filters.push(Filters.ownedByUser(ownedByUser));
  }
  if (hasContentType(contentType)) {
    filters.push(Filters.contentType(contentType));
  }
  if (tags.length) {
    filters.push(Filters.tags(tags));
  }
  return filters;
};

function listContent({
  filters = [],
  count = 10,
  start = 0,
  cont = '',
  search = '',
  cancelToken,
} = {}) {
  return axios
    .get(apiPath(`applications?${filters.join('&')}`), {
      params: { count, start, cont, search },
      cancelToken,
    })
    .then(({ data: receivedData }) => {
      const data = keysToCamel(receivedData);
      data.applications = (data.applications || []).map(app => new App(app));
      return data;
    });
}

export function getUnpublishedContent({
  count = 10,
  start = 0,
  cont = '',
  cancelToken,
} = {}) {
  return listContent({
    filters: buildFilters({
      minVisibilityAppRole: AppRoles.Owner,
      undeployedContent: true,
    }),
    count,
    start,
    cont,
    cancelToken,
  });
}

export function getPublishedContentForUser(
  userGuid,
  { count = 10, start = 0, cont = '', cancelToken } = {}
) {
  return listContent({
    filters: buildFilters({
      minVisibilityAppRole: AppRoles.Viewer,
      deployedContent: true,
      ownedByUser: userGuid,
    }),
    count,
    start,
    cont,
    cancelToken,
  });
}

export function getContent({
  filters = buildFilters({
    minVisibilityAppRole: AppRoles.Viewer,
    deployedContent: true,
  }),
  count = 10,
  start = 0,
  cont = '',
  search = '',
  cancelToken,
} = {}) {
  return listContent({
    filters,
    count,
    start,
    cont,
    search,
    cancelToken,
  });
}

export function searchContent({
  query = '',
  sortBy = '',
  order = '',
  page = undefined,
} = {}) {
  const params = {
    q: query,
    sort: sortBy,
    order,
    include: 'owner',
  };

  if (page) {
    params.pageNumber = page;
  }

  return axios
    .get(apiV1Path(`/search/content`), { params: keysToSnake(params) })
    .then(({ data }) => {
      return {
        ...data,
        results: data.results.map(item => App.fromV1(keysToCamel(item))),
      };
    });
}
