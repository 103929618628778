<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-if="noSchedules"
      class="noContentMessage"
    >
      {{ noContentMessage }}
    </div>
    <RSTable
      v-else
      :columns="columns"
      data-automation="scheduled-content__list__table"
      @sort="sortColumn"
    >
      <RSTableRow
        v-for="(s, index) in schedules"
        :key="index"
      >
        <RSTableCell
          :cell-id="'link-' + index"
          :has-icon="true"
        >
          <router-link
            :to="{ name: 'apps.output', params: { idOrGuid: s.item.appId, id: s.item.variantId } }"
            :title="$t('content.list.labels.linkApp')"
          >
            <i
              :class="s.iconCssClass"
              class="rs-icon rs-icon--large"
            />{{ s.title }}
          </router-link>
        </RSTableCell>
        <RSTableCell>{{ s.frequencyDescription }}</RSTableCell>
        <RSTableCell>{{ s.nextRunDescription }}</RSTableCell>
        <RSTableCell>{{ s.sendsEmailGlyph }}</RSTableCell>
        <RSTableCell
          :cell-id="'author-' + index"
        >
          <router-link
            :to="{ name: 'people.users.profile', params: { guid: s.authorGuid } }"
            :title="$t('users.action.navigateUser', { user: s.authorName })"
          >
            {{ s.authorName }}
          </router-link>
        </RSTableCell>
      </RSTableRow>
    </RSTable>
  </div>
</template>

<script>
import RSTable from '@/elements/RSTable';
import RSTableRow from '@/elements/RSTableRow';
import RSTableCell from '@/elements/RSTableCell';

export default {
  name: 'ListView',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    schedules: {
      type: Array,
      required: true,
    },
    frequency: {
      type: String,
      required: true,
    },
  },
  emits: ['sort'],
  data() {
    return {
      columns: [
        {
          name: 'name',
          label: this.$t('admin.scheduledContent.headers.name'),
          sortable: true,
          direction: null,
        },
        {
          name: 'frequency',
          label: this.$t('admin.scheduledContent.headers.frequency'),
          direction: 'asc',
          sortable: true,
        },
        {
          name: 'nextRun',
          label: this.$t('admin.scheduledContent.headers.nextScheduledRun'),
          direction: null,
          sortable: true,
        },
        {
          name: 'email',
          label: this.$t('admin.scheduledContent.headers.sendsEmail'),
          direction: null,
          sortable: true,
        },
        {
          name: 'author',
          label: this.$t('admin.scheduledContent.headers.author'),
          direction: null,
          sortable: true,
        },
      ],
    };
  },
  computed: {
    noSchedules() {
      return this.schedules.length === 0;
    },
    noContentMessage() {
      return this.frequency === 'all'
        ? this.$t('admin.scheduledContent.noContent')
        : this.$t('admin.scheduledContent.noContentForType');
    },
  },
  methods: {
    sortColumn({ index, direction }) {
      this.columns.forEach(c => {
        c.direction = null;
      });
      const descending = direction === 'desc';
      this.columns[index].direction = descending ? 'desc' : 'asc';
      this.$emit('sort', this.columns[index].name, descending);
    },
  },
};
</script>
