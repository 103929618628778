<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div v-if="hasGitInfo">
    <div class="formSection itemInfo noSeparator">
      <p>{{ $t('content.settings.git.titles.generated') }}</p>
      <dl class="sameLine">
        <dt>{{ $t('content.settings.git.titles.repo') }}</dt>
        <dd>
          <a
            :href="git.repositoryUrl"
            target="_blank"
          >{{
            git.repositoryUrl
          }}</a>
        </dd>
        <dt>{{ $t('content.settings.git.titles.branch') }}</dt>
        <dd>{{ git.branch }}</dd>
        <dt>{{ $t('content.settings.git.titles.directory') }}</dt>
        <dd>/{{ git.contentPath }}</dd>
      </dl>
    </div>

    <div
      v-if="userCanMakeChanges"
      class="formSection"
    >
      <RSInputCheckbox
        v-model="enabled"
        :label="$t('content.settings.git.titles.updatePeriodically')"
        name="gitAutoUpdate"
        @change="repositoryUpdateToggled"
      />
      <div
        v-if="git.lastError"
        class="error"
      >
        {{ git.lastError }}
      </div>
      <GitUpdateNow
        :app-guid="appGuid"
        :git="git"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GitUpdateNow from './GitUpdateNow';
import RSInputCheckbox from '@/elements/RSInputCheckbox';

export default {
  name: 'GitInfo',
  components: {
    GitUpdateNow,
    RSInputCheckbox,
  },
  props: {
    appGuid: {
      type: String,
      required: true,
    },
    repositoryEnabled: {
      type: Boolean,
      required: true,
    },
    // whether user is allowed to make changes
    userCanMakeChanges: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input'],
  data() {
    return {
      enabled: this.repositoryEnabled,
    };
  },
  computed: {
    ...mapState({
      git: state => state.infoSettings.app.git,
    }),
    hasGitInfo() {
      return this.git !== null;
    },
  },
  watch: {
    repositoryEnabled(newValue) {
      if (newValue !== this.enabled) {
        this.enabled = newValue;
      }
    }
  },
  methods: {
    repositoryUpdateToggled(e) {
      this.enabled = e;
      this.$emit('input', this.enabled);
    }
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

dl.sameLine {
  dt, dd {
    display: inline;
  }

  dt:after {
    content: ': '
  }
  dd:after {
    content: '';
    display: block;
  }
}

/* unset styles set by bootstrap */
dl {
  margin-bottom: unset;
  margin-top: unset;
}

dt {
  font-weight: unset;
  line-height: unset;
}

dd {
  line-height: unset;
  margin-left: unset;
}

.error {
  color: $color-error;
}
</style>
