// Copyright (C) 2022 by Posit Software, PBC.

// Initialize Vue-i18n library
// with configuration and language resource objects

import { createI18n, I18nT } from 'vue-i18n';
import { en } from './en/index';
import { i18nModifier } from './systemConstants';

export const availableLanguages = [{ code: 'en', name: 'English', bundle: en }];

const DEFAULT_LANGUAGE = 'en';

const languageConfig = {
  fallbackLocale: DEFAULT_LANGUAGE,
  locale: DEFAULT_LANGUAGE,
  messages: availableLanguages.reduce((acc, lang) => {
    acc[lang.code] = lang.bundle;
    return acc;
  }, {}),
  modifiers: {
    sys: i18nModifier,
  },
};

I18nT.props.scope.default = 'global';
export const useI18n = () => ({ useScope: 'global' });

const vueI18n = createI18n(languageConfig);

export { vueI18n };
