<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div class="rs-field">
      <label
        for="schedule-interval-daily"
        class="rs-radio__label"
      >
        <input
          id="schedule-interval-daily"
          v-model="scheduleType"
          type="radio"
          class="rs-radio__input interval-check"
          name="schedule-daily"
          data-automation="schedule-daily__by-day"
          :disabled="disabled"
          :value="DAY"
          :aria-label="intervalTerm"
        >
        <IntervalScheduleInput
          v-model.number="schedInterval"
          :term="intervalTerm"
          :disabled="disabled"
          @change="intervalChange"
        />
      </label>
    </div>
    <RSInputRadio
      v-model="scheduleType"
      name="schedule-daily"
      data-automation="schedule-daily__weekdays"
      :label="$t('appSettings.schedule.inputLabels.weekdays')"
      :value="WEEKDAY"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { ScheduleTypes } from '@/api/dto/schedule';
import RSInputRadio from '@/elements/RSInputRadio';
import IntervalScheduleInput from '@/components/Schedule/IntervalScheduleInput';

export default {
  name: 'DailySchedule',
  components: {
    RSInputRadio,
    IntervalScheduleInput,
  },
  props: {
    type: {
      type: String,
      default: ScheduleTypes.Day,
    },
    interval: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      scheduleType: this.type,
      schedInterval: this.interval,
    };
  },
  computed: {
    intervalTerm() {
      if (this.schedInterval > 1) {
        return this.$t(`appSettings.schedule.inputLabels.plural.day`);
      }
      return this.$t(`appSettings.schedule.inputLabels.singular.day`);
    },
  },
  watch: {
    scheduleType(){
      this.emitChange();
    },
  },
  created() {
    this.DAY = ScheduleTypes.Day;
    this.WEEKDAY = ScheduleTypes.Weekday;
  },
  methods: {
    intervalChange() {
      if (this.scheduleType === ScheduleTypes.Day) {
        this.emitChange();
      }
    },
    async emitChange() {
      // Wait for model values to update before firing the event
      await this.$nextTick();
      this.$emit('change', {
        // Always return int
        interval: Number(this.schedInterval),
        type: this.scheduleType,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
input.interval-check[type="radio"] {
  vertical-align: middle;
}
</style>
