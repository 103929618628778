<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="showConfirmation"
      @save="save"
      @discard="reset"
    />

    <RSInputCheckbox
      v-model="scheduleEnabled"
      name="enable-schedule-output"
      data-automation="schedule__output-checkbox"
      :disabled="!canSchedule"
      :label="enableCheckboxLabel"
      @change="scheduleFlagChange"
    />

    <div
      v-if="scheduleEnabled"
      data-automation="schedule__full-form"
    >
      <div
        v-if="!initialized"
      >
        {{ $t('appSettings.schedule.loading') }}
      </div>
      <div v-else>
        <div class="formSection">
          <!-- Timezone -->
          <div
            v-if="timezone.list.length > 0"
            class="rs-field"
            data-automation="schedule__timezone"
          >
            <span class="rs-field__help-label">
              {{ $t('appSettings.schedule.timezone.label') }}
            </span>
            <TimezoneSelector
              :selected="timezone.selected"
              :options="timezone.list"
              :disabled="!canSchedule"
              @change="updateTimezone"
            />
          </div>

          <!-- Start date/time -->
          <div
            class="rs-field vertical-space"
            data-automation="schedule__datetime"
          >
            <span class="rs-field__help-label">
              {{ $t('appSettings.schedule.timezone.start') }}
            </span>
            <DateTimeInput
              :date="startTime"
              :disabled="!canSchedule"
              @change="updateStartTime"
            />
          </div>

          <div
            v-if="canSchedule"
            class="reset-time vertical-space"
          >
            <button
              class="link-like-button"
              @click="resetStartTime"
            >
              Reset to local time
            </button>
          </div>
        </div>

        <div class="formSection">
          <RSInputSelect
            v-model="schedTypeCategory"
            :label="$t('appSettings.schedule.scheduleType')"
            :options="schedOptions"
            :disabled="!canSchedule"
            data-automation="schedule__frequency"
            name="schedule-type"
            @change="updateSchedSelected"
          />

          <!-- By Minute, Hours, Years -->
          <IntervalScheduleInput
            v-if="isSimpleInterval"
            v-model="schedInterval"
            :term="intervalTerm"
            :disabled="!canSchedule"
            @change="updateSingleInterval"
          />

          <DailySchedule
            v-if="isDaily"
            :type="schedTypeSelected"
            :interval="schedInterval"
            :disabled="!canSchedule"
            @change="updateScheduleValues"
          />

          <WeeklySchedule
            v-if="isWeekly"
            :type="schedTypeSelected"
            :interval="schedInterval"
            :days="specificOptions.days"
            :disabled="!canSchedule"
            @change="updateScheduleValues"
            @invalid="invalidWeeklySet"
          />

          <SemimonthlySchedule
            v-if="isSemimonth"
            :value="specificOptions.semimonth"
            :disabled="!canSchedule"
            @change="updateSemimonthSet"
          />

          <MonthlySchedule
            v-if="isMonthly"
            :type="schedTypeSelected"
            :interval="schedInterval"
            :monthday="specificOptions.day"
            :nthweek="specificOptions.week"
            :weekday="specificOptions.day"
            :disabled="!canSchedule"
            @change="updateScheduleValues"
          />
        </div>

        <!-- Publish output -->
        <div class="formSection">
          <RSInputCheckbox
            v-model="publishOutput"
            name="publication-enabled"
            data-automation="schedule__publish-output"
            :disabled="!canSchedule"
            :label="$t('appSettings.schedule.publishOutput')"
          />
        </div>

        <ScheduleEmail
          :enabled="sendEmail"
          :app="app"
          :current-user="currentUser"
          :server-settings="serverSettings"
          :access-list="appAccessList"
          :access-groups="appAccessGroups"
          :all="email.all"
          :collabs="email.collabs"
          :viewers="email.viewers"
          :subscribers="email.additionalRecipients"
          @change="updateEmailRecipients"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  DaysOfWeek,
  OrdinalMonthWeeks,
  Schedule,
  ScheduleTypes,
  SemiMonthOptions,
} from '@/api/dto/schedule';
import { updateVariant } from '@/api/parameterization';
import {
  createSchedule,
  deleteSchedule,
  getTimezones,
  getVariantSchedules,
  updateSchedule,
} from '@/api/scheduledContent';
import {
  getSubscribers,
  removeSubscriber,
  subscribeUser,
} from '@/api/subscriptions';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { browserTimezone } from '@/utils/timezone';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';

import DateTimeInput from '@/components/DateTimeInput';
import DailySchedule from '@/components/Schedule/DailySchedule';
import IntervalScheduleInput from '@/components/Schedule/IntervalScheduleInput';
import MonthlySchedule from '@/components/Schedule/MonthlySchedule';
import SemimonthlySchedule from '@/components/Schedule/SemimonthlySchedule';
import WeeklySchedule from '@/components/Schedule/WeeklySchedule';
import TimezoneSelector from '@/components/TimezoneSelector';
import RSInputCheckbox from '@/elements/RSInputCheckbox';
import RSInputSelect from '@/elements/RSInputSelect';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';
import ScheduleEmail from './ScheduleEmail';

const defaultSchedType = ScheduleTypes.Day;
const simpleIntervalTypes = [
  ScheduleTypes.Minute,
  ScheduleTypes.Hour,
  ScheduleTypes.Year,
];

const initialData = () => ({
  initialized: false,
  scheduleEnabled: false,
  currentSchedule: null,
  invalid: false,
  isSaving: false,
  isDirty: false,
  publishOutput: true,
  sendEmail: false,
  startTime: dayjs(),
  nextRun: null,
  schedInterval: 1,
  // The difference between schedTypeCategory and schedTypeSelected
  // is that the first one tracks the category selected in the dropdown
  // and the later tracks the actual schedule type to use.
  // This because there are more than one schedule type values for some of this.
  // E.g: By Week has "every X weeks" but also "every x-y-z days of each week".
  schedTypeCategory: defaultSchedType,
  schedTypeSelected: defaultSchedType,
  specificOptions: {
    day: 1,
    days: [],
    week: 1,
    semimonth: SemiMonthOptions.First,
  },
  timezone: {
    selected: null,
    list: [],
  },
  email: {
    all: false,
    collabs: true, // When enabling email, we want collabs to be set by default.
    viewers: false,
    additionalRecipients: [],
  },
});

export default {
  name: 'ScheduleForm',
  components: {
    RSInputSelect,
    RSInputCheckbox,
    DateTimeInput,
    TimezoneSelector,
    DailySchedule,
    WeeklySchedule,
    SemimonthlySchedule,
    MonthlySchedule,
    IntervalScheduleInput,
    ConfirmationPanel,
    ScheduleEmail,
  },
  data() {
    return initialData();
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      variant: state => state.parameterization.currentVariant,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
    canSchedule() {
      return this.currentUser.canScheduleVariant(this.app);
    },
    enableCheckboxLabel() {
      return this.$t('appSettings.schedule.scheduleFor', { name: this.variant.name });
    },
    appAccessList() {
      return this.app.users || [];
    },
    appAccessGroups() {
      return this.app.groups || [];
    },
    isSimpleInterval() {
      // by minute, hour or year.
      return simpleIntervalTypes.includes(this.schedTypeCategory);
    },
    isDaily() {
      return this.schedTypeCategory === ScheduleTypes.Day;
    },
    isWeekly() {
      return this.schedTypeCategory === ScheduleTypes.Week;
    },
    isSemimonth() {
      return this.schedTypeCategory === ScheduleTypes.SemiMonth;
    },
    isMonthly() {
      return this.schedTypeCategory === ScheduleTypes.DayOfMonth;
    },
    intervalTerm() {
      if (this.schedInterval > 1) {
        return this.$t(`appSettings.schedule.inputLabels.plural.${this.schedTypeCategory}`);
      }
      return this.$t(`appSettings.schedule.inputLabels.singular.${this.schedTypeCategory}`);
    },
    enableConfirmation() {
      return this.isDirty && !this.isSaving && !this.invalid;
    },
    showConfirmation() {
      // We show confirmation panel when...
      // A) There is an existing schedule but the user disables scheduling. (scheduleEnabled = false)
      if (this.disabledCurrentSchedule) {
        return true;
      }

      // B) There was no current schedule and it is now enabled
      // C) When there is an existing schedule and any input changes.
      // ^ both cases above can be handled with isDirty
      return this.isDirty;
    },
    disabledCurrentSchedule() {
      return this.currentSchedule && !this.scheduleEnabled;
    },
  },
  watch: {
    // When the variant changes, reset the component
    // to reflect the selected variant schedule.
    'variant.id'() {
      this.reset();
    },
  },
  created() {
    // Create the schedule type options here, since those won't change
    // and there's no need to have them reactive. (Leaner reset of data too)
    const schedOptLabel = term => this.$t(`appSettings.schedule.inputLabels.${term}`);
    this.schedOptions = [
      { label: schedOptLabel('byMinute'), value: ScheduleTypes.Minute },
      { label: schedOptLabel('hourly'), value: ScheduleTypes.Hour },
      { label: schedOptLabel('daily'), value: ScheduleTypes.Day },
      { label: schedOptLabel('weekly'), value: ScheduleTypes.Week },
      { label: schedOptLabel('semimonthly'), value: ScheduleTypes.SemiMonth },
      { label: schedOptLabel('monthly'), value: ScheduleTypes.DayOfMonth },
      { label: schedOptLabel('yearly'), value: ScheduleTypes.Year },
    ];
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    init() {
      return getVariantSchedules(this.variant.id)
        .then(currentSched => {
          if (currentSched.length) {
            this.currentSchedule = currentSched[0];
            this.scheduleEnabled = true;
            return this.loadScheduleForm();
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    loadScheduleForm() {
      return Promise.all([
        getTimezones(),
        getSubscribers(this.variant.id),
      ])
        .then(([
          tzList,
          subs,
        ]) => {
          this.timezone.list = tzList;
          this.originalSubs = [...subs.subscriptions];
          this.resolveTimezone();
          this.reflectCurrentSchedule(subs.subscriptions);
          this.initialized = true;
        })
        .catch(this.setErrorMessageFromAPI);
    },
    reflectCurrentSchedule(subs) {
      if (!this.currentSchedule) {
        // Allow saving automatically if there's no existing schedule
        // we should allow immediate saving with the default values in this case.
        this.isDirty = true;
        return;
      }

      let schedJson;
      const {
        email,
        type,
        activate,
        timezone,
        startTime,
        nextRun,
      } = this.currentSchedule;

      this.updateTimezone(timezone, false);
      // The start time comes as ISO string (UTC) from the backend,
      // but we must handle dates here as if it where local dates,
      // remove the "Z" so dayjs picks it up as a local date.
      this.startTime = this.forceLocalAsISO(startTime);
      this.nextRun = this.forceLocalAsISO(nextRun);
      this.publishOutput = activate;
      this.schedTypeCategory = ScheduleTypes.categoryOf(type);
      this.schedTypeSelected = type;

      try {
        schedJson = JSON.parse(this.currentSchedule.schedule);
      } catch {
        schedJson = {};
      }

      if (schedJson.N) {
        this.schedInterval = schedJson.N;
      }

      this.specificOptions.day = schedJson.Day || 1;
      this.specificOptions.days = schedJson.Days ? [...schedJson.Days] : [];
      this.specificOptions.week = schedJson.Week || 1;
      this.specificOptions.semimonth = (
        schedJson.First ? SemiMonthOptions.First : SemiMonthOptions.Last
      );

      if (email) {
        this.updateEmailRecipients({
          sendEmail: email,
          collaborators: this.variant.emailCollaborators,
          viewers: this.variant.emailViewers,
          mailAll: this.variant.emailAll,
          additionalRecipients: subs,
        }, false);
      }
    },
    scheduleFlagChange(show) {
      if (show) {
        // Load data and form
        this.loadScheduleForm();
      } else if (!this.currentSchedule) {
        // If no current schedule and disabled,
        // no need to consider it dirty.
        this.isDirty = false;
      } else {
        this.isDirty = true;
      }
    },
    resetSpecifics() {
      this.schedInterval = 1;
      this.specificOptions.day = 1;
      this.specificOptions.days = [];
      this.specificOptions.week = 1;
      this.specificOptions.semimonth = SemiMonthOptions.First;
    },
    resetStartTime() {
      this.startTime = dayjs();
      this.resolveTimezone();
      this.isDirty = true;
    },
    tzFind(tz) {
      return this.timezone.list.find(i => i.value === tz);
    },
    resolveTimezone() {
      const localTimezone = this.tzFind(browserTimezone());
      const defaultTimezone = this.tzFind('UTC');
      this.timezone.selected = localTimezone || defaultTimezone;
    },
    updateTimezone(value, dirty = true) {
      this.isDirty = dirty;
      this.timezone.selected = this.tzFind(value);
    },
    updateStartTime(date) {
      this.isDirty = true;
      this.startTime = date;
    },
    updateSchedSelected(value) {
      this.invalid = false;
      this.isDirty = true;
      this.resetSpecifics();
      this.schedTypeSelected = value;
      this.schedTypeCategory = ScheduleTypes.categoryOf(value);
    },
    updateSingleInterval(interval) {
      this.isDirty = true;
      this.schedInterval = interval;
    },
    updateScheduleValues({
      type,
      interval,
      days,
      nday,
      nthweek,
      weekday,
    }) {
      this.invalid = false;
      this.isDirty = true;
      this.schedTypeSelected = type;
      this.schedInterval = interval;

      if (type === ScheduleTypes.DayOfWeek) {
        this.specificOptions.days = [...days];
      }

      if (type === ScheduleTypes.DayOfMonth) {
        this.specificOptions.day = nday;
      }

      if (type === ScheduleTypes.DayweekOfMonth) {
        this.specificOptions.day = DaysOfWeek.valueOf(weekday);
        this.specificOptions.week = OrdinalMonthWeeks.valueOf(nthweek);
      }
    },
    updateSemimonthSet(value) {
      this.isDirty = true;
      this.specificOptions.semimonth = value;
    },
    updateEmailRecipients({
      sendEmail,
      collaborators,
      viewers,
      mailAll,
      additionalRecipients,
    }, dirty = true) {
      this.isDirty = dirty;
      this.sendEmail = sendEmail;
      this.email.all = mailAll;
      this.email.collabs = collaborators;
      this.email.viewers = mailAll ? false : viewers;
      this.email.additionalRecipients = additionalRecipients;
    },
    invalidWeeklySet() {
      // This only happens when no day is choosen for weekly - weekdays
      this.invalid = true;
    },
    handleSubscriptions() {
      const newSubs = this.email.additionalRecipients.map(sub => sub.guid);
      const removeSubs = this.originalSubs.reduce((toRemove, sub) => {
        const subscIndex = newSubs.indexOf(sub.guid);
        if (subscIndex === -1) {
          // Subscriber not in the list anymore, pull it in to be removed.
          toRemove.push(sub.guid);
        } else {
          // Subscriber still in the list, ignore it as it is not new.
          newSubs.splice(subscIndex, 1);
        }
        return toRemove;
      }, []);

      return [
        // Requests to subscribe users
        ...newSubs.map(sub => subscribeUser(this.variant.id, sub)),
        // Requests to un-subscribe users
        ...removeSubs.map(sub => removeSubscriber(this.variant.id, sub)),
      ];
    },
    save() {
      if (this.disabledCurrentSchedule) {
        // Delete the current schedule due to the user disabling it.
        const { id } = this.currentSchedule;
        return deleteSchedule(id).then(() => {
          this.isDirty = false;
          this.currentSchedule = null;
          this.setInfoMessage({ message: this.$t('appSettings.schedule.saved') });
        })
          .catch(this.setErrorMessageFromAPI);
      }

      const allRequests = [];
      const schedule = new Schedule({
        appId: this.app.id,
        variantId: this.variant.id,
        activate: this.publishOutput,
        email: this.sendEmail,
        type: this.schedTypeSelected,
        timezone: this.timezone.selected.value,
        startTime: this.forceISOAsLocal(this.startTime),
        nextRun: this.forceISOAsLocal(this.nextRun || this.startTime),
      });
      schedule.setSpecifics({
        interval: this.schedInterval,
        ...this.specificOptions,
      });

      if (this.sendEmail) {
        const variantClone = cloneDeep(this.variant);
        variantClone.setEmailSettings(this.email);
        allRequests.push(updateVariant(variantClone.toJSON()));
        allRequests.push(...this.handleSubscriptions());
      }

      if (this.currentSchedule) {
        // If current schedule, update it
        schedule.id = this.currentSchedule.id;
        allRequests.push(updateSchedule(schedule.toJSON()));
      } else {
        // else, create a new one
        allRequests.push(createSchedule(schedule.toJSON()));
      }

      return Promise.all(allRequests).then(responses => {
        // The last request in the list is the update/create schedule
        const newSchedule = responses.pop();
        this.isDirty = false;
        this.currentSchedule = newSchedule;
        this.setInfoMessage({ message: this.$t('appSettings.schedule.saved') });
      })
        .catch(this.setErrorMessageFromAPI);
    },
    reset() {
      Object.assign(this.$data, initialData());
      this.init();
    },
    // Method to create forced strings of local dates as ISO strings
    // ignoring the timezone or local offset.
    // The datetime the user sees in the UI,
    // is what is sent to the database but forced as UTC string.
    // E.g '2023-10-30T09:30:20 GMT -0400' -> '2023-10-30T09:30:20Z'
    forceISOAsLocal(d) {
      return `${d.format('YYYY-MM-DDTHH:mm:ss')}Z`;
    },
    // Method to create a forced dayjs instance from a date
    // which comes as ISO string (UTC) from the backend,
    // but we must handle dates here as if it where local dates,
    // remove the "Z" so dayjs picks it up as a local date.
    forceLocalAsISO(d) {
      return dayjs(d.toISOString().slice(0, -1));
    },
  },
};
</script>

<style scoped lang="scss">
.reset-time {
  text-align: right;
}

input.interval-check[type="radio"] {
  vertical-align: middle;
}
</style>
