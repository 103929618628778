<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="band pushFooter">
    <div class="bandContent mainPage">
      <div
        v-if="showLoginForm"
        class="fullPageFormContainer"
      >
        <h1 class="formTitle">
          {{ $t('authentication.login.title') }}
        </h1>

        <div
          v-if="authNotice"
          class="authentication-notice-wrapper"
        >
          <p
            class="authentication-notice"
            data-automation="login-auth-notice"
          >
            {{ authNotice }}
          </p>
        </div>

        <form
          data-automation="login-panel"
          @submit.prevent="signIn"
        >
          <p class="chunk login-type">
            {{ $t('authentication.login.blurb', { auth: authName }) }}
          </p>

          <RSInputText
            ref="username"
            v-model.trim="username"
            name="username"
            autocomplete="username"
            data-automation="login-username"
            :label="`${$t('authentication.label.username')} *`"
            :disabled="submitting"
          />

          <RSInputPassword
            ref="password"
            v-model="password"
            name="password"
            autocomplete="off"
            data-automation="login-password"
            :label="$t('authentication.label.password')"
            :disabled="submitting"
          />
          <div
            v-if="!externalUserData"
            class="right finePrint"
          >
            <router-link
              :to="resetPasswordPath"
              data-automation="login-forgot"
            >
              {{ $t('authentication.label.forgotPassword') }}
            </router-link>
          </div>

          <LoginCaptcha
            v-if="challengeEnabled"
            v-model="captchaValue"
            :should-reload="reloadCaptcha"
            @ready="onCaptchaReady"
          />

          <div class="actions">
            <RSButton
              data-automation="login-panel-submit"
              type="primary"
              :label="$t('common.logIn')"
              :disabled="disableButton"
              @click="signIn"
            />
          </div>
        </form>

        <div
          v-if="showRegisterLink"
          class="formFooter"
        >
          {{ $t('authentication.label.selfRegistration') }}
          <router-link
            data-automation="login-signup"
            :to="routeWithRedirect('register_view')"
          >
            {{ $t('common.signUp') }}
          </router-link>
        </div>
      </div>

      <div
        v-if="showSpinner"
        id="initialSpinnerWrapper"
        data-automation="login-spinner"
      >
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/authentication';
import ApiErrors from '@/api/errorCodes';
import { locationHrefTo } from '@/utils/windowUtil';
import Spinner from '@/components/Spinner';
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText';
import RSInputPassword from '@/elements/RSInputPassword';
import LoginCaptcha from './LoginCaptcha';
import { isDefaultSystemName } from '@/constants/system';
import { routeWithRedirect } from '@/router';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { SERVER_SETTINGS_LOAD } from '@/store/modules/server';
import {
  BadRedirectErr,
  IllegalRedirectErr,
  policeRedirects,
} from '@/utils/paths';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'LoginView',
  components: {
    RSButton,
    RSInputText,
    RSInputPassword,
    LoginCaptcha,
    Spinner,
  },
  data() {
    return {
      authName: '',
      authNotice: '',
      captchaValue: '',
      challengeEnabled: false,
      challengeId: null,
      externalUserData: false,
      init: {
        checkingAutoSession: true,
        loginFailure: false,
        error: null,
      },
      invalidatePassword: false,
      isLoadingCaptcha: false,
      password: '',
      reloadCaptcha: false,
      routeWithRedirect,
      showRegisterLink: false,
      showSpinner: false,
      submitting: false,
      systemDisplayName: '',
      timers: {
        spinner: null,
        warning: null,
      },
      username: '',
      warningDelay: 0,
    };
  },
  computed: {
    showLoginForm() {
      // To avoid components render flashing, we don't show the form
      // until we are certain that we are not redirecting the user to
      // set/reset the password.
      return (
        !this.init.checkingAutoSession ||
        this.init.loginFailure ||
        this.init.error
      );
    },
    disableButton() {
      return this.emptyFields || this.submitting || this.isLoadingCaptcha;
    },
    emptyFields() {
      return (
        !this.username ||
        !this.password ||
        (this.challengeEnabled && !this.captchaValue)
      );
    },
    resetPasswordPath() {
      return {
        name: 'reset_password',
        query: {
          u: this.username ? this.username : undefined,
        },
      };
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.setup();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      reloadCurrentUser: CURRENT_USER_LOAD,
      reloadServerSettings: SERVER_SETTINGS_LOAD,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    async setup() {
      this.authName = this.serverSettings.authentication.name;
      this.authNotice = this.serverSettings.authentication.notice;
      this.warningDelay = this.serverSettings.authentication.warningDelay || 30;
      this.externalUserData =
        this.serverSettings.authentication.externalUserData;
      this.showRegisterLink =
        this.serverSettings.selfRegistration && !this.externalUserData;
      this.systemDisplayName = this.serverSettings.systemDisplayName;
      this.challengeEnabled =
        this.serverSettings.authentication.challengeResponseEnabled;
      this.isLoadingCaptcha = this.challengeEnabled;

      // If auth method name is default, use system display name
      if (isDefaultSystemName(this.serverSettings.authentication.name)) {
        this.authName = this.systemDisplayName;
      }

      await this.checkCurrentSession();

      if (!this.init.checkingAutoSession) {
        this.handleInitFocus();
      }
    },
    onCaptchaReady(challengeId) {
      this.challengeId = challengeId;
      this.captchaValue = '';
      this.reloadCaptcha = false;
      this.isLoadingCaptcha = false;
    },
    signIn({ autoLogin = false } = {}) {
      if (this.disableButton || this.submitting) { return; }

      this.clearStatusMessage();
      this.submitting = true;
      this.handleWarningTimers();

      const payload = {
        username: this.username,
        password: this.password,
        invalidatePassword: this.invalidatePassword,
      };

      if (this.challengeEnabled && !autoLogin) {
        payload.challengeId = this.challengeId;
        payload.challengeResponse = this.captchaValue;
      }

      return login(payload)
        .then(this.onLoginSuccess)
        .catch(err => this.onLoginFailure(err, { autoLogin }))
        .finally(() => { this.submitting = false; });
    },
    onLoginSuccess({ passwordExpired }) {
      this.clearTimers();

      const { url, utoken: resettoken } = this.$route.query;
      if (url) {
        // If there is a redirect URL param, use it
        this.safeRedirect(url);
      } else if (passwordExpired && resettoken) {
        // Check if we should redirect to set or reset password
        this.$router.push({
          name: 'set_password',
          query: { resettoken },
        });
      } else {
        this.syncStateAndGo();
      }

      // Bluebird complains about non-returning promises.
      return Promise.resolve();
    },
    onLoginFailure(err, { autoLogin = false } = {}) {
      this.clearTimers();
      this.submitting = false;
      this.showSpinner = false;
      this.init.loginFailure = true;

      // Get new captcha, if enabled (clear captcha value)
      if (this.challengeEnabled) {
        this.reloadCaptcha = true;
      }

      // Clear captcha and password, and focus on password input
      this.password = '';
      this.$nextTick(() => this.$refs.password?.$el?.querySelector('input').focus());

      // Handle unexpected errors
      if (!err.response?.data?.error) {
        this.setErrorMessage({ message: this.$t('authentication.login.unableToSignIn') });
        return;
      }

      if (autoLogin && err.response.data.code === ApiErrors.InvalidLogin) {
        if (this.invalidatePassword) {
          this.setInfoMessage({
            message: this.$t('authentication.login.confirm.enterNewPassword'),
            autoHide: false,
          });
        } else {
          this.setInfoMessage({
            message: this.$t('authentication.login.confirm.enterPassword'),
            autoHide: false,
          });
        }
      } else {
        this.setErrorMessageFromAPI(err);
      }
    },
    safeRedirect(url) {
      const redirErr = policeRedirects(url);
      if (redirErr) {
        if (redirErr === IllegalRedirectErr) {
          this.setErrorMessage({
            message: this.$t('authentication.login.illegalRedirect', { errUrl: url })
          });
        }
        if (redirErr === BadRedirectErr) {
          this.setErrorMessage({
            message: this.$t('authentication.login.malformedRedirect', { errUrl: url })
          });
        }
        this.syncStateAndGo();
      } else {
        this.syncStateAndGo(url);
      }
    },
    async checkCurrentSession() {
      const { user: username, utoken, passwordreset } = this.$route.query;
      // "?passwordreset" comes as an int bool (0 | 1)
      // but here we get it as a string, we have to cast it to avoid surprises.
      this.invalidatePassword = !!Number(passwordreset);

      // if username is supplied, pre-populate it
      if (username || !this.isAuthenticated) {
        this.username = username || this.currentUser.username;
      }

      // if password is supplied too, sign in automatically
      if (this.username && utoken) {
        this.password = utoken;
        await this.signIn({
          autoLogin: true,
        });
      }
      this.init.checkingAutoSession = false;
    },
    async syncStateAndGo(targetUrl) {
      await this.reloadCurrentUser(false);
      await this.reloadServerSettings(false);
      if (targetUrl) {
        locationHrefTo(targetUrl);
      } else {
        this.$router.push({ name: 'contentList' });
      }
    },
    handleInitFocus() {
      if (!this.username) {
        // Focus on username input if not present
        this.$nextTick(() => this.$refs.username?.$el?.querySelector('input').focus());
      } else {
        // Focus on password input if username was per-populated
        this.$nextTick(() => this.$refs.password?.$el?.querySelector('input').focus());
      }
    },
    // The product configuration "WarningDelay" is used to show a message
    // when the authentication provider takes longer than the configured delay.
    handleWarningTimers() {
      // Don't wait longer than 1 second to show the spinner
      // More than that defeats its purpose of indicating
      // "longer than usual". However, show the spinner sooner
      // if the warn delay is short so difference between the
      // events (spinner and warning) is noticed.

      const spinnerDelay = Math.min(1, this.warningDelay / 5) * 1000;
      this.timers.spinner = setTimeout(() => {
        if (this.submitting) {
          this.showSpinner = true;
        }
      }, spinnerDelay);

      if (this.warningDelay > 0) {
        const warningDelay = this.warningDelay * 1000;
        this.timers.warning = setTimeout(() => {
          // notify the user if it takes way too long that may indicate a problem
          if (this.submitting) {
            this.setInfoMessage({
              message: this.$t('authentication.login.warning'),
              autoHide: false,
            });
          }
        }, warningDelay);
      }
    },
    clearTimers() {
      const { spinner, warning } = this.timers;
      if (spinner) {
        clearTimeout(spinner);
      }
      if (warning) {
        clearTimeout(warning);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

#initialSpinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  & > * {
    height: 5rem;
    width: 5rem;
  }
}
.finePrint {
  margin-bottom: 1rem;
}

.login-type {
  font-weight: 600;
  text-align: center;
}

.authentication-notice-wrapper {
  align-items: center;
  background-color: $color-info-background;
  display: flex;
  justify-content: center;
  padding: 1em;

  .authentication-notice {
    color: $color-info;
    font-weight: 600;
    text-align: center;
  }
}
</style>
