<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <RSInputSelect
      :label="$t('appSettings.access.labels.runAs')"
      :options="primaryOptions"
      :disabled="!isAdmin"
      :model-value="runAs.primaryMode"
      name="runtime-runas-primary"
      data-automation="runtime-runas-primary"
      @change="onPrimaryChange"
    >
      <template #help>
        <i18n-t
          v-if="isAdmin"
          keypath="appSettings.access.runAsInfo.admin"
          tag="p"
        >
          <template #username>
            <span class="inlineUserName">{{ defaultRunAsUser }}</span>
          </template>
        </i18n-t>
        <i18n-t
          v-else
          keypath="appSettings.access.runAsInfo.nonAdmin"
          tag="p"
        >
          <template #username>
            <span class="inlineUserName">{{ $t('appSettings.access.labels.runAsUsername') }}</span>
          </template>
        </i18n-t>
      </template>
    </RSInputSelect>

    <RSInputSelect
      v-if="showSecondaryOptions"
      :label="$t('appSettings.access.labels.secondaryRunAs')"
      :options="secondaryOptions"
      :disabled="!isAdmin"
      :model-value="runAs.secondaryMode"
      name="runtime-runas-secondary"
      data-automation="runtime-runas-secondary"
      @change="onSecondaryChange"
    />

    <div class="textInputContainer noTopPadding">
      <RSInputText
        v-if="showRunAsInput"
        :label="$t('appSettings.access.labels.runAsAlternateLabel')"
        :show-label="false"
        :model-value="runAs.alternateUser"
        :placeholder="$t('appSettings.access.labels.runAsAlternatePlaceholder')"
        name="runtime-runas-alternate-user"
        data-automation="runtime-runas-alternate-user"
        @change="onAlternateUserChange"
      />
    </div>
  </div>
</template>

<script>
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputText from '@/elements/RSInputText';
import { mapState, mapMutations } from 'vuex';
import {
  ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
  ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
  ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
  RunAsModes,
} from '@/store/modules/accessSettings';

export default {
  name: 'RunAs',
  components: {
    RSInputSelect,
    RSInputText,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    defaultRunAsUser: {
      type: String,
      default: '',
    },
    runAsCurrentAllowed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      runAs: state => state.accessSettings.runAs,
    }),
    altUserText() {
      // The alt username is displayed in the option for non-admins,
      // because the text input is not visible to them.
      return this.isAdmin ? '' : this.runAs.alternateUser;
    },
    secondaryOptions() {
      return [
        { value: RunAsModes.DEFAULT, label: this.$t('appSettings.access.labels.runAsDefault', { username: this.defaultRunAsUser }) },
        { value: RunAsModes.ALTERNATE, label: this.$t('appSettings.access.labels.runAsAlternate', { username: this.altUserText }) },
      ];
    },
    primaryOptions() {
      const options = this.secondaryOptions.slice();

      if (this.runAsCurrentAllowed) {
        options.push({
          value: RunAsModes.CURRENT,
          label: this.$t('appSettings.access.labels.runAsCurrent'),
        });
      }
      return options;
    },
    showRunAsInput() {
      return (
        this.runAs.primaryMode === RunAsModes.ALTERNATE ||
        (
          this.runAs.primaryMode === RunAsModes.CURRENT &&
          this.runAs.secondaryMode === RunAsModes.ALTERNATE
        )
      ) && this.isAdmin;
    },
    showSecondaryOptions() {
      return this.runAs.primaryMode === RunAsModes.CURRENT;
    },
  },
  methods: {
    ...mapMutations({
      updatePrimary: ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
      updateSecondary: ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
      updateAlternateUser: ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
    }),
    onPrimaryChange(value) {
      this.updatePrimary(value);
    },
    onSecondaryChange(value) {
      this.updateSecondary(value);
    },
    onAlternateUserChange(value) {
      this.updateAlternateUser(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.inlineUserName {
  font-style: italic;
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
.noTopPadding {
  padding-top: 0;
}
</style>
