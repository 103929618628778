<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      v-if="hasHelp"
      class="rs-field__help"
    >
      <label
        :for="name"
        class="rs-checkbox__label"
      >
        <input
          v-bind="$attrs"
          :id="name"
          class="rs-checkbox__input"
          type="checkbox"
          :checked="modelValue"
          @change="handleValue"
        >
        {{ label }}
      </label>
      <i
        v-if="hasHelp"
        :class="{active: helpShown}"
        class="rs-field__help-icon"
        aria-label="More Information"
        role="button"
        tabindex="0"
        @click="toggleHelp"
        @keypress="toggleHelp"
      />
    </div>
    <label
      v-else
      :for="name"
      class="rs-checkbox__label"
    >
      <input
        v-bind="$attrs"
        :id="name"
        :name="name"
        class="rs-checkbox__input"
        type="checkbox"
        :checked="modelValue"
        :aria-describedby="`${ name }-message`"
        :aria-invalid="hasError"
        @change="handleValue"
      >
      {{ label }}
    </label>

    <!-- help text -->
    <div
      v-if="helpShown"
      class="rs-field__text"
    >
      <span v-if="$slots.help">
        <slot name="help" />
      </span>
      <span v-else>{{ help }}</span>
    </div>

    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      <span v-if="$slots.message">
        <slot name="message" />
      </span>
      <span v-else>{{ message }}</span>
    </div>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

export default {
  name: 'RSInputCheckbox',
  _events: ['change'],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    dataAutomation: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: ErrorMessage,
    },
  },
  emits: ['change', 'update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  data() {
    return {
      helpShown: false
    };
  },
  computed: {
    hasMessage() {
      return Boolean(this.message) || Boolean(this.$slots.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
  },
  methods: {
    handleValue(ev) {
      this.$emit('change', ev.target.checked);
      this.$emit('update:modelValue', ev.target.checked);
    },
    toggleHelp() {
      this.helpShown = !this.helpShown;
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__text {
    font-size: $rs-font-size-small;
    border: 1px solid $color-light-grey-3;
    background-color: $color-light-grey-2;
    margin: 3px 0 5px 0;
    padding: 0.5rem;
    line-height: 1.5;

    p, ul, ol, li {
      margin: 0.3rem 0;
      padding: 0;
    }

    ul, ol {
      padding-left: 1rem;
    }
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    &-icon {
      width: $rs-icon-size-smaller;
      height: $rs-icon-size-smaller;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      background-repeat: no-repeat;
      background-size: $rs-icon-size-smaller $rs-icon-size-smaller*2;
      background-position: 0 0;
      background-image: url(/images/elements/actionToggleInfo.svg);
      cursor: pointer;

      &.active {
        background-position: 0 (-$rs-icon-size-smaller);
      }

      &:focus {
        @include control-focus;
        border-radius: 50%;
      }
    }
  }
}

.rs-checkbox {
  &__input{
    box-sizing: border-box;
    padding: 5px 10px;
    margin: 2px 6px 2px 2px;
    border: none;

    appearance: none;
    -webkit-appearance: none;
    background-color: $color-white;
    border-radius: 1px;
    border: 2px solid $color-white;
    outline: 2px solid $color-dark-grey;
    padding: 6px;
    vertical-align: top;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:checked {
      background-color: $color-selected;
      outline: 2px solid $color-selected;

      &:disabled {
        background-color: $color-dark-grey;
        outline: 2px solid $color-dark-grey;
      }

      &:after {
        display: block;
        top: 7px;
        left: 7px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:focus {
      outline: 2px solid $color-posit-teal;
    }

    &:disabled {
      @include control-disabled-input;
      cursor: not-allowed;
    }
  }

  &__label {
    position: relative;
    display: inline-block;
    font-size: $rs-font-size-small;
    line-height: 1.5;
    cursor: pointer;
    padding: 3px 0;

    &:has(:disabled) {
      cursor: not-allowed;
    }

    & + .rs-field__error {
      margin-top: unset;
    }
    & + .rs-field__warning {
      margin-top: unset;
    }

    & + .rs-field__info {
      margin-top: unset;
    }
  }
}
</style>
