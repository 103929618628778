<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rs-action">
    <RSButton
      ref="openModalRef"
      :label="$t('authentication.label.changePassword')"
      data-automation="cp-trigger"
      @click="reset(true)"
    />
    <RSModalForm
      v-if="form.show"
      :active="true"
      :subject="$t('authentication.label.changePassword')"
      autocomplete="off"
      @close="reset(false)"
      @submit="changePassword"
    >
      <template #content>
        <EmbeddedStatusMessage
          v-if="apiError"
          :message="apiError"
          :show-close="true"
          class="rsc-status"
          type="error"
          @close="apiError = ''"
        />

        <fieldset :disabled="processing">
          <RSInputPassword
            ref="currentPassword"
            v-model="form.currentPassword"
            :message="errorMessage('currentPassword')"
            :label="$t('authentication.label.currentPassword')"
            autocomplete="off"
            data-automation="cp-current"
            name="cp-current"
          />
          <RSInputPassword
            v-model="form.newPassword"
            :message="errorMessage('newPassword')"
            :label="$t('authentication.label.newPassword')"
            autocomplete="off"
            data-automation="cp-new"
            name="cp-new"
          >
            <template #help>
              <ul>
                <li
                  v-for="item in $tm('authentication.setPassword.help')"
                  :key="item"
                >
                  {{ $rt(item) }}
                </li>
              </ul>
            </template>
          </RSInputPassword>
          <RSInputPassword
            v-model="form.repeatNewPassword"
            :message="errorMessage('repeatNewPassword')"
            :label="$t('authentication.label.repeatNewPassword')"
            autocomplete="off"
            data-automation="cp-repeat-new"
            name="cp-repeat-new"
          />
        </fieldset>
      </template>
      <template #controls>
        <RSButton
          :disabled="processing"
          :label="$t('authentication.label.changePassword')"
          data-automation="cp-submit-btn"
        />
      </template>
    </RSModalForm>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import RSButton from '@/elements/RSButton';
import RSInputPassword from '@/elements/RSInputPassword';
import RSModalForm from '@/elements/RSModalForm';

import { changePassword } from '@/api/authentication';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import * as Validators from '@/utils/validators';
import { mapActions } from 'vuex';
import { SHOW_INFO_MESSAGE } from '@/store/modules/messages';

export default {
  name: 'ChangePassword',
  components: {
    EmbeddedStatusMessage,
    RSButton,
    RSInputPassword,
    RSModalForm,
  },
  emits: ['changed-password'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      processing: false,
      apiError: '',
      form: {
        show: false,
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      },
    };
  },
  validations() {
    return this.formValidations();
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    focusCurrentPassword() {
      this.$refs.currentPassword?.$el.querySelector('input').focus();
    },
    changePassword() {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        return;
      }

      this.processing = true;
      return changePassword(this.form.currentPassword, this.form.newPassword)
        .then(() => {
          this.reset(false);
          this.setInfoMessage({
            message: this.$t('authentication.setPassword.status.changed'),
          });
          this.$emit('changed-password');
        })
        .catch(e => {
          this.reset(true);
          this.apiError = safeAPIErrorMessage(e);
          this.focusCurrentPassword();
        });
    },
    errorMessage(field) {
      const fieldValidators = this.v$.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      // return the first failed validator
      const errorKey = fieldValidators.$errors[0].$validator;
      return this.$t(
        `authentication.validation.${field}.${errorKey}`
      );
    },
    reset(show) {
      this.processing = false;
      this.apiError = '';
      this.form.currentPassword = '';
      this.form.newPassword = '';
      this.form.repeatNewPassword = '';
      // clear validation errors
      this.v$.$reset();
      this.form.show = show;

      if (show) {
        // invoke after next tick (waits for the modal and its contents to render)
        setTimeout(() => this.focusCurrentPassword(), 1);
      } else {
        this.$nextTick(() => this.$refs.openModalRef.focusElement());
      }
    },
    formValidations() {
      return {
        form: {
          currentPassword: Validators.password(),
          newPassword: Validators.password(),
          repeatNewPassword: Validators.repeatPassword(this.form.newPassword),
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rsc-status {
  margin-bottom: 0.9rem;
}
</style>
