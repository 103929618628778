<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSTable
    v-if="applications.length"
    :columns="tableColumns"
    table-name="Content"
    data-automation="content-list__table"
  >
    <RSTableRow
      v-for="app in applications"
      :key="app.guid"
    >
      <RSTableCell
        :cell-id="`app-name-${app.guid}`"
        :has-icon="true"
        :fill="true"
        data-automation="content-row-icon-title-cell"
      >
        <router-link
          :to="{ name: 'apps.access', params: { idOrGuid: app.guid } }"
        >
          <div class="content-table__content-name">
            <div>
              <i
                :class="app.iconClass()"
                class="rs-icon rs-icon--large"
              />
            </div>
            <div :class="[{ 'rsc-content-list__unpublished': !app.isDeployed() }, 'content-table__display-name']">
              {{ app.contentDisplayName() || $t('content.untitled') }}
            </div>
          </div>
        </router-link>
      </RSTableCell>
      <RSTableCell data-automation="content-row-type-cell">
        {{ $t(`content.type.description.${app.contentType()}`) }}
      </RSTableCell>
      <!-- Content author (owner info is available) -->
      <RSTableCell
        v-if="hasOwner(app)"
        :cell-id="`${app.id}-author`"
        data-automation="content-row-author-cell"
      >
        <router-link
          :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
        >
          <span data-automation="content-row-author">
            {{ ownerName(app) }}
          </span>
        </router-link>
      </RSTableCell>
      <!-- Content author (empty cell b/c owner info is missing) -->
      <RSTableCell
        v-else
        data-automation="content-row-author-cell"
      />
      <RSTableCell
        v-if="app.isDeployed()"
        data-automation="content-row-updated-cell"
      >
        {{ lastDeployedTime(app) }}{{ fromGit(app.git) }}
      </RSTableCell>
      <RSTableCell
        v-else
        class="rsc-content-list__unpublished"
      >
        {{ $t('content.list.labels.unpublished') }}
      </RSTableCell>
    </RSTableRow>
  </RSTable>
</template>

<script>
import RSTable from '@/elements/RSTable';
import RSTableRow from '@/elements/RSTableRow';
import RSTableCell from '@/elements/RSTableCell';

import { activeTime } from '@/utils/activeTime.filter';
import { truncate } from '@/utils/truncate.filter';
import { hasOwner, fromGit, displayOwnerName } from '@/utils/contentList';

export default {
  name: 'ContentTable',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.tableColumns = [
      {
        label: this.$t('content.list.headers.name'),
        width: '100%',
      },
      { label: this.$t('content.list.headers.type') },
      { label: this.$t('content.list.headers.owner') },
      { label: this.$t('content.list.headers.lastDeployedTime') },
    ];
  },
  methods: {
    hasOwner,
    fromGit,
    ownerName(app) {
      const ownerName = displayOwnerName(app);
      return truncate(ownerName, 18);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
  },
};
</script>
