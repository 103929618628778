<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="subject || $t('common.modal.confirm.subject')"
    data-automation="confirm-modal"
    @close="$emit('cancel')"
  >
    <template #content>
      <p
        v-if="details"
        data-automation="confirm-modal__details"
      >
        {{ details }}
      </p>
      <slot />
    </template>
    <template #controls>
      <RSButton
        type="primary"
        :label="confirmation || $t('common.buttons.yes')"
        data-automation="confirm-modal__confirmation-btn"
        @click.prevent="$emit('confirm')"
      />
      <RSButton
        type="secondary"
        :label="$t('common.buttons.cancel')"
        class="confirm-modal__cancel-btn"
        data-automation="confirm-modal__cancel-btn"
        @click="$emit('cancel')"
      />
    </template>
  </RSModal>
</template>

<script>
import RSModal from '@/elements/RSModal';
import RSButton from '@/elements/RSButton';

export default {
  name: 'ConfirmModal',
  components: { RSModal, RSButton },
  props: {
    subject: {
      type: String,
      required: false,
      default: null,
    },
    details: {
      type: String,
      required: false,
      default: null,
    },
    confirmation: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['cancel', 'confirm'],
  created() {
    if (!this.details && !this.$slots.default) {
      console.warn('ConfirmModal requires either the `details` prop or a default slot');
    }
  },
};
</script>

<style scoped lang="scss">
.confirm-modal__cancel-btn {
  margin-left: 10px;
}
</style>
