<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="title"
    @close="$emit('close')"
  >
    <template #content>
      <form @submit="onSubmit">
        <RSInputText
          ref="renameText"
          v-model="newName"
          :maxlength="maxNameLength"
          :message="$t('parametersPanel.renameVariantModal.maxLength', { maxNameLength })"
          message-type="info"
          name="renameText"
          :label="$t('parametersPanel.renameVariantModal.name')"
        />
      </form>
    </template>

    <template #controls>
      <div class="controls">
        <RSButton
          type="secondary"
          :label="$t('parametersPanel.renameVariantModal.cancel')"
          @click="$emit('close')"
        />
        <RSButton
          :label="buttonTitle"
          @click="onSubmit"
        />
      </div>
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSInputText from '@/elements/RSInputText';
import RSModal from '@/elements/RSModal';

const MAX_NAME_LENGTH = 50;

export const VariantNameMode = {
  hidden: 'hidden',
  create: 'create',
  rename: 'rename',
};

export default {
  name: 'VariantNameModal',
  components: { RSButton, RSInputText, RSModal },
  props: {
    mode: {
      type: String,
      required: true,
    },
    variantName: {
      type: String,
      required: true,
    }
  },
  emits: ['close'],
  data() {
    return {
      newName: this.variantName,
      maxNameLength: MAX_NAME_LENGTH,
    };
  },
  computed: {
    title() {
      return this.$t(`parametersPanel.renameVariantModal.${this.mode}Title`);
    },
    buttonTitle() {
      return this.$t(`parametersPanel.renameVariantModal.${this.mode}`);
    }
  },
  mounted() {
    this.$refs.renameText.focusElement();
  },
  methods: {
    onSubmit() {
      this.$emit(this.mode, { newName: this.newName });
    }
  }
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  justify-content: flex-end;

  :last-child {
    margin-left: 1em;
  }
}
</style>
