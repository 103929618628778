// Copyright (C) 2022 by Posit Software, PBC.

export const users = {
  title: {
    options: 'Options',
    statusCaps: 'STATUS',
    roleCaps: 'ROLE',
    status: 'Status',
    role: 'Role',
    users: 'Users',
    matchingUsers: 'Matching Users',
    addUser: 'Add User',
    user: 'User',
    lastActive: 'Last Active',
    gettingUsers: 'Getting users...',
    undisclosed: 'Undisclosed',
    locked: 'locked',
    createUser: 'Create User',
    firstName: 'First Name',
    lastName: 'Last Name',
    profile: 'Profile',
    apiKeys: 'API Keys',
    loadingUserProfile: 'Loading User Profile...',
    publishedContent: 'Published Content',
    loadingPublishedContent: `Loading User's Published Content...`,
    joined: 'Joined',
    language: 'Language',
    resetPassword: 'Reset Password',
    confirmAccount: 'Confirm Account',
    accountLocked: 'Account Locked',
    editProfile: 'Edit Profile',
    saveChanges: 'Save Changes',
    updatingProfile: 'Updating Profile...',
    updatedProfile: 'Successfully updated user profile.',
  },
  label: {
    hideOptionsPanel: 'Hide options panel',
    searchForUsers: 'Search for users',
    searchForName: 'Search for a user...',
    searchForUserToImport: 'Search for the User to be added to Posit Connect.',
    status: {
      licensed: 'Licensed',
      locked: 'Locked',
      inactive: 'Inactive',
    },
    role: {
      administrator: 'Administrator',
      administrators: 'Administrators',
      publisher: 'Publisher',
      publishers: 'Publishers',
      viewer: 'Viewer',
      viewers: 'Viewers',
      anonymous: 'Anonymous',
    },
    activeTime: {
      never: 'Never logged in',
      yesterday: 'Yesterday',
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
  },
  action: {
    addUser: 'Add User',
    navigateUser: `Go to {user}'s profile`,
    navigateOwner: `Go to content owner's profile`,
  },
  help: {
    status:
      'Unlocked users who have been recently active count against your license. Locked users and inactive users do not count against user license limits.',
    resetPassword: `
      Users cannot reset their password themselves without an email server configured in Posit Connect.
      Administrators will need to copy the reset password link to the clipboard and pass it to the user
      via other means. This should be done without visiting the link since administrators must have no
      knowledge of the users' passwords.`,
    confirmAccount: `
      Users cannot confirm their own accounts without an email server configured in Posit Connect.
      Administrators will need to copy the account confirmation link to the clipboard and pass it to
      the user via other means. This should be done without visiting the link since administrators must
      have no knowledge of the users' passwords.`,
    locked:
      'A locked user is prohibited from signing into Posit Connect, deploying content, and otherwise interacting with the service. Unlocking the user restores these privileges, if allowed by your license.',
  },
  status: {
    added: 'User {name} added.'
  },
  confirmation: {
    sent:
      'Successfully added user. Sent email to {email} with an account confirmation link.',
    getLinkFailed: `Successfully added user, however failed to obtain the user's account confirmation link. Please contact your Posit Connect administrator.`,
    link: {
      title: 'Account Confirmation Link',
      success: 'Successfully added user',
      description:
        'Pass the following account confirmation link along to the user without visiting it.',
      label: 'Confirmation Link',
      copy: 'Copy Link',
      copied: 'The account confirmation link has been copied to the clipboard.',
    },
  },
  linkCopy: {
    confirmAccount: {
      title: 'Account Confirmation Link',
      description:
        'Pass the following account confirmation link along to the user without visiting it.',
      copiedMessage:
        'The account confirmation link has been copied to the clipboard.',
      textLabel: 'Copy Account Confirmation Link',
    },
    resetPassword: {
      title: 'Reset Password Link',
      description:
        'Pass the following reset password link along to the user without visiting it.',
      copiedMessage:
        'The reset password link has been copied to the clipboard.',
      textLabel: 'Copy Reset Password Link',
    },
  },
  email: {
    confirmAccount: 'Send account confirmation link to {email}?',
    resetPassword: 'Send reset password link to {email}?',
    send: 'Send',
    sentConfirmAccount:
      'An account confirmation link has been emailed to {email}.',
    sentResetPassword: 'A reset password link has been emailed to {email}.',
  },
  options: {
    info: {
      exactTerm: 'exact',
      search: 'Use the Search field to search users by username or name.',
      searchWithUniqueID: 'Use the Search field to search users by username, name, or by their {0} remote identity (UniqueID).',
    },
  },
};
