<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band pushFooter">
    <div class="bandContent mainPage">
      <div class="fullPageFormContainer">
        <h1
          v-if="isFormCompleted"
          class="formTitle"
          data-automation="reset-password-email-sent"
        >
          {{ $t("resetPassword.titles.resetPasswordEmailSent") }}
        </h1>
        <h1
          v-else
          class="formTitle"
          data-automation="reset-password-instructions"
        >
          {{ $t("resetPassword.titles.resetPassword") }}
        </h1>

        <form
          v-if="serverSettings.mailConfigured"
          autocomplete="off"
          @submit.prevent="handleSubmit"
        >
          <div
            v-if="isFormCompleted"
            class="instructions"
            data-automation="reset-password-check-email"
          >
            {{
              $t("resetPassword.instructions.checkEmail")
            }}
          </div>

          <div
            v-else
            class="instructions"
            data-automation="reset-password-enter-username"
          >
            {{
              $t("resetPassword.instructions.enterUsername")
            }}
          </div>
          <div v-if="!isFormCompleted">
            <fieldset class="chunk">
              <div
                class="textInputContainer"
                :class="{ hasError: getError.type }"
              >
                <!-- eslint-disable vuejs-accessibility/no-autofocus -->
                <RSInputText
                  ref="username"
                  v-model.trim="username"
                  autofocus
                  name="username"
                  label="Username"
                  :message-type="getError.type"
                  :message="getError.message"
                  @change="isFormDirty = true"
                />
              </div>
            </fieldset>

            <div class="actions">
              <RSButton
                id="resetPassword"
                type="primary"
                label="Reset Password"
                :disabled="shouldDisableResetPasswordButton"
              />
            </div>
          </div>
        </form>

        <div
          v-else
          class="formish"
        >
          <i18n-t
            tag="p"
            class="instructions"
            keypath="resetPassword.instructions.askYourAdmin"
            data-automation="reset-password-ask-your-admin"
          >
            <template #resetLink>
              <span class="emphasize">{{ $t('resetPassword.instructions.resetLink') }}</span>
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RSInputText from '@/elements/RSInputText';
import RSButton from '@/elements/RSButton';
import { sendOrGetResetPasswordLink } from '@/api/authentication';
import { vueI18n } from '@/i18n/index';
import { mapState, mapMutations } from 'vuex';
import {
  SET_ERROR_MESSAGE_FROM_API,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';

export default {
  name: 'ResetPasswordView',
  components: {
    RSInputText,
    RSButton,
  },
  data() {
    return {
      username: '',
      isFormDirty: false,
      isFormCompleted: false,
      isLoading: false,
    };
  },
  computed: {
    isValidForm() {
      return Boolean(this.username.trim().length);
    },
    getError() {
      if (!this.isValidForm && this.isFormDirty) {
        return {
          message: vueI18n.global.t('resetPassword.validationMessage'),
          type: 'error',
        };
      }

      return { message: null, type: null };
    },
    shouldDisableResetPasswordButton() {
      return !this.isValidForm || this.isLoading;
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.setUsername();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    setUsername() {
      const urlParams = new URLSearchParams(location.hash.split('?')[1]);
      const username = (urlParams.get('u') || '').trim();
      if (username) {
        this.username = username;
      }
    },
    handleSubmit() {
      if (this.shouldDisableResetPasswordButton) {
        return;
      }

      this.isLoading = true;
      this.clearStatusMessage();
      sendOrGetResetPasswordLink(this.username)
        .then(() => {
          this.isFormCompleted = true;
        })
        .catch(error => {
          this.setErrorMessageFromAPI(error);
          this.$refs.username.$el.querySelector('input').focus();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
