<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <RSModal
    :active="true"
    :subject="$t('subscriptions.title', { appName: app.title })"
    class="subscriptions-modal"
    data-automation="subscriptions-modal"
    @close="hideSubscriptionsModal"
  >
    <template #content>
      <i18n-t
        keypath="subscriptions.content"
        tag="p"
      >
        <span class="emphasis">{{ $t('subscriptions.notification') }}</span>
      </i18n-t>
      <RSCheckboxGroup
        :name="$t('subscriptions.title', { appName: app.title })"
        :options="subscriptions"
      />
    </template>
    <template #controls>
      <RSButton
        type="primary"
        :label="$t('common.buttons.save')"
        @click="onClick"
      />
    </template>
  </RSModal>
</template>

<script>
import RSModal from '@/elements/RSModal';
import RSButton from '@/elements/RSButton';
import RSCheckboxGroup from '@/elements/RSCheckboxGroup';
import { getSubscribers, subscribeUser, unsubscribeUser } from '@/api/subscriptions';
import { mapState } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  name: 'SubscriptionsModal',
  components: { RSModal, RSButton, RSCheckboxGroup },
  data() {
    return {
      subscriptions: [],
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      variants: state => state.parameterization.variants,
      currentVariant: state => state.parameterization.currentVariant,
    }),
  },
  async created() {
    for (const variant of this.variants) {
      const subscribers = await getSubscribers(variant.id);
      this.subscriptions.push({
        checked: !subscribers.optOut,
        label: variant.name,
        id: variant.id
      });
    }
  },
  methods: {
    async onClick() {
      const { guid: userGuid } = this.currentUser;
      for (const subscription of this.subscriptions) {
        if (subscription.checked) {
          await subscribeUser(subscription.id, userGuid);
        } else {
          await unsubscribeUser(subscription.id, userGuid);
        }
      }
      this.hideSubscriptionsModal();
    },
    hideSubscriptionsModal() {
      const params = {};
      let routeName = 'apps.output';
      const guid = this.app.guid;
      params.idOrGuid = guid;

      if (!isEmpty(this.currentVariant)) {
        params.id = this.currentVariant.id;
        routeName += '.variant';
      }

      this.$router.push({
        name: routeName,
        params,
        replace: true,
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .emphasis {
    font-weight: 700;
  }

</style>
