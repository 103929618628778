<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div>
    <div class="actions alignRight extraSpacingAbove">
      <RSButton
        :label="$t('content.settings.git.titles.updateNow')"
        class="rsc-button-small"
        @click.prevent="updateNowClicked"
      />
    </div>

    <RSModal
      v-if="showUpdateNowDialog"
      :active="true"
      :subject="$t('content.settings.git.titles.updateNow')"
      class="unhide-overflow"
      @close="hideDialog"
      @submit="hideDialog"
    >
      <template #content>
        <EmbeddedStatusMessage
          v-if="activity.show"
          :message="activity.text"
          :show-close="false"
          :type="activity.type"
        />
        <div v-if="message.show">
          {{ message.text }}
        </div>
        <div v-if="showDeployLogs">
          <div class="chunk">
            <LogViewer :entries="logEntries" />
          </div>
        </div>
      </template>
      <template
        v-if="showOk"
        #controls
      >
        <RSButton
          id="gun-ok"
          :label="$t('common.buttons.ok')"
          @click="hideDialog"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

import { safeAPIErrorMessage } from '@/api/error';
import { deployApplicationResult } from '@/api/app';
import { getBranchesResult } from '@/api/git';
import { JobLogLine } from '@/api/dto/job';
import EmbeddedStatusMessage, {
  ActivityMessage,
  ErrorMessage,
} from '@/components/EmbeddedStatusMessage';
import LogViewer from '@/components/LogViewer';

export function initialData() {
  return {
    showUpdateNowDialog: false,
    showOk: false,
    activity: {
      show: false,
      text: '',
      type: '',
    },
    message: {
      show: false,
      text: '',
    },
    showDeployLogs: false,
    logEntries: [],
    task: {
      finished: false,
      code: -1,
    },
  };
}

export default {
  name: 'GitUpdateNow',
  components: {
    EmbeddedStatusMessage,
    LogViewer,
    RSButton,
    RSModal,
  },
  props: {
    appGuid: {
      type: String,
      required: true,
    },
    git: {
      type: Object,
      required: true,
    },
  },
  data() {
    return initialData();
  },
  methods: {
    updateNowClicked() {
      this.showDialog();
      this.showStatus(
        this.$t('content.settings.git.titles.checkingForChanges')
      );

      return getBranchesResult(this.git.repositoryUrl)
        .then(({ data }) => {
          this.hideStatus();
          this.processBranchesResult(data);
        })
        .catch(err => {
          this.hideStatus();
          this.showStatus(safeAPIErrorMessage(err), { error: true });
          throw err;
        });
    },
    processBranchesResult(refs) {
      const matchingRef = refs.filter(ref => ref.branch === this.git.branch);
      // uncomment the following line to force deploys (useful while testing)
      // matchingRef[0] = {ref: '', branch: this.git.branch};

      if (matchingRef.length === 0) {
        // branch doesn't exist anymore
        this.showStatus(
          this.$t('content.settings.git.errors.branchDisappeared', {
            branch: this.git.branch,
          }),
          { error: true }
        );
      } else if (matchingRef[0].ref === this.git.lastKnownCommit) {
        // no changes in branch
        this.showStatus(this.$t('content.settings.git.titles.noChanges'), {
          plain: true,
        });
      } else if (matchingRef[0].ref !== this.git.lastKnownCommit) {
        // newer commit detected
        this.showStatus(this.$t('content.settings.git.titles.changes'));
        this.showDeployLogs = true;

        return deployApplicationResult(this.appGuid, this.taskCallback)
          .then(task => {
            if (task.error) {
              this.showStatus(task.error, { error: true });
              throw new Error(task.error);
            } else {
              this.hideStatus();
              this.buttonText = this.$t(
                'content.deploymentDialog.common.openContentLabel'
              );
            }
          })
          .catch(err => {
            this.showStatus(String(err), { error: true });
            throw err;
          });
      }
    },
    showDialog() {
      this.showUpdateNowDialog = true;
    },
    hideDialog() {
      if (this.task.finished && this.task.code === 0) {
        // reload page on successful deployment
        this.reloadPage();
      }
      Object.assign(this, initialData());
    },
    reloadPage() {
      // TODO wire this up so that the app is refreshed w/o a full-page reload.
      window.location.reload();
    },
    showStatus(text, { error = false, plain = false } = {}) {
      this.showOk = true;
      this.hideStatus();

      if (plain) {
        this.message = { show: true, text };
      } else {
        this.activity = {
          show: true,
          text,
          type: error ? ErrorMessage : ActivityMessage,
        };
      }
    },
    hideStatus() {
      this.activity.show = false;
      this.message.show = false;
    },
    taskCallback(task) {
      const newEntries = task.status.map(line => (new JobLogLine({
        line: line,
        isError: false,
      })));
      this.logEntries = this.logEntries.concat(newEntries);
      this.task.finished = task.finished;
      this.task.code = task.code;
    },
  },
};
</script>

<style scoped>
.rsc-button-small {
  font-size: 0.8rem;
}
.unhide-overflow {
  .statusMessage {
    overflow: visible;
    margin-bottom: 0.9rem;
  }
  .statusMessage.showing {
    max-height: 120px;
  }
}
</style>
