<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <button
    ref="button"
    v-bind="$attrs"
    :class="['rs-button', noLabel, type, labelWidth]"
    :aria-label="label"
    :type="buttonType"
    @click="clickHandler"
  >
    <div
      class="wrapper"
      :class="[ alignment, size ]"
    >
      <img
        v-if="icon"
        alt=""
        :src="icon"
      >
      <span v-if="!hasDefaultSlot && !iconOnly">{{ label }}</span>
      <!-- @slot The text label of the button (can also be set as the label prop) -->
      <slot />
    </div>
  </button>
</template>

<script>
export const ButtonType = {
  primary: 'primary',
  secondary: 'secondary',
  link: 'link',
};

export const ButtonAlignment = {
  horizontal: 'horizontal',
  vertical: 'vertical'
};

export const ButtonSize = {
  small: 'small',
  normal: 'normal',
  large: 'large'
};

export default {
  name: 'RSButton',
  inheritAttrs: false,
  props: {
    /**
     * The type of button
     * @values primary, secondary, link
     */
    type: {
      type: String,
      default: ButtonType.primary,
      validator: value => {
        return Object.values(ButtonType).includes(value);
      }
    },
    /**
     * The text label of the button (can also be set as the default slot)
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * The icon to display on the button
     *
     * ```
     * import iconNew from '@/assets/images/iconNew.svg'
     * import RSButton from '@/components/RSButton'
     *
     * const ButtonPanel = ({
     *   components: { RSButton }
     *   data: () => ({ iconNew }),
     *   template: `
     *     <div class="button-panel">
     *       <RSButton label="New" icon="iconNew" @click="onClick" />
     *     </div>
     *   `
     * })
     * ```
     *
     */
    icon: {
      type: String,
      default: null
    },
    /**
     * The size of the button
     * @values small, normal, large
     */
    size: {
      type: String,
      default: ButtonSize.normal,
      validator: value => {
        return Object.values(ButtonSize).includes(value);
      }
    },
    /**
     * The alignment of the button label with the icon.
     * Unnecessary if the button has no icon or no text.
     * @values horizontal, vertical
     */
    alignment: {
      type: String,
      default: ButtonAlignment.horizontal,
      validator: value => {
        return Object.values(ButtonAlignment).includes(value);
      }
    },
    /**
     * Show the icon and only render the label as an aria-label.
     */
    iconOnly: {
      type: Boolean,
      default: false
    },
    /**
     * Set the button width from the label, rather than a uniform
     * minimum width.
     */
    useLabelWidth: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    buttonType() {
      return this.type === ButtonType.primary ? 'submit' : 'button';
    },
    hasDefaultSlot() {
      return Boolean(this.$slots.default);
    },
    noLabel() {
      return this.iconOnly ? 'no-label' : '';
    },
    labelWidth() {
      return this.useLabelWidth ? 'label-width' : '';
    }
  },
  methods: {
    focusElement() {
      this.$refs.button.focus();
    },
    clickHandler(e) {
      /**
       * Click event
       *
       * @event click
       * @property {event} native click event
       */
      this.$emit('click', e);
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-button,
a.rs-button {
  min-width: 8.75rem;
  margin: 0;
  padding: 0.7rem 1.1rem;
  font-size: $rs-font-size-normal;
  line-height: 1rem;
  overflow: visible;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition-duration: 250ms;
  text-align: center;

  &[disabled] {
    cursor: not-allowed;
  }

  &.label-width {
    min-width: unset;
  }

  &.no-label {
    min-width: unset;

    .wrapper.vertical.vertical,
    .wrapper.horizontal.horizontal {
      img {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  @include control-visible-focus;

  &.primary {
    background-color: $color-primary;
    color: $color-primary-inverse;

    &:hover:not([disabled]) {
      background-color: $color-primary-dark;
    }
  }

  &.secondary {
    background-color: $color-secondary;
    color: $color-secondary-inverse;

    &:hover:not([disabled]) {
      background-color: $color-secondary-hover;
    }
  }

  &.link {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $color-primary-dark;
    min-width: unset;
    padding-left: .5rem;
    padding-right: .5rem;

    &:hover:not([disabled]) {
      background-color: $color-light-blue;
      color: $color-blue;
    }
  }

  &.full-width {
    width: 100%;
    max-width: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.vertical {
      flex-direction: column;

      img {
        margin-right: 0;
        margin-bottom: 0.5rem;
      }

      &.small {
        img {
          margin-right: 0;
          margin-bottom: 0.3rem;
        }
      }

      &.large {
        img {
          margin-right: 0;
          margin-bottom: 0.7rem;
        }
      }
    }

    img {
      width: 24px;
      margin-right: 0.5rem;
    }

    &.small {
      font-size: $rs-font-size-small;

      img {
        width: 16px;
        margin-right: 0.3rem;
      }
    }

    &.large {
      font-size: $rs-font-size-large;

      img {
        width: 40px;
        margin-right: 0.7rem;
      }
    }
  }
}

a.rs-button {
  display: inline-block;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

</style>
