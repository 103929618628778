<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <label
      :for="`rs_radio_${name}_${value}`"
      :title="title"
      class="rs-radio__label"
    >
      <input
        :id="`rs_radio_${name}_${value}`"
        :name="name"
        :checked="modelValue == value"
        :value="value"
        :disabled="disabled"
        class="rs-radio__input"
        type="radio"
        @change="handleChange"
      >
      {{ label }}
      <!-- message text -->
      <div
        v-if="message"
        :id="`${ name }-message`"
        class="rs-field__message"
        :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
      >
        {{ message }}
      </div>
    </label>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

export default {
  name: 'RSInputRadio',
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: ErrorMessage,
    },
  },
  emits: ['change', 'update:modelValue'],
  compatConfig: { COMPONENT_V_MODEL: false },
  computed: {
    hasError() {
      return this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.messageType === InfoMessage;
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__message {
    @include message;
    padding-left: 24px;
  }
  &__error {
    color: $color-error;
  }
  &__warning {
    color: $color-warning;
  }
  &__info {
    color: $color-info;
  }
}

.rs-radio {
  &__label {
    position: relative;
    display: inline-block;
    font-size: $rs-font-size-small;
    line-height: 1.5;
    cursor: pointer;
    padding: 3px 0;

    & + .rs-field__error {
      margin-top: unset;
    }
    & + .rs-field__warning {
      margin-top: unset;
    }
    &:has(:disabled) {
      cursor: not-allowed;
    }
  }
  &__input {
    border: none;
    margin: 4px 6px 2px 2px;
    width: auto;
    appearance: none;
    -webkit-appearance: none;
    background-color: $color-white;
    border-radius: 45%;
    border: 2px solid $color-white;
    box-shadow: 0 0 0 2px $color-dark-grey;
    padding: 4px;
    vertical-align: top;
    &:disabled {
      cursor: not-allowed;
      box-shadow: 0 0 0 2px $color-medium-grey;
    }
    &:checked {
      background-color: $color-selected;
      box-shadow: 0 0 0 2px $color-selected;

      &:disabled {
        background-color: $color-dark-grey;
        box-shadow: 0 0 0 2px $color-medium-grey;
      }
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px $color-posit-teal;
    }
  }
}
</style>
