// Copyright (C) 2022 by Posit Software, PBC.

import {
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLOT,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_BOKEH,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_SHINY,
  PYTHON_STREAMLIT,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_R,
  QUARTO_R_SCRIPT,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  R_VETIVER,
  SHINY_APP,
  SHINY_RMARKDOWN,
  TENSOR_FLOW,
} from '@/constants/contentTypes';

const publish = {
  keys: {
    CONNECT_SERVER: '&lt;server-url&gt;',
    CONNECT_API_KEY: '&lt;api-key&gt;',
  },
  pinEnvironment: {
    title: 'Environment Variables',
    intro:
      `The following environment variables must be set locally and within @.sys:{'system.displayName'} for pins to work:`,
    warning: {
      note: 'Advanced:',
      text:
        'Using pins is an advanced topic that requires an understanding ' +
        'of environment variables and using an API key.',
    },
    links: {
      title: 'Learn more about these advanced topics',
      vars: `Using Environment Variables in @.sys:{'system.displayName'}`,
      renviron: 'Using Renviron to set environment variables',
      api: 'Creating and Deleting API Keys',
    },
  },
  pinOutro: {
    body:
    'Once your pin is published, you control who can access it, ' +
    'when it gets updated, and much more via its settings panels.',
    learnMore: {
      title: 'Introduction to Pins',
      target: 'user/pins',
      text: 'Learn more in the User Guide',
    },
  },
  downloadExample: 'Download the Example',
  downloadLabel: 'Download',
  exampleSubject: 'Publish',
  exampleThumbnailAlt: 'Example thumbnail',
  installQuarto: {
    title: 'Get Quarto',
    body: {
      reason: 'A Quarto installation is needed to render and publish Quarto content.',
      rstudio:
        'RStudio and Posit Workbench users have Quarto available by default in ' +
        'the RStudio UI, at the R console, and in the integrated terminal.',
      install: 'Please {instructions} for other development environments.',
      instructions: 'download and install Quarto',
    },
    learnMore: 'Learn more about Quarto',
  },
  installRSConnectJupyter: {
    body:
      `To publish a Jupyter notebook to @.sys:{'system.displayName'}, first install ` +
      'the rsconnect-jupyter plugin in your Jupyter environment.',
    learnMore: 'Learn more about the rsconnnect-jupyter plugin',
    title: 'Get the Connect Jupyter Plugin',
  },
  jupyterNotebookFile: {
    body:
      'If you already have a Jupyter notebook, open it. If ' +
      'not, go ahead and make one.',
    learnMore: 'Learn more about Jupyter',
    title: 'Open a Jupyter Notebook',
  },
  jupyterNotebookIntro: {
    body:
      `Python users can publish to @.sys:{'system.displayName'} using Jupyter Notebooks` +
      ', a popular tool for literate programming. Rendered notebooks can ' +
      `be deployed to @.sys:{'system.displayName'} for easy distribution, or the source notebook ` +
      'and environment can be published making it easy to refresh, ' +
      'schedule, and distribute.',
  },
  jupyterNotebookOpen: {
    body:
      'Unzip {zip}, then navigate to the {dir} directory and upload ' +
      '{source} from within Jupyter:',
    learnMore: 'Learn more about Jupyter',
    title: 'Open the Jupyter Notebook',
  },
  jupyterNotebookOutro: {
    body:
      'Once your notebook is published, you control who can access it, ' +
      'when it gets updated, and much more via its settings panels.',
    learnMore: {
      title: 'Introduction to Jupyter Notebooks',
      target: 'user/jupyter-notebook',
      text: 'Learn more in the User Guide',
    },
  },
  jupyterVoilaOutro: {
    body:
      'Once your Voila dashboard is published, you control who can access it, ' +
      'when it gets updated, and much more via its settings panels.',
    learnMore: {
      title: 'Introduction to Voila Dashboards',
      target: 'user/jupyter-voila',
      text: 'Learn more in the User Guide',
    },
  },
  openExample: {
    aProject: 'a project file',
    title: 'Open the Example',
    subtitle: {
      server: 'Using Posit Workbench:',
      desktop: 'Using RStudio Desktop:',
    },
    body: {
      server:
        'Use the {upload} command in the Files pane to upload {zip}, and then ' +
        'open {rproj} from within the IDE.',
      desktop:
        'Unzip {zip} locally, and then open {rproj} from within the IDE.',
    },
    thumbAlt: 'Posit Workbench Files pane with arrow pointing at Upload button',
    upload: 'Upload',
  },
  openIDE: {
    title: 'Open the RStudio IDE',
    body:
      'Use your desktop version, or Posit Workbench if your organization ' +
      'supports it.',
    learnMore: 'Learn more about the RStudio IDE',
  },
  outro: {
    title: `That's It!`,
  },
  plumberAPIFile: {
    body:
      'If you already have a Plumber API file, open it. If not, go ahead ' +
      'and make one.',
    learnMore: 'Learn more about Plumber',
    title: 'Open a Plumber API File',
  },
  plumberAPIIntro: {
    body:
      'Deploy a model for other systems to use in production via a Plumber ' +
      `API. Plumber and @.sys:{'system.displayName'} make it easy to create and deploy RESTful ` +
      'Web APIs using R.',
    howToPublish: `Let's walk through how to publish one.`,
  },
  plumberAPIOutro: {
    body:
      'Once your Plumber API is published, your code can be accessed from ' +
      'other systems. You control who can access it and more via its ' +
      'settings panels.',
    learnMore: {
      title: 'Introduction to Plumber',
      target: 'user/plumber',
      text: 'Learn more in the User Guide',
    },
  },
  publishIDE: {
    body: {
      pressPublish:
        'In the IDE, press the {publish} button in the upper right corner of ' +
        'the Source pane or the Output pane and follow the on-screen instructions.',
      connectURL:
        `If you're publishing for the first time, enter the URL for @.sys:{'system.displayName'}:`,
      warning: {
        note: 'Note:',
        text:
          'Publishing for the first time can often take a while, ' +
          `since @.sys:{'system.displayName'} will download and build all dependent packages.`,
      },
      publish: 'Publish',
    },
    learnMore: 'Learn more about how to publish',
    screenshotAlt: 'Publish button in RStudio IDE',
    title: 'Publish',
  },
  publishRConsole: {
    title: 'Publish',
    body: {
      preamble: 'Use the rsconnect package to deploy the unzipped project.',
      account: 'If this is your first time publishing from RStudio, you need to {connectRStudio} before deploying your code.',
      connectRStudio: 'link the IDE to a Connect account',
      publish: 'Publish your content by using the R console to run the following command:',
    },
    links: {
      publish: 'Learn more about how to publish',
      package: 'Learn more about the rsconnect package',
    },
    warning: {
      note: 'Note:',
      text:
        'Publishing for the first time can often take a while, ' +
        `since @.sys:{'system.displayName'} will download and build all dependent packages.`,
    },
  },
  publishJupyter: {
    body: {
      pressPublish:
        'In the Jupyter toolbar, press the {publish} button and follow the ' +
        'on-screen instructions.',
      rsconnectJupyterInstall:
        `If you don't see the Publish button, the required ` +
        'rsconnect-jupyter plugin has not been correctly installed in your ' +
        'Jupyter environment.',
      publish: 'Publish',
    },
    warning: {
      note: 'Note:',
      text:
        'You must have a matching version of Python installed and configured ' +
        `on the @.sys:{'system.displayName'} server.`,
    },
    learnMore: 'Learn more about how to publish Jupyter Notebooks',
    troubleshooting: 'Troubleshooting Python with Posit Connect',
    screenshotAlt: 'rsconnect Publish button in Jupyter toolbar',
    title: 'Publish',
  },
  pythonAPIOutro: {
    body:
      'Once your Python API is published, your code can be accessed from ' +
      'other systems. You control who can access it and more via its ' +
      'settings panels.',
    learnMore: {
      title: `Introduction to Python APIs in @.sys:{'system.displayName'}`,
      target: 'user/flask',
      text: 'Learn more in the User Guide',
    },
  },
  pythonFastAPIOutro: {
    body:
      'Once your Python FastAPI is published, your code can be accessed from ' +
      'other systems. You control who can access it and more via its ' +
      'settings panels.',
    learnMore: {
      title: `Introduction to Python APIs in @.sys:{'system.displayName'}`,
      target: 'user/fastapi',
      text: 'Learn more in the User Guide',
    },
  },
  pythonDashOutro: {
    body:
      'Once your Dash app is published, you control who can access it, ' +
      'its runtime settings, and much more via its settings panels.',
    learnMore: {
      title: `Dash in @.sys:{'system.displayName'}`,
      target: 'user/dash',
      text: 'Learn more in the User Guide',
    },
  },
  pythonStreamlitOutro: {
    body:
      'Once your Streamlit app is published, you control who can access it, ' +
      'its runtime settings, and much more via its settings panels.',
    learnMore: {
      title: `Streamlit in @.sys:{'system.displayName'}`,
      target: 'user/streamlit',
      text: 'Learn more in the User Guide',
    },
  },
  pythonShinyOutro: {
    body:
      'Once your Shiny app is published, you control who can access it, ' +
      'its runtime settings, and much more via its settings panels.',
    learnMore: {
      title: `Shiny in @.sys:{'system.displayName'}`,
      target: 'user/shiny-python',
      text: 'Learn more in the User Guide',
    },
  },
  pythonBokehOutro: {
    body:
      'Once your Bokeh app is published, you control who can access it, ' +
      'its runtime settings, and much more via its settings panels.',
    learnMore: {
      title: `Bokeh in @.sys:{'system.displayName'}`,
      target: 'user/bokeh',
      text: 'Learn more in the User Guide',
    },
  },
  vetiverOutro: {
    body:
      'Once your Vetiver API is published, your code can be accessed from ' +
      'other systems. You control who can access it and more via its ' +
      'settings panels.',
    learnMore: {
      title: `Introduction to vetiver in @.sys:{'system.displayName'}`,
      target: 'user/vetiver',
      text: 'Learn more in the User Guide',
    },
  },
  pythonDependencies: {
    title: 'Open & Install Dependencies',
    body:
      'Unzip {zip} locally, and open a command prompt to the unzipped ' +
      'project directory.',
    install: 'Install required packages using pip:',
    links: {
      pip: 'Learn more about pip - the Python Package Installer',
      requirements: 'Learn more about using requirements.txt with pip',
    },
  },
  rmarkdownAddParams: {
    body:
      'Declare one or more params in the YAML header and use those params ' +
      'in your R Markdown code.',
    learnMore: 'Learn more about parameters',
    title: 'Add Parameters',
  },
  rmarkdownFile: {
    body:
      'If you already have an R Markdown file, open it. If not, go ahead ' +
      'and make one.',
    learnMore: 'Learn more about R Markdown',
    title: 'Open an R Markdown File',
  },
  rmarkdownIntro: {
    body:
      'R Markdown is the underpinning for persuasive, dynamic, data-driven ' +
      'documents. Combine text, tables and visualizations in a seamless ' +
      'story. Schedule updates, and email the results automatically to ' +
      'your colleagues.',
    howToPublish: `Let's walk through how to publish one.`,
  },
  rmarkdownOutro: {
    body:
      'Once your document is published, you control who can access it, ' +
      'when it gets updated, and much more via its settings panels.',
    learnMore: {
      title: 'Introduction to R Markdown',
      target: 'user/rmarkdown',
      text: 'Learn more in the User Guide',
    },
  },
  rmarkdownParamsIntro: {
    body:
      'Adding parameters to an R Markdown document gives you all the joy ' +
      'of R Markdown plus interactivity. When you add params to an R ' +
      'Markdown document, you and others can provide inputs to dynamically ' +
      'tailor the resulting report.',
    howToPublish: `Here's how to publish and interact with one.`,
  },
  rmarkdownParamsOutro: {
    body: {
      postPublish:
        'Once published, anyone with access can change the input parameters ' +
        `via the Input panel in @.sys:{'system.displayName'}.`,
      sharing:
        'The resulting customized reports can be saved and shared with ' +
        'others, or kept private.',
    },
    learnMore: {
      title: 'Parameterized R Markdown',
      target: 'user/param-rmarkdown/',
      text: 'Learn more in the User Guide',
    },
  },
  runExample: {
    body: {
      open:
        'In the IDE, open {source} and run the example by pressing the {button} button ' +
        'in the Source pane header.',
      warning: {
        note: 'Note:',
        text:
          'The RStudio IDE will prompt you to install any required packages ' +
          '— this may take a few minutes.',
      },
    },
    title: 'Run the Example',
    screenshotLocale: 'en',
    alt: 'Screenshot of RStudio IDE Source pane header.',
  },
  shinyAppFile: {
    title: 'Open a Shiny App',
    body:
      'If you already have a Shiny App, open it. If not, go ahead and make ' +
      'one.',
    howToPublish: 'Learn more about Shiny',
  },
  shinyAppIntro: {
    body:
      'Shiny is an R package that makes it easy to build interactive web ' +
      'apps straight from R.',
    howToPublish: `Let's walk through how to publish one.`,
  },
  shinyAppOutro: {
    body:
      'Once your Shiny App is published, you control who can access it, ' +
      'its runtime settings, and much more via its settings panels.',
    learnMore: {
      title: 'Introduction to Shiny',
      target: 'user/shiny',
      text: 'Learn more in the User Guide',
    },
  },
  quartoFile: {
    body:
      'If you already have a Quarto file or project, open it. If not, go ' +
      'ahead and make one. It is strongly recommended that you use ' +
      'v2022.02 of the RStudio IDE, which includes support for editing ' +
      'and previewing Quarto documents.',
    learnMore: 'Learn more about working with Quarto in the RStudio IDE',
    title: 'Open a Quarto File',
  },
  quartoIntro: {
    body:
      'Quarto is an open-source scientific and technical publishing ' +
      'system built on Pandoc. Author with scientific markdown. Create ' +
      'dynamic content with Python, R, and Observable. Publish articles, ' +
      'reports, presentations, websites, and more.',
    howToPublish: `Let's walk through how to publish Quarto content.`,
    support: {
      note: 'Note:',
      text:
        'Quarto publishing is not available. ' +
        'To publish Quarto content, an adminstrator must install and ' +
        'configure Quarto on this server.',
      learnMore: 'Learn more about configuring Quarto with Posit Connect',
    },
  },
  quartoOutro: {
    body:
      'Once your content is published, you control who can access it, ' +
      'when it gets updated, and much more via its settings panels.',
    learnMore: {
      title: 'Learn more about Quarto',
      target: 'https://quarto.org/docs/guide/',
      text: 'Learn more about Quarto'
    },
  },
  subject: {
    [JUPYTER_NOTEBOOK]: 'Publish a Jupyter Notebook',
    [JUPYTER_VOILA]: 'Publish a Voilà Dashboard',
    [PIN]: 'Publish a Pin',
    [PLOT]: 'Publish a Plot',
    [PLUMBER_API]: 'Publish a Plumber API',
    [RMARKDOWN]: 'Publish an R Markdown Document',
    [RMARKDOWN_PARAMS]: 'Publish a Parameterized R Markdown Document',
    [R_VETIVER]: 'Publish a Vetiver API in R',
    [SHINY_APP]: 'Publish a Shiny App',
    [SHINY_RMARKDOWN]: 'Publish a Shiny R Markdown Document',
    [TENSOR_FLOW]: 'Publish a TensorFlow Model',
    [PYTHON_API]: 'Publish a Python API',
    [PYTHON_FASTAPI]: 'Publish a Python FastAPI',
    [PYTHON_DASH]: 'Publish a Dash App',
    [PYTHON_STREAMLIT]: 'Publish a Streamlit App',
    [PYTHON_BOKEH]: 'Publish a Bokeh App',
    [PYTHON_SHINY]: 'Publish a Python Shiny App',
    [PYTHON_VETIVER]: 'Publish a Vetiver API in Python',
    [QUARTO]: 'Publish Quarto Content',
    [QUARTO_R]: 'Publish Quarto Content using R',
    [QUARTO_R_SCRIPT]: 'Publish an R Script using Quarto',
    [QUARTO_PYTHON]: 'Publish Quarto Content using Python',
    [QUARTO_PYTHON_SCRIPT]: 'Publish a Python Script using Quarto',
    [QUARTO_WEBSITE]: 'Publish a Quarto website',
  }
};

export { publish };
