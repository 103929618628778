<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rs-action">
    <RSButton
      ref="openModalButton"
      :label="$t('users.title.resetPassword')"
      data-automation="rp-trigger"
      @click="resetPassword"
    />
    <CopyTextDialog
      v-if="copyUrlDialog.show"
      :title="$t('users.linkCopy.resetPassword.title')"
      :description="$t('users.help.resetPassword')"
      :copied-message="$t('users.linkCopy.resetPassword.copiedMessage')"
      :text-label="$t('users.linkCopy.resetPassword.textLabel')"
      :text="copyUrlDialog.url"
      @close="copyUrlDialog.show = false"
    />

    <RSModal
      v-if="showSendEmailDialog"
      :active="true"
      :subject="$t('users.title.resetPassword')"
      @close="closeModal"
    >
      <template #content>
        {{ $t('users.email.resetPassword', { email: userProfile.email }) }}
      </template>
      <template #controls>
        <RSButton
          :label="$t('users.email.send')"
          data-automation="rp-send"
          @click="sendEmail"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

import { sendOrGetResetPasswordLink } from '@/api/authentication';
import CopyTextDialog from '@/components/CopyTextDialog';
import { mapMutations, mapActions } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';

export default {
  name: 'ResetPassword',
  components: {
    CopyTextDialog,
    RSButton,
    RSModal,
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
    canEmail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copyUrlDialog: {
        show: false,
        url: '',
      },
      showSendEmailDialog: false,
    };
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    closeModal() {
      this.showSendEmailDialog = false;
      this.$nextTick(() => this.$refs.openModalButton.focusElement());
    },
    resetPassword() {
      if (this.canEmail) {
        this.showSendEmailDialog = true;
      } else {
        // show copyable 'reset password' link
        return sendOrGetResetPasswordLink(this.userProfile.username)
          .then(({ url }) => {
            this.copyUrlDialog.url = url;
            this.copyUrlDialog.show = true;
          })
          .catch(e => {
            this.copyUrlDialog.show = false;
            this.setErrorMessageFromAPI(e);
          });
      }
    },
    sendEmail() {
      return sendOrGetResetPasswordLink(this.userProfile.username)
        .then(() => {
          this.setInfoMessage({
            message: this.$t('users.email.sentResetPassword', {
              email: this.userProfile.email,
            }),
          });
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.closeModal();
        });
    },
  },
};
</script>
