<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<script setup>
import {
  watch,
  reactive,
  computed,
  onBeforeMount,
} from 'vue';
import { useStore } from 'vuex';
import { searchUsers } from '@/api/users';
import { ViewType } from '@/store/modules/contentList';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { useContentSearch } from '@/composables/contentSearch';
import { useContentFilter } from '@/composables/contentFilter';
import {
  sortOptions,
  contentTypeFilterOptions,
} from './filters';
import ContentListOps from './ContentListOps.vue';
import ContentTable from './ContentTable.vue';
import ContentBlog from './ContentBlog.vue';
import RSInputSearch from '@/elements/RSInputSearch.vue';
import Paginator from '@/components/Paginator.vue';
import Dropdown from '@/components/Dropdown.vue';

const store = useStore();

const localState = reactive({
  searchResults: [],
  sortOrderValue: 'created_time-desc',
  usersOptions: [],
  contentTypeOptions: contentTypeFilterOptions(),
  total: 0,
  showPermissionsModal: false,
});
const serverSettings = computed(() => store.state.server.settings);
const contentListViewType = computed(() => {
  return store.state.contentList.viewType || serverSettings.value.defaultContentListView;
});
const isBlogView = computed(() => contentListViewType.value === ViewType.BLOG);
const isTableView = computed(() => contentListViewType.value === ViewType.TABLE);

/**
 * Build search users payload.
 * - Only looking for users that can publish (owners).
 * - Do not include remote users, only local users can own content.
 * @param {String} prefix Search prefix
 * @returns {Object} Search users payload.
 */
const searchUsersPayload = (prefix = '') => ({
  prefix,
  userRole: {
    publisher: true,
    administrator: true,
  },
  includeRemote: false,
});

/**
 * Parse users to filters.
 * @param {Array} users Array of users
 * @returns {Array} Filter options to be used on filter dropdown.
 */
const usersToFilters = users => users.map(u => ({
  label: u.fullName,
  value: u.username,
  sub: u.username,
}));

/**
 * Lookup users with the given prefix and populate the dropdown options with the results.
 * @param {String} prefix Search prefix
 */
const lookupUsers = async(prefix = '') => {
  try {
    const usersRes = await searchUsers(
      serverSettings.value,
      searchUsersPayload(prefix),
    );
    localState.usersOptions = usersToFilters(usersRes.results);
  } catch (err) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
};

/**
 * Filter down the content type options and populate the dropdown with the results.
 * @param {String} prefix Search prefix
 */
const lookupContentTypes = (prefix = '') => {
  const filterOptions = contentTypeFilterOptions();
  if (!prefix) {
    localState.contentTypeOptions = filterOptions;
  }
  localState.contentTypeOptions = filterOptions.filter(opt => `${opt.label} ${opt.value}`.includes(prefix));
};

/**
 * Method to handle and update state with incoming search results
 * @param {Object} payload
 * @param {Number} payload.total
 * @param {Array} payload.results
 */
const onSearchResults = ({ total, results = [] } = {}) => {
  localState.searchResults = results;
  localState.total = total;
};

/**
 * Method to handle page change to page number n
 * @param {Number} n
 */
const onPageChange = n => {
  page.value = n;
  search();
};

/**
 * Method to reset paging
 */
const resetPage = () => {
  page.value = undefined;
};

const {
  query,
  sortBy,
  order,
  page,
  perPage,
  search,
} = useContentSearch({
  onResults: onSearchResults,
});

const {
  filterRef: ownerFilterRef,
  syncFrom: syncOwnerFrom,
  syncFilterToRef: syncOwnerTo,
} = useContentFilter('owner', []);

const {
  filterRef: typeFilterRef,
  syncFrom: syncTypeFrom,
  syncFilterToRef: syncTypeTo,
} = useContentFilter('type', []);

/**
 * Wrapper method for resetting pagination and then searching.
 * @return {Promise} Search promise
 */
const resetPageAndSearch = () => {
  resetPage();
  return search();
};

/**
 * Search method triggered by query input submit.
 * Updates filter values from the latest search query value.
 */
const searchViaQueryInput = async() => {
  try {
    await resetPageAndSearch();
    syncOwnerFrom(query.value);
    syncTypeFrom(query.value);
  } catch (err) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
};

const clearSearch = async(resetSort = false) => {
  resetPage();
  query.value = '';
  syncOwnerFrom(query.value);
  syncTypeFrom(query.value);
  if (resetSort) {
    // Watcher will do the search for us
    localState.sortOrderValue = 'created_time-desc';
  } else {
    search();
  }
};

/**
 * At content type dropdown changes, sync the query input value.
 * Triggers search too with the updated values.
 */
const syncFromTypeDropdown = () => {
  resetPage();
  syncTypeTo(query);
  resetPageAndSearch();
};

/**
 * At owner dropdown changes, sync the query input value.
 * Triggers search too with the updated values.
 */
const syncFromOwnerDropdown = () => {
  syncOwnerTo(query);
  resetPageAndSearch();
};

/**
 * Update sort and order data models used on search with sort dropdown changes.
 */
watch(() => localState.sortOrderValue, () => {
  const [newSort, newOrder] = localState.sortOrderValue.split('-');
  sortBy.value = newSort;
  order.value = newOrder;
  resetPageAndSearch();
});

onBeforeMount(() => {
  if (sortBy.value) {
    localState.sortOrderValue = `${sortBy.value}-${order.value}`;
  }
  lookupUsers();
});
</script>

<template>
  <div
    data-automation="new-content-list"
    class="bandContent mainPage requiresAuth new-content-list"
  >
    <h1
      ref="pageTitle"
      class="pageTitle"
      data-automation="content-list-title"
      tabindex="-1"
    >
      Content
    </h1>
    <div class="new-content-list__search">
      <form
        class="content-search"
        @submit.prevent.stop="searchViaQueryInput"
      >
        <RSInputSearch
          v-model="query"
          icon="search"
          :name="$t('content.list.labels.searchForContent')"
          :label="$t('content.list.labels.searchForContent')"
          :show-label="false"
          :placeholder="$t('content.list.labels.searchForContent')"
          show-clear
          @clear="clearSearch"
        />
      </form>

      <!-- Publishing & Permissions controls, wizards and modals -->
      <ContentListOps
        @reset-content="clearSearch(true)"
      />
    </div>
    <div class="search-filters">
      <Dropdown
        v-model="ownerFilterRef"
        name="filter-users"
        label="Owner"
        search-label="Filter users"
        :options="localState.usersOptions"
        :search="lookupUsers"
        @update:modelValue="syncFromOwnerDropdown"
      />
      <Dropdown
        v-model="typeFilterRef"
        name="filter-users"
        label="Content Type"
        search-label="Filter types"
        :options="localState.contentTypeOptions"
        :search="lookupContentTypes"
        @update:modelValue="syncFromTypeDropdown"
      />
      <Dropdown
        v-model="localState.sortOrderValue"
        name="sort-content"
        label="Sort By"
        :options="sortOptions()"
      />
    </div>
    <ContentTable
      v-if="isTableView"
      :applications="localState.searchResults"
    />
    <ContentBlog
      v-if="isBlogView"
      :applications="localState.searchResults"
    />
    <Paginator
      :page="page || 1"
      :per-page="perPage"
      :total="localState.total"
      @change="onPageChange"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.new-content-list {
  min-height: 80vh;
  max-width: 1200px;

  &__search {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
}

.content-search {
  flex: 1;
  position: relative;
}

.search-filters {
  display: flex;
  align-items: center;
  justify-content: right;
  background: $color-light-grey;
  border: 1px solid $color-light-grey-2;
  margin-bottom: 1rem;
  padding: 1.2rem 0.9rem;
}
</style>
