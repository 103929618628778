<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="labels.subject"
    :closeable="!importInProgress"
    @close="onClose"
  >
    <template #content>
      <div
        class="details rsc-text"
        :data-automation="`import-remote-${type}-msg`"
      >
        <p>
          {{ labels.details }}
        </p>
      </div>
      <PrincipalSearchSelect
        ref="searchInput"
        :label="labels.searchLabel"
        :server-settings="serverSettings"
        :has-clear="true"
        :remote-lookup="true"
        :data-automation="`search-remote-${type}`"
        :name="`search-remote-${type}`"
        :type="type"
        @input="handleChange"
        @select="handleSelection"
      />
    </template>
    <template #controls>
      <div
        v-if="showSyncingMemberships"
        class="syncing-members-msg"
      >
        <div class="syncing-members-msg__spinner">
          <Spinner />
        </div>
        <span>
          {{ $t('groups.status.importingMemberships') }}
        </span>
      </div>
      <RSButton
        v-if="!showSyncingMemberships"
        :id="`import-${type}-submit`"
        :label="labels.buttonLabel"
        :disabled="disableButton"
        @click="importEntity"
      />
    </template>
  </RSModal>
</template>

<script>
import Spinner from '@/components/Spinner';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';
import { addNewRemoteUser } from '@/api/users';
import { addNewRemoteGroup } from '@/api/groups';
import {
  SHOW_INFO_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';
import { mapMutations, mapActions } from 'vuex';

export default {
  name: 'ImportRemoteEntityDialog',
  components: {
    Spinner,
    RSButton,
    RSModal,
    PrincipalSearchSelect,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'import'],
  data() {
    return {
      selection: null,
      importInProgress: false,
      labels: {},
      showSyncingMemberships: false,
      syncingMembershipsTimeout: null,
    };
  },
  computed: {
    disableButton() {
      return !this.selection || this.importInProgress;
    },
  },
  created() {
    const availableTypes = ['group', 'user'];
    if (!availableTypes.includes(this.type)) {
      throw new Error(
        `:type '${this.type}' is invalid. Must be one of: ${availableTypes.join(
          ', '
        )}`
      );
    }

    // Define upfront, the local labels and methods to be used
    // related to entity type (user or group)
    switch (this.type) {
      case 'user':
        this.importAPIMethod = addNewRemoteUser;
        this.labels = {
          subject: this.$t('users.title.addUser'),
          details: this.$t('users.label.searchForUserToImport'),
          searchLabel: this.$t('users.label.searchForUsers'),
          buttonLabel: this.$t('users.action.addUser'),
          successMessageFor: userName => this.$t('users.status.added', { name: userName }),
        };
        break;
      case 'group':
        this.importAPIMethod = addNewRemoteGroup;
        this.labels = {
          subject: this.$t('groups.titles.importGroup'),
          details: this.$t('groups.search.desc'),
          searchLabel: this.$t('groups.search.label'),
          buttonLabel: this.$t('groups.actions.import'),
          successMessageFor: groupName => this.$t('groups.status.added', { name: groupName }),
        };
        break;
    }
  },
  mounted() {
    // Focus on search input on load
    if (this.$el.querySelector) {
      this.$el.querySelector('.rs-input').focus();
    }
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    handleChange() {
      // clear existing selection if search text changes
      if (this.selection) {
        this.selection = null;
      }
    },
    handleSelection(selection) {
      this.selection = selection;
    },
    initSyncingMembershipsMsg() {
      if (this.type === 'group') {
        this.syncingMembershipsTimeout = setTimeout(() => {
          this.showSyncingMemberships = true;
        }, 300);
      }
    },
    importEntity() {
      this.clearStatusMessage();
      this.importInProgress = true;

      // If selection has GUID, it already exists in Connect
      if (this.selection.guid) {
        this.$emit('import', this.selection);
        return;
      }
      this.initSyncingMembershipsMsg();
      return this.importAPIMethod(this.selection.tempTicket)
        .then(this.importSuccess)
        .catch(this.importError)
        .finally(() => {
          this.importInProgress = false;
          clearTimeout(this.syncingMembershipsTimeout);
        });
    },
    importSuccess(newEntity) {
      this.$emit('import', newEntity);
      this.setInfoMessage({ message: this.labels.successMessageFor(newEntity.displayName) });
    },
    importError(err) {
      this.$emit('close');
      this.setErrorMessageFromAPI(err);
    },
    onClose() {
      if (!this.importInProgress) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped>
.rsc-text p {
  margin-bottom: 1rem;
}

.syncing-members-msg {
  display: flex;
  line-height: 2rem;
}

.syncing-members-msg__spinner {
  height: 2rem;
  margin-right: 0.6rem;
  width: 2rem;
}
</style>
