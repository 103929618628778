<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div
        v-if="showNavigationLinks"
        class="menu"
      >
        <div
          class="menuItems"
          role="tablist"
        >
          <router-link
            ref="usersTab"
            :to="{ name: 'people.users' }"
            class="menuItem users active"
            data-automation="menu-item-users"
            role="tab"
          >
            {{ $t('navigation.menus.users') }}
          </router-link>
          <router-link
            :to="{ name: 'people.groups' }"
            class="menuItem groups"
            data-automation="menu-item-groups"
            role="tab"
          >
            {{ $t('navigation.menus.groups') }}
          </router-link>
        </div>
      </div>
      <div
        :class="optionsPanelVisible ? '' : 'hideOptionsPanel'"
        class="contentWithOptionsPanel"
      >
        <div
          id="usersPanel"
          class="majorColumn"
        >
          <div class="sectionTitle flex">
            <div data-automation="uv-title">
              {{ pageTitle }}
            </div>
            <div
              v-if="initialDataLoaded"
              class="actionBar inline showTitles"
            >
              <button
                v-if="enableAddUsersBtn"
                ref="addUserButton"
                data-automation="add-user-button"
                :aria-label="$t('users.title.addUser')"
                type="button"
                class="action new"
                @click="toggleAddUserDialog"
              >
                <span class="hideOnMobile">{{ $t('users.title.addUser') }}</span>
              </button>
              <button
                v-if="showOptionsPanelToggleButton"
                ref="optionsButton"
                :aria-label="$t('users.title.options')"
                type="button"
                class="action toggleOptions"
                @click="toggleOptionsPanel"
              >
                <span class="hideOnMobile">{{ $t('users.title.options') }}</span>
              </button>
            </div>
          </div>

          <EmbeddedStatusMessage
            v-if="isLoading"
            :show-close="false"
            :message="$t('users.title.gettingUsers')"
            type="activity"
          />

          <RSTable
            v-show="!isLoading"
            :columns="tableHeaders"
            data-automation="users__list"
          >
            <RSTableRow
              v-for="(user, index) in api.users"
              :key="index"
              :class="highlightClass(index)"
              :row-id="user.guid"
              :row-label="formatNavigateUser(user)"
            >
              <RSTableCell
                :cell-id="`user-${user.guid}-link`"
                :has-icon="true"
                :fill="true"
                :link="userProfileLink(user)"
                :clickable="true"
                data-automation="user-link"
              >
                <RSPrincipalInfo
                  :initials="user.displayInitials"
                  :name="user.displayName"
                  :status="user.displayStatuses"
                />
              </RSTableCell>
              <RSTableCell>{{ userRoleName(user) }}</RSTableCell>
              <RSTableCell>
                {{ userActiveTime(user) }}
              </RSTableCell>
            </RSTableRow>
          </RSTable>

          <RSPager
            v-show="showPager"
            :disable-left-actions="disablePreviousPagination"
            :disable-right-actions="disableNextPagination"
            :labels="pagerLabels"
            @first-page="gotoPage('first')"
            @previous-page="gotoPage('previous')"
            @next-page="gotoPage('next')"
            @last-page="gotoPage('last')"
          />
        </div>

        <div
          v-if="initialDataLoaded && showOptionsPanel"
          class="minorColumn"
        >
          <UsersOptionPanel
            v-if="optionsPanelVisible"
            ref="optionsPanel"
            :is-admin="currentUser.isAdmin()"
            :can-search-by-id="canSearchById"
            @close="toggleOptionsPanel"
            @change="updateSearchOptions"
            @vue:mounted="onMounted"
          />
        </div>

        <UserCreateDialog
          v-if="addUserDialogVisible && canAddNewUser"
          :server-settings="serverSettings"
          :current-user-role="currentUser.userRole"
          @close="toggleAddUserDialog"
          @user-created="userCreated"
          @confirmation-link="toggleConfirmationLinkDialog"
        />
        <CopyTextDialog
          v-if="confirmationLinkDialogVisible && confirmationLink"
          :title="$t('users.confirmation.link.title')"
          :info-message="$t('users.confirmation.link.success')"
          :description="$t('users.confirmation.link.description')"
          :copied-message="$t('users.confirmation.link.copied')"
          :text-label="$t('users.confirmation.link.label')"
          :text="confirmationLink"
          @close="toggleConfirmationLinkDialog"
        />
        <ImportRemoteEntityDialog
          v-if="addUserDialogVisible && canAddRemoteUser"
          type="user"
          :server-settings="serverSettings"
          @import="userImported"
          @close="toggleAddUserDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RSPager from '@/elements/RSPager';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo';
import RSTable from '@/elements/RSTable';
import RSTableRow from '@/elements/RSTableRow';
import RSTableCell from '@/elements/RSTableCell';

import UserRoles from '@/api/dto/userRole';
import { searchUsers } from '@/api/users';
import { mapState, mapMutations } from 'vuex';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import CopyTextDialog from '@/components/CopyTextDialog';
import ImportRemoteEntityDialog from '@/components/ImportRemoteEntityDialog';
import { activeTime } from '@/utils/activeTime.filter';
import { userPath } from '@/utils/paths';

import UserCreateDialog from './UserCreateDialog';
import UsersOptionPanel from './UsersOptionPanel';

export default {
  name: 'UsersView',
  components: {
    CopyTextDialog,
    EmbeddedStatusMessage,
    ImportRemoteEntityDialog,
    RSPager,
    RSPrincipalInfo,
    RSTable,
    RSTableRow,
    RSTableCell,
    UserCreateDialog,
    UsersOptionPanel,
  },
  data() {
    return {
      initialDataLoaded: false,
      loading: false,
      optionsPanelVisible: true,
      addUserDialogVisible: false,
      confirmationLinkDialogVisible: false,
      confirmationLink: null,
      searchParameters: {
        prefix: '',
        accountStatus: {},
        userRole: UserRoles.Anonymous,
        currentPage: 1,
      },
      tableHeaders: [
        { name: 'user', label: this.$t('users.title.user'), width: '100%' },
        { name: 'role', label: this.$t('users.title.role') },
        { name: 'lastActive', label: this.$t('users.title.lastActive') },
      ],
      api: {
        users: [],
        totalPages: 1,
      },
      highlightFlag: false,
    };
  },
  computed: {
    highlightClass() {
      // Use a class that handles the highlight of new rows
      const highlight = this.highlightFlag;
      return rowIndex => (highlight && rowIndex === 0 ? 'highlight-once' : '');
    },
    showNavigationLinks() {
      return (
        this.initialDataLoaded &&
        (!this.serverSettings.authentication.externalGroupId ||
          this.serverSettings.authentication.externalGroupSearch) &&
        this.serverSettings.authentication.groupsEnabled
      );
    },
    isLoading() {
      return !this.initialDataLoaded || this.loading;
    },
    pageTitle() {
      return this.searchParameters.prefix
        ? this.$t('users.title.matchingUsers')
        : this.$t('users.title.users');
    },
    enableAddUsersBtn() {
      return this.canAddNewUser || this.canAddRemoteUser;
    },
    canAddNewUser() {
      return (
        this.initialDataLoaded && this.currentUser.canAddNewUser(this.serverSettings)
      );
    },
    canAddRemoteUser() {
      return (
        this.initialDataLoaded && this.currentUser.canAddRemoteUser(this.serverSettings)
      );
    },
    canSearchById() {
      return Boolean(this.serverSettings.authentication.externalUserId);
    },
    showOptionsPanel() {
      if (!this.currentUser || !this.serverSettings) { return false; }

      const visibleForCurrentUser =
        this.currentUser.userRole >= UserRoles.Publisher ||
        !this.serverSettings.viewersCanOnlySeeThemselves;
      return visibleForCurrentUser;
    },
    showOptionsPanelToggleButton() {
      return !this.optionsPanelVisible && this.showOptionsPanel;
    },
    showPager() {
      return !this.loading && this.api.totalPages > 1;
    },
    pagerLabels() {
      return {
        first: this.$t('navigation.pagination.fixed.first'),
        previous: this.$t('navigation.pagination.fixed.previous'),
        next: this.$t('navigation.pagination.fixed.next'),
        last: this.$t('navigation.pagination.fixed.last'),
      };
    },
    disablePreviousPagination() {
      return this.searchParameters.currentPage === 1;
    },
    disableNextPagination() {
      return this.searchParameters.currentPage === this.api.totalPages;
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    async init() {
      try {
        await this.search();
        this.initialDataLoaded = true;
        this.optionsPanelVisible =
          this.currentUser.userRole >= UserRoles.Publisher ||
          !this.serverSettings.viewersCanOnlySeeThemselves;
      } catch (err) {
        this.setErrorMessageFromAPI(err);
      }
    },
    onMounted() {
      const focusedElement = this.$refs.usersTab;
      if (focusedElement) {
        '$el' in focusedElement ? focusedElement.$el.focus() : focusedElement.focus();
      }
    },
    search() {
      const {
        searchParameters: { prefix, accountStatus, userRole, currentPage },
      } = this;
      const timeoutId = setTimeout(() => (this.loading = true), 300);

      return searchUsers(this.serverSettings, {
        prefix,
        accountStatus,
        userRole,
        includeRemote: false,
        pageNumber: currentPage,
      })
        .then(({ results, currentPage: newCurrentPage, totalPages }) => {
          this.searchParameters.currentPage = newCurrentPage;
          this.api.users = results;
          this.api.totalPages = totalPages;
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          clearTimeout(timeoutId);
          this.loading = false;
        });
    },
    formatNavigateUser(user) {
      return this.$t('users.action.navigateUser', {
        user: user.displayName,
      });
    },
    userProfileLink(user) {
      return {
        title: user.displayName,
        href: userPath(user.guid),
      };
    },
    userRoleName(user) {
      const userRole = UserRoles.stringOf(user.userRole);
      return this.$t(`users.label.role.${userRole}`);
    },
    toggleOptionsPanel() {
      this.optionsPanelVisible = !this.optionsPanelVisible;
      this.$nextTick(() => {
        const focused = this.optionsPanelVisible ?
          this.$refs.optionsPanel :
          this.$refs.optionsButton;
        focused.focus();
      });
    },
    toggleAddUserDialog() {
      this.addUserDialogVisible = !this.addUserDialogVisible;
      if (!this.addUserDialogVisible) {
        this.$nextTick(() => this.$refs.addUserButton.focus());
      }
    },
    toggleConfirmationLinkDialog(link) {
      if (link) {
        this.confirmationLink = link;
      }
      this.confirmationLinkDialogVisible = !this.confirmationLinkDialogVisible;
    },
    updateSearchOptions({ prefix, accountStatus, userRole }) {
      this.searchParameters.currentPage = 1;
      this.searchParameters.prefix = prefix;
      this.searchParameters.accountStatus = accountStatus;
      this.searchParameters.userRole = userRole;
      return this.search();
    },
    removeUserFromTable(targetGUID) {
      const index = this.api.users.findIndex(user => {
        return user.guid === targetGUID;
      });
      if (index !== -1) {
        this.api.users.splice(index, 1);
      }
    },
    userImported(user) {
      // Evaluate if remote user already exists
      // and try to remove it from current page if needed
      // (to be placed on top for user to see it)
      if (user.guid) {
        this.removeUserFromTable(user.guid);
      }
      this.userCreated(user);
    },
    userCreated(user) {
      this.api.users.unshift(user);
      this.toggleAddUserDialog();
      this.highlightNewUser();
    },
    userActiveTime(user) {
      return activeTime(user.activeTime);
    },
    highlightNewUser() {
      this.highlightFlag = true;
      setTimeout(() => {
        this.highlightFlag = false;
      }, 1000);
    },
    gotoPage(direction) {
      switch (direction) {
        case 'first':
          this.searchParameters.currentPage = 1;
          break;
        case 'previous':
          this.searchParameters.currentPage -= 1;
          break;
        case 'next':
          this.searchParameters.currentPage += 1;
          break;
        case 'last':
          this.searchParameters.currentPage = this.api.totalPages;
          break;
      }
      return this.search();
    },
  },
};
</script>

<style lang="scss">
// Overwrite link text-decoration to this specific table links
.rs-tablecell {
  a:hover {
    text-decoration: none;
  }
}
</style>
