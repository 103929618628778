<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="visible"
    :class="{ showing: visible }"
    :data-automation="dataAutomationTag"
    class="save-discard"
  >
    <BaseButton
      v-bind="{
        label: $t('common.buttons.save'),
        buttonClass: 'default',
        disabled: !enabled
      }"
      data-automation="settings-save"
      @clicked.stop.prevent="$emit('save')"
    />
    <BaseButton
      :label="$t('common.buttons.discardChanges')"
      data-automation="settings-discard"
      @clicked.stop.prevent="$emit('discard')"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  name: 'ConfirmationPanel',
  components: { BaseButton },
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['discard', 'save'],
  computed: {
    dataAutomationTag() {
      if (this.visible) {
        return 'settings-confirmation-panel-active';
      }
      return 'settings-confirmation-panel-inactive';
    },
  },
};
</script>

<style scoped>
.save-discard {
  position: fixed;
  top: -12px;
  width: 350px;
  padding: 1.2rem;
  /*
  * z-index required since there are some fields
  * that bleed through when scrolling content settings.
  */
  z-index: 10;
  background-color: #c8c8c8;

  margin: -20px -10px; /* undo-ing margin from `.contentPanel .settingsPane` */

  display: flex;
  justify-content: space-between;

  transition: top 250ms;
}

.save-discard.showing {
  top: 68px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.save-discard button {
  min-width: 140px;
}
</style>
