<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { ref, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import {
  ViewType,
  CONTENT_LIST_UPDATE_VIEW_TYPE,
} from '@/store/modules/contentList';
import { JUMP_START_INIT } from '@/store/modules/jumpstart';
import UserRoles from '@/api/dto/userRole';
import DeploymentWizard from '@/views/content/DeploymentWizard';
import PublishWizard from '@/views/content/PublishWizard';
import JumpStart from '@/views/content/JumpStart';
import RequestPermissionsModal from '@/views/content/RequestPermissionsModal';
import PublishDropdown from '@/views/content/PublishDropdown.vue';

const emit = defineEmits(['reset-content']);

const store = useStore();
const showPermissionsModal = ref(false);
const serverSettings = computed(() => store.state.server.settings);
const isJumpstartActive = computed(() => store.state.jumpstart.active);
const isPublishingActive = computed(() => store.state.publish.active);
const canPublish = computed(() => store.state.currentUser.user.userRole >= UserRoles.Publisher);
const canPublishGit = computed(() => {
  return canPublish.value && serverSettings.value.gitEnabled;
});
const canRequestPublisherAccess = computed(() => {
  return (
    store.state.currentUser.user.userRole === UserRoles.Viewer &&
    !serverSettings.value.viewerKiosk
  );
});

const togglePermissionsModal = () => {
  showPermissionsModal.value = !showPermissionsModal.value;
};

const updateViewType = viewType => {
  store.commit(CONTENT_LIST_UPDATE_VIEW_TYPE, viewType);
};

onBeforeMount(() => {
  store.dispatch(JUMP_START_INIT);
});
</script>

<template>
  <div class="content-list-ops actionBar">
    <button
      :title="$t('content.list.labels.expandedView')"
      :class="{ current: isBlogView }"
      :aria-pressed="isBlogView ? 'true' : 'false'"
      class="action blogView"
      data-automation="switch-to-blogview"
      @click="updateViewType(ViewType.BLOG)"
    />
    <button
      :title="$t('content.list.labels.compactView')"
      :class="{ current: isTableView }"
      :aria-pressed="isTableView ? 'true' : 'false'"
      class="action radio tableView"
      data-automation="switch-to-tableview"
      @click="updateViewType(ViewType.TABLE)"
    />
    <PublishDropdown
      v-if="canPublish"
      ref="publishDropdown"
      :git-enabled="serverSettings.gitEnabled"
      :git-available="serverSettings.gitAvailable"
      :jump-start-enabled="serverSettings.jumpStartEnabled"
    />
    <a
      v-if="canRequestPublisherAccess"
      href="#"
      class="rs-link"
      data-automation="request-publisher-access"
      @click.prevent="togglePermissionsModal"
      @keydown.space.prevent="togglePermissionsModal"
      @keydown.enter.prevent="togglePermissionsModal"
    >
      {{ $t('content.newButton.label') }}
    </a>

    <!-- Wizards and Modals -->
    <DeploymentWizard
      v-if="canPublishGit"
      :git-available="serverSettings.gitAvailable"
      @refresh-content-list="emit('reset-content')"
    />
    <PublishWizard v-if="canPublish && isPublishingActive && !isJumpstartActive" />
    <JumpStart v-if="canPublish && serverSettings.jumpStartEnabled && isJumpstartActive" />
    <RequestPermissionsModal
      v-if="canRequestPublisherAccess"
      :privilege="'publisher'"
      :show-modal="showPermissionsModal"
      @close="togglePermissionsModal"
    />
  </div>
</template>

<style scoped lang="scss">
.content-list-ops {
  padding: 0;
  text-align: left;

  & .tableView,.blogView {
    margin-left: 0.5rem;
  }
}
</style>
