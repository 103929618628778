<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="$t('appSettings.access.labels.becomeViewerWarning')"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p
          v-if="isAdmin"
        >
          {{ $t('appSettings.access.messages.becomeViewerWarning.admin') }}
        </p>
        <p
          v-else
        >
          {{ $t('appSettings.access.messages.becomeViewerWarning.nonAdmin') }}
        </p>
      </div>
    </template>

    <template #controls>
      <RSButton
        :label="$t('common.buttons.ok')"
        data-automation="become-viewer-warning-dialog-submit"
        @click="$emit('confirm')"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

export default {
  name: 'BecomeViewerWarning',
  components: { RSButton, RSModal },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'confirm']
};
</script>
