<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__quarto-intro wizard-panel--blue">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p>{{ $t('publish.quartoIntro.body') }}</p>
      <p>{{ $t('publish.quartoIntro.howToPublish') }}</p>
      <div
        v-if="!quartoAvailable"
        class="wizard-panel__warning"
      >
        <p>
          <strong>{{ $t('publish.quartoIntro.support.note') }}</strong>
          {{ $t('publish.quartoIntro.support.text') }}
        </p>
        <p>
          <a
            :href="quartoConfigDocumentation"
            target="_blank"
          >
            {{ $t('publish.quartoIntro.support.learnMore') }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';
import { mapState } from 'vuex';

export default {
  name: 'QuartoIntro',
  computed: {
    quartoConfigDocumentation() {
      return docsPath('admin/appendix/configuration/#Quarto');
    },
    ...mapState({
      quartoAvailable: state => state.server.settings.runtimes.includes('Quarto'),
    })
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__quarto-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &__warning {
    border: 1px solid #db7575;
    background-color: #f8e3e3;
    margin: 1.5rem 0 0 0;
    padding: 1rem;
    color: #202020;
    * {
      background-color: transparent;
    }
    a {
      color: $color-primary-dark;
    }
  }
}
</style>
