<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Use RestrictedAccessWrapper to wrap a component which utilizes APIs that can
  fail due to the WebSudo feature. See `WebSudoInvoker` in the backend for
  details about the feature.

  The wrapped component *must* conform to the following pattern:

  1. When the wrapped component wants to execute a restricted API, it *must*
     pass the bare/unchained restricted API promise to the 'executeRestrictedApi'
     function contained in the Vue slot parameter. Chaining .then and .catch *must*
     be done on 'executeRestrictedApi' rather than on the target API so the
     wrapper can handle the authentication logic when necessary.
  2. 'executeRestrictedApi' takes care of displaying the Confirm Password dialog
     if necessary.  This function chains on to the original promise so it can be
     utilized as it normally would.  However, an additional scenario must be
     taken into account.  An error in the promise will be returned in the case
     when the reauthentication is in progress.  The error is of type 'ReauthenticationInProgressError'.
     Ensure this error is handled appropriately in the wrapped component.

  Visual representation of how this wrapper is intended to be used:
   +-----------------------------------+
   |      RestrictedAccessWrapper      |
   |       +                           |
   |       |executeRestrictedApi       |
   |  +----|------------------------+  |
   |  |    |    My Component        |  |
   |  |    v                        |  |
   |  | Some API Action             |  |
   |  +-----------------------------+  |
   +-----------------------------------+

  Refer to the tests for a simple example.
-->
<template>
  <div v-if="loaded">
    <ConfirmPasswordDialog
      v-if="showConfirmPasswordDialog"
      :authentication-name="authenticationName"
      :challenge-required="challengeRequired"
      :username="currentUser.username"
      @done="authenticated"
    />
    <div v-else>
      <slot :execute-restricted-api="execute" />
    </div>
  </div>
</template>

<script>
import ApiErrors from '@/api/errorCodes';
import { getRestrictedAccessMode } from '@/api/authentication';
import { safeAPIErrorMessage } from '@/api/error';
import ConfirmPasswordDialog from '@/views/authentication/ConfirmPasswordDialog';
import { SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { mapState, mapActions } from 'vuex';

export class ReauthenticationInProgressError extends Error {}

export default {
  name: 'RestrictedAccessWrapper',
  components: { ConfirmPasswordDialog },
  props: {
    eager: {
      // Controls whether authentication check should be done when the component is first loaded.
      // Keep in mind the UX we want - an event should trigger possible re-authentication.
      // e.g. deleting an API key, attempting to create a user, changing user profile details
      type: Boolean,
      required: true,
    },
  },
  emits: ['authenticated', 'loaded'],
  data() {
    return {
      loaded: false,
      showConfirmPasswordDialog: false,
      challengeRequired: null,
      authenticationName: null,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  async created() {
    try {
      const restrictedAccessMode = this.eager ? await getRestrictedAccessMode() : false;
      this.authenticationName = this.serverSettings.authentication.name;
      if (this.serverSettings.authentication.name === 'Posit Connect') {
        this.authenticationName = this.serverSettings.systemDisplayName;
      }
      this.challengeRequired =
        this.serverSettings.authentication.challengeResponseEnabled;
      this.showConfirmPasswordDialog = restrictedAccessMode;
      this.loaded = true;
      this.$emit('loaded');
    } catch (err) {
      // nothing we can do at this point
      this.showConfirmPasswordDialog = false;

      // errors at this stage are unrecoverable.
      this.setErrorMessage({
        message: this.$t('authentication.websudo.unrecoverable', {
          error: safeAPIErrorMessage(err),
        }),
      });
    }
  },
  methods: {
    ...mapActions({
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    authenticated() {
      this.showConfirmPasswordDialog = false;
      this.$emit('authenticated');
    },
    execute(apiPromise) {
      return apiPromise.catch(err => {
        // unsure what kind of error we are getting so be safe
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.code === ApiErrors.InRestrictedAccessMode
        ) {
          this.showConfirmPasswordDialog = true;
          throw new ReauthenticationInProgressError();
        } else {
          throw err;
        }
      });
    },
  },
};
</script>
