<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <RestrictedAccessWrapper
      v-slot="{ executeRestrictedApi }"
      :eager="false"
    >
      <RSModalForm
        data-automation="confirm-delete-api-key"
        :active="true"
        :subject="$t('apiKeys.delete.title', { name: apiKey.name })"
        @close="$emit('close')"
        @submit="() => deleteKey(executeRestrictedApi)"
      >
        <template #content>
          <EmbeddedStatusMessage
            v-if="errMessage"
            type="error"
            data-automation="delete-key-error"
            :message="errMessage"
            @close="clearErr"
          />
          <p>
            {{ $t('apiKeys.delete.text', { name: apiKey.name }) }}
          </p>
        </template>
        <template #controls>
          <RSButton
            data-automation="api-key-delete"
            :label="$t('apiKeys.delete.btnLabel')"
          />
        </template>
      </RSModalForm>
    </RestrictedAccessWrapper>
  </div>
</template>

<script>
import { deleteUserAPIKey } from '@/api/users';
import { safeAPIErrorMessage } from '@/api/error';

import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import RSButton from '@/elements/RSButton';
import RSModalForm from '@/elements/RSModalForm';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';

export default {
  name: 'DeleteKey',
  components: {
    RestrictedAccessWrapper,
    RSButton,
    RSModalForm,
    EmbeddedStatusMessage,
  },
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'deleted'],
  data() {
    return {
      errMessage: '',
    };
  },
  methods: {
    deleteKey(executeRestrictedApi) {
      executeRestrictedApi(deleteUserAPIKey(this.apiKey.id))
        .then(() => {
          return this.$emit('deleted');
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.errMessage = safeAPIErrorMessage(err);
          }
        });
    },
    clearErr() {
      this.errMessage = '';
    },
  }
};
</script>

<style lang="scss" scoped>
.statusMessage {
  margin-bottom: 0.9rem;
}
</style>
