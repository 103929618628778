<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="accordion"
    :class="accordionClass"
    data-automation="accordion"
  >
    <div
      class="header"
      data-automation="accordion-button"
      tabindex="0"
      role="button"
      @keyup.enter="toggle"
      @keyup.space="toggle"

      @mouseover="hover = true"
      @focusin="hover = true"

      @mouseleave="hover = false"
      @focusout="hover = false"
    >
      <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
      <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
      <img
        v-show="isOpen"
        src="/images/arrowhead-down.png"
        alt="collapse"
        title="Collapse Details"
        class="header__icon"
        data-automation="accordion-button-expanded"
        @click="toggle"
      >
      <img
        v-show="!isOpen"
        src="/images/arrowhead-right.png"
        alt="expand"
        title="Expand Details"
        class="header__icon"
        data-automation="accordion-button-collapsed"
        @click="toggle"
      >
      <!-- eslint-enable vuejs-accessibility/click-events-have-key-events -->
      <!-- eslint-enable vuejs-accessibility/no-static-element-interactions-->
      <slot name="header" />
    </div>
    <Transition
      name="accordion"
      @enter="enter"
      @leave="leave"
    >
      <div
        v-show="isOpen"
        ref="body"
        class="body"
      >
        <div class="body__inner">
          <slot />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    classWhenHovered: {
      type: String,
      default: '',
    },
    classWhenOpened: {
      type: String,
      default: '',
    },
    classWhenClosed: {
      type: String,
      default: '',
    },
    classWhenSelected: {
      type: String,
      default: '',
    },
    classWhenNotSelected: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    accordionClass() {
      const classes = [];

      if (this.isOpen) {
        classes.push(this.classWhenOpened);
      } else if (this.hover) {
        classes.push(this.classWhenHovered);
      } else {
        classes.push(this.classWhenClosed);
      }

      if (this.selected) {
        classes.push(this.classWhenSelected);
      } else {
        classes.push(this.classWhenNotSelected);
      }

      return classes;
    }
  },
  methods: {
    toggle: function() {
      this.$emit('toggle');
    },
    enter: function(el) {
      el.style.height = `${el.scrollHeight + 10}px`;
    },
    leave: function(el) {
      el.style.height = '0';
    },
  }
};
</script>
<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.accordion {
  font-family: Lato;
}

.accordion-enter-active, .accordion-leave-active {
  transition: height 0.25s ease;
}

.accordion-enter-from, .accordion-leave-to {
  height: 0px;
}
.accordion-enter-from .body__inner, .accordion-leave-to .body__inner {
  opacity: 0;
}

.accordion-enter-to, .accordion-leave-from {
  height: auto;
}
.accordion-enter-to .body__inner, .accordion-leave-from .body__inner {
  opacity: 1;
}

.header {
  line-height: 2rem;
  position: relative;
  cursor: pointer;
  @include control-visible-focus;

  &__icon {
    position: absolute;
    top: 10px;
    transform: rotate(0deg);
    transition-duration: 0.3s;
    width: 14px;
    cursor: pointer;
    margin-left: 3px;

    &.rotate {
      transform: rotate(180deg);
      transition-duration: 0.3s;
    }
  }
}

.body {
  overflow: show;
  background-color: $color-white;
  border-top: 0;

  &__inner {
  overflow-wrap: break-word;
  }
}
</style>
