<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="tag-filter-record">
    <span
      v-if="tag.path"
      class="tag-filter-record__path"
    >
      {{ tag.path }}
    </span>
    <span class="tag-filter-record__name">
      {{ tag.name }}
    </span>
    <button
      :aria-label="$t('content.filters.tags.deselect', { tag: tag.name })"
      class="tag-filter-record__remove"
      @click="$emit('deselect')"
    />
  </div>
</template>

<script>
export default {
  name: 'TagFilterRecord',
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  emits: ['deselect']
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

@mixin tag-overflow {
  line-height: 1.4rem;
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag-filter-record {
  align-items: center;
  background: $color-light-grey-3;
  display: flex;
  height: 2rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;

  &__path {
    @include tag-overflow;
    color: $color-dark-grey;
    font-size: 0.8rem;
    margin-right: 0.3rem;
  }

  &__name {
    @include tag-overflow;
  }

  &__remove {
    background: transparent;
    background-image: url(/images/elements/close.svg);
    background-size: 20px 20px;
    background-position: center;
    height: 1.4rem;
    margin: 0.3rem;
    padding: 0;
    width: 1.4rem;
  }

  & + .tag-filter-record {
    margin-left: 0.4rem;
  }
}
</style>
