<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div class="sub-section-label">
      {{ $t('appSettings.access.executionEnvironment.labels.lastTime') }}
    </div>
    <div
      class="detail"
      data-automation="image-last-time"
    >
      {{ computedLastTime }}
    </div>
    <div v-if="showEnvironmentSelection">
      <RSInputSelect
        v-model="optionValue"
        :label="$t('appSettings.access.executionEnvironment.labels.nextTime')"
        :options="specifyDefaultOptions"
        :message="selectErrorMessage"
        :disabled="disableEnvironmentSelection"
        name="specifyDefaultEnvironment"
        class="mt-small"
        data-automation="specify-default-environment"
        @change="onOptionValueChange"
      />
      <RSButton
        v-if="showSelectEnvironmentAction"
        :label="$t('appSettings.access.executionEnvironment.labels.selectButton')"
        type="secondary"
        class="full-width"
        data-automation="make-default-environment-selection"
        name="selectDefaultEnvironment"
        @click="onEdit"
      />
      <div
        v-if="showDetail"
        class="detail"
      >
        <div
          class="environment-detail"
          data-automation="selected-environment-detail"
        >
          <div class="environment-detail__title-name">
            <div>
              {{ defaultEnvironment.title }}
            </div>
            <div
              v-if="showName"
              class="mt-small"
            >
              {{ defaultEnvironment.name }}
            </div>
          </div>
          <a
            v-if="showEditEnvironmentButton"
            class="small-text-button"
            role="button"
            tabindex="0"
            data-automation="edit-default-environment-selection"
            @click="onEdit"
            @keyup.enter="onEdit"
            @keyup.space="onEdit"
          >
            {{ $t('appSettings.access.executionEnvironment.labels.editButton') }}
          </a>
        </div>
      </div>
      <MessageBox
        v-if="showSelectedEnvironmentWarning"
        alert
        small
        class="mt-normal mb-none"
        data-automation="selected-environment-warning"
      >
        <div
          v-for="msg in selectedEnvironmentWarningMessage"
          :key="msg"
          class="mt-small"
        >
          {{ $rt(msg) }}
        </div>
      </MessageBox>
    </div>
    <DefaultExecutionEnvironmentDialog
      v-if="showSelectionDialog"
      :initial-selected-environment="defaultEnvironment"
      @close="onSelectionDialogClose"
      @submit="onSelectionDialogSubmit"
    />
  </div>
</template>

<script>
import DefaultExecutionEnvironmentDialog from './DefaultExecutionEnvironmentDialog';
import RSInputSelect from '@/elements/RSInputSelect';
import RSButton from '@/elements/RSButton';
import MessageBox from '@/components/MessageBox';
import { getEnvironments } from '@/api/environments';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapMutations } from 'vuex';

export const SpecifyDefaultOptionsValues = {
  system: 'system',
  specify: 'specify',
};

export default {
  name: 'DefaultExecutionEnvironment',
  components: {
    DefaultExecutionEnvironmentDialog,
    RSButton,
    RSInputSelect,
    MessageBox,
  },
  props: {
    imageName: {
      type: String,
      default: null,
    },
    imageLastTime: {
      type: String,
      default: 'unknown',
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: true,
    }
  },
  emits: [
    'valid',
    'changeName',
    'changeOption',
  ],
  data() {
    return {
      showSelectionDialog: false,
      defaultEnvironment: {},
      environments: [],
      disableAutomaticSelection: false,
      specifyDefaultOptions: [
        {
          value: SpecifyDefaultOptionsValues.system,
          label: this.$t('appSettings.access.executionEnvironment.options.system'),
        },
        {
          value: SpecifyDefaultOptionsValues.specify,
          label: this.$t('appSettings.access.executionEnvironment.options.specify'),
        }
      ],
      optionValue: this.calculatedOptionValue(),
      initializePromise: null,
    };
  },
  computed: {
    showName() {
      return this.defaultEnvironment.title !== this.defaultEnvironment.name;
    },
    defaultEnvironmentIsEmpty() {
      return !this.defaultEnvironment.name;
    },
    showDefaultEnvironment() {
      return this.optionValue === SpecifyDefaultOptionsValues.specify;
    },
    showSelectEnvironmentAction() {
      return this.showDefaultEnvironment
        && this.defaultEnvironmentIsEmpty
        && this.environments.length > 0;
    },
    showEditEnvironmentButton() {
      return !this.readOnly
        && this.environments.length > 0
        && !this.disableEnvironmentSelection;
    },
    showSelectedEnvironmentWarning() {
      return this.selectedEnvironmentWarningMessage.length > 0;
    },
    selectedEnvironmentWarningMessage() {
      if (this.showNoEnvironmentsAvailableMessage) {
        return this.$tm('appSettings.access.messages.environmentEmpty');
      }
      if (this.showSelectEnvironmentMissingMessage) {
        return this.$tm('appSettings.access.messages.environmentMissing');
      }
      if (this.showSelectEnvironmentMissingAndEmptyMessage) {
        return this.$tm('appSettings.access.messages.environmentMissingAndEmpty');
      }
      return '';
    },
    showSelectEnvironmentMissingMessage() {
      return this.showDefaultEnvironment
        && !this.defaultEnvironmentIsEmpty
        && this.defaultEnvironment.envNotFound
        && this.environments.length > 0;
    },
    showSelectEnvironmentMissingAndEmptyMessage() {
      return this.showDefaultEnvironment
        && !this.defaultEnvironmentIsEmpty
        && this.defaultEnvironment.envNotFound
        && this.environments.length === 0;
    },
    showDetail() {
      return this.showDefaultEnvironment && !this.defaultEnvironmentIsEmpty;
    },
    selectErrorMessage() {
      if (this.showErrorMessage && !this.valid) {
        // Errors only shown if user has made change
        if (this.showSelectEnvironmentAction) {
          // user is mid-way through specifying an image
          return this.$t('appSettings.access.executionEnvironment.errors.noSelection');
        }
      }
      return null;
    },
    computedLastTime() {
      return this.imageLastTime
        ? this.imageLastTime
        : this.$t('appSettings.access.executionEnvironment.noPreviousImage');
    },
    showNoEnvironmentsAvailableMessage() {
      return !this.readOnly
        && this.environments.length === 0
        && this.defaultEnvironmentIsEmpty;
    },
    showEnvironmentSelection() {
      return this.allowSelection;
    },
    disableEnvironmentSelection() {
      // specifically NOT including showSelectEnvironmentMissingMessage
      return this.readOnly
        || this.showSelectEnvironmentMissingAndEmptyMessage
        || this.showNoEnvironmentsAvailableMessage;
    },
  },
  watch: {
    valid: {
      handler(newValue) {
        this.$emit('valid', newValue);
      },
      immediate: true,
    },
    imageName: {
      handler() {
        // lookup the environment associated with this image within the environments defined
        // on the server
        this.defaultEnvironment = this.lookupEnvironmentByImage(this.imageName);
        this.optionValue = this.calculatedOptionValue();
        this.$emit('valid', true);
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      // set our initial selection based on the image name
      this.optionValue = this.calculatedOptionValue();

      // don't make the API calls if we're disabled.
      if (!this.allowSelection) {
        this.initializePromise = Promise.resolve();
        return;
      }
      // we need a list of the environments. For now, we'll request
      // the API ourselves, even though our child component also needs it.
      this.initializePromise = getEnvironments()
        .then(environments => {
          this.environments = environments;
          this.defaultEnvironment = this.lookupEnvironmentByImage(this.imageName);
        })
        .catch(err => {
          this.setErrorMessageFromAPI(err);
        });
    },
    calculatedOptionValue() {
      return this.imageName
        ? SpecifyDefaultOptionsValues.specify
        : SpecifyDefaultOptionsValues.system;
    },
    lookupEnvironmentByImage(imageName) {
      const env = this.environments.find(environment => environment.name === imageName);
      if (env) {
        return { ...env, envNotFound: false };
      }
      return ({
        name: imageName,
        title: '',
        envNotFound: true,
      });
    },
    onOptionValueChange(newValue) {
      if (newValue === SpecifyDefaultOptionsValues.system) {
        this.defaultEnvironment = {};
        this.$emit('valid', true);
        this.$emit('changeName', null);
      } else if (!this.defaultEnvironment.name) {
        this.$emit('valid', false);
      } else {
        this.$emit('valid', true);
      }
      this.$emit('changeOption', newValue);
    },
    toggleShowSelectionDialog() {
      this.showSelectionDialog = !this.showSelectionDialog;
    },
    onSelectionDialogSubmit(environment) {
      this.defaultEnvironment = environment;
      this.toggleShowSelectionDialog();
      this.$emit('valid', true);
      this.$emit('changeName', this.defaultEnvironment.name);
    },
    onSelectionDialogClose() {
      if (this.defaultEnvironmentIsEmpty) {
        // we have no setting but the user has cancelled the dialog
        this.optionValue = SpecifyDefaultOptionsValues.system;
        this.onOptionValueChange(this.optionValue);
      }
      this.toggleShowSelectionDialog();
    },
    onEdit() {
      this.toggleShowSelectionDialog();
    },
    // called from parent
    resetState() {
      // Don't update through user action handler (onOptionValueChange) as that
      // will confuse the internal state and notifications.
      this.defaultEnvironment = this.lookupEnvironmentByImage(this.imageName);
      this.optionValue = this.calculatedOptionValue();
      this.$emit('valid', true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.sub-section-label {
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: $color-secondary-inverse;
  margin-top: 0.4rem;
  margin-bottom: 0.25rem;
}
.detail {
  border: 1px solid $color-medium-grey;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: rgb(89, 88, 88);
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 16px;
}
.small-text-button {
  font-size: 13px;
  flex: 0 0;
  margin-left: 5px;
  padding: 2px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: $color-button-background;
  transition-property: background-color;
  transition-duration: 0.25s;
  color: rgba(0, 0, 0, 0.847);
  line-height: 30px;
  flex: 0 0;
  min-width: 45px;
  text-align: center;
}
.mt-normal {
  margin-top: 0.5rem;
}
.mt-small {
  margin-top: 0.4rem;
}
.mb-none {
  margin-bottom: 0 !important;
}
.full-width {
  width: 100%;
}
.environment-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: anywhere;

  &__title-name {
    flex: 1 1;
  }
}
</style>
