<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="menu">
        <div
          class="menuItems"
          role="menubar"
          tabindex="-1"
        >
          <router-link
            v-for="item in menuItems"
            :key="item.i"
            :to="{ name: item.name }"
            :class="['menuItem', item.cssClass, { 'active': currentRoute === item.name }]"
            role="menuitem"
            :data-automation="`admin-nav-item ${item.dataAutomationClass}`"
          >
            <span>{{ $t(item.text) }}</span>
          </router-link>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AdminView',
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    menuItems() {
      const items = [
        {
          name: 'admin.metrics',
          cssClass: 'metrics',
          dataAutomationClass: 'admin-nav-metrics',
          text: 'navPanel.admin.metrics',
          enabled: true
        },
        {
          name: 'admin.mail_settings',
          cssClass: 'settings',
          dataAutomationClass: 'admin-nav-settings',
          text: 'navPanel.admin.mailSettings',
          enabled: true
        },
        {
          name: 'admin.tag_editor',
          cssClass: 'tags',
          dataAutomationClass: 'admin-nav-tags',
          text: 'navPanel.admin.tags',
          enabled: true
        },
        {
          name: 'admin.audit_logs',
          cssClass: 'auditLogs',
          dataAutomationClass: 'admin-nav-logs',
          text: 'navPanel.admin.auditLogs',
          enabled: true
        },
        {
          name: 'admin.unpublished',
          cssClass: 'unpublished',
          dataAutomationClass: 'admin-nav-unpub',
          text: 'navPanel.admin.unpublishedContent',
          enabled: true
        },
        {
          name: 'admin.scheduled_content',
          cssClass: 'scheduled',
          dataAutomationClass: 'admin-nav-scheduled',
          text: 'navPanel.admin.scheduledContent',
          enabled: true
        },
        {
          name: 'admin.server_status',
          cssClass: 'serverStatus',
          dataAutomationClass: 'admin-nav-serverstatus',
          text: 'navPanel.admin.serverStatusReports',
          enabled: this.serverSettings.selfTestEnabled
        },
        {
          name: 'admin.queue',
          cssClass: 'queue',
          dataAutomationClass: 'admin-nav-queue',
          text: 'navPanel.admin.queue',
          enabled: true
        },
        {
          name: 'admin.server_settings_toggler',
          cssClass: 'serverSettingsToggler',
          dataAutomationClass: 'admin-nav-server-settings-toggler',
          text: 'navPanel.admin.serverSettingsToggler',
          enabled: this.serverSettings.serverSettingsToggler
        }
      ];
      return items.filter(item => item.enabled);
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    })
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.menu {
  font-weight: inherit;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  .menuItems {
    display: block;
  }

  .menuItem, .menuItem * {
    @include transition-property(opacity, background-color);
    @include normal-transition-duration();
  }

  .menuItem {
    color: inherit;
    line-height: inherit;
    background-color: transparent;
    display: inline-block;
    padding: 6px 15px;
    margin-right: 3px;
    vertical-align: middle;

    cursor: pointer;

    color: inherit;

    border-radius: $small-border-radius;

    &:hover {
      text-decoration: none;
      background-color: $color-light-grey;
    }

    &.active {
      background-color: $color-light-grey-2;
      border: 1px solid $color-alternate;
    }
  }
}

.menu {
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 11px;

  .menuItem {
    margin-right: 10px;
    border: 1px solid $color-light-grey-4;

    &.active {
      border-color: $color-alternate;
    }
  }
}

@media screen and (max-width: 1023px){
  .menu {
    display: none;
  }
}

</style>
