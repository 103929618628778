<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <button
    :class="{ 'jump-start-example--selected': selected }"
    class="jump-start-example"
    tabindex="-1"
    @focus="focusList"
    @click="select"
    @keypress.enter="submitSelected"
  >
    <img
      alt=""
      :src="example.thumbnailUrl"
      class="jump-start-example__thumbnail"
    >
    <div class="jump-start-example__details">
      <div class="jump-start-example__title">
        {{ example.title }}
      </div>
      <div class="jump-start-example__type">
        {{ typeString }}
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Example',
  props: {
    example: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['focusList', 'publish', 'select'],
  computed: {
    typeString() {
      return this.$t(`content.types.${this.example.type}.name`);
    },
  },
  methods: {
    focusList() {
      this.$emit('focusList');
    },
    select() {
      this.$emit('select', this.example);
    },
    submitSelected() {
      this.$emit('publish', this.example);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.jump-start-example {
  font-size: 14px;
  padding: 0;
  background-color: transparent;
  border: 2px solid $color-dialog-background;
  border-radius: 3px;
  cursor: pointer;
  width: 240px;

  &:focus {
    @include control-focus();
  }

  &--selected {
    border-color: $color-primary;
  }

  &__thumbnail {
    max-width: 234px;
    height: 135px;
    background-size: cover;
    background-color: $color-medium-grey;
  }

  &__details {
    padding: 1rem;
    background-color: $color-light-grey;
  }

  &__title {
    margin-bottom: 5px;
    line-height: 1.4em;
  }

  &__type {
    font-size: 0.85em;
  }
}
</style>
