<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    v-if="loaded"
    class="band"
  >
    <div class="bandContent mainPage">
      <div class="menu">
        <div class="menuItems">
          <router-link
            :to="{ name: 'people.users.profile', params: { guid: currentUser.guid } }"
            class="menuItem"
            role="button"
          >
            {{ $t('users.title.profile') }}
          </router-link>
          <router-link
            :to="{ name: 'people.users.api_keys', params: { guid: currentUser.guid } }"
            class="menuItem active"
            role="button"
            :aria-current="true"
          >
            {{ $t('users.title.apiKeys') }}
          </router-link>
        </div>
      </div>

      <div class="majorMinorColumnsContainer">
        <div class="majorColumn">
          <div class="sectionTitle flex">
            <h1
              class="view-title"
              data-automation="api-keys-title"
            >
              {{ $t('apiKeys.title') }}
            </h1>
            <div class="actionBar inline showTitles">
              <BaseButton
                :title="$t('apiKeys.newKey')"
                :label="$t('apiKeys.newKey')"
                :aria-label="$t('apiKeys.newKeyAria')"
                class="action new"
                data-automation="add-new-api-key"
                @clicked.prevent="toggleCreateKeyModal"
              />
            </div>
          </div>

          <KeysTable
            v-if="hasKeys"
            :keys="apiKeys"
            @delete-intention="onDeleteRequest"
          />
          <p
            v-else
            class="no-keys-msg"
          >
            {{ $t('apiKeys.noKeysMsg') }}
          </p>
        </div>
        <div class="minorColumn">
          <div
            data-automation="api-keys__about__container"
            class="section"
          >
            <h2 class="sectionTitle view-subtitle">
              {{ $t('apiKeys.about.title') }}
            </h2>
            <div class="sectionBlurb">
              <i18n-t
                keypath="apiKeys.about.description"
                tag="p"
              >
                <template #warning>
                  <span class="emphasize">
                    {{ $t('apiKeys.about.impersonateWarning') }}
                  </span>
                </template>
              </i18n-t>
              <i18n-t
                keypath="apiKeys.about.docsRef"
                tag="p"
              >
                <a
                  :href="linkToApiKeysDocs"
                  target="_blank"
                >
                  {{ $t('apiKeys.about.linkLabel') }}
                </a>
              </i18n-t>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteKey
      v-if="showDeleteModal"
      :api-key="deleteKeyTarget"
      @deleted="onKeyDeleted"
      @close="toggleDeleteKeyModal"
    />
    <CreateKey
      v-if="showCreateModal"
      @created="onKeyCreated"
      @close="toggleCreateKeyModal"
    />
    <NewKeyConfirmation
      v-if="showNewKeyConfirmation"
      :api-key="newKey"
      @close="clearNewKeyConfirmation"
    />
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';
import { getCurrentUserAPIKeys } from '@/api/users';
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';

import BaseButton from '@/components/BaseButton';
import KeysTable from '@/views/users/apikeys/KeysTable';
import CreateKey from '@/views/users/apikeys/CreateKey';
import DeleteKey from '@/views/users/apikeys/DeleteKey';
import NewKeyConfirmation from '@/views/users/apikeys/NewKeyConfirmation';

export default {
  name: 'UserKeysView',
  components: {
    BaseButton,
    KeysTable,
    CreateKey,
    DeleteKey,
    NewKeyConfirmation,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      newKey: null,
      deleteKeyTarget: null,
      showDeleteModal: false,
      showCreateModal: false,
      showNewKeyConfirmation: false,
      apiKeys: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    hasKeys() {
      return this.apiKeys.length > 0;
    },
    linkToApiKeysDocs() {
      return docsPath('/user/api-keys');
    },
  },
  created() {
    this.pullUserKeys();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    pullUserKeys() {
      return getCurrentUserAPIKeys()
        .then(apiKeys => {
          if (this.currentUser.guid === this.guid) {
            this.apiKeys = apiKeys;
            this.loaded = true;
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    onDeleteRequest(key) {
      this.deleteKeyTarget = key;
      this.toggleDeleteKeyModal();
    },
    toggleDeleteKeyModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    toggleCreateKeyModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    onKeyDeleted() {
      this.pullUserKeys();
      this.toggleDeleteKeyModal();
      this.setInfoMessage({
        message: this.$t('apiKeys.delete.keyDeleted'),
      });
    },
    onKeyCreated(newKey) {
      this.pullUserKeys();
      this.toggleCreateKeyModal();
      this.newKey = newKey;
      this.showNewKeyConfirmation = true;
    },
    clearNewKeyConfirmation() {
      this.showNewKeyConfirmation = false;
      this.newKey = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-title {
  font-size: 1.5rem;
}
.view-subtitle {
  font-size: 1.25rem;
}
.no-keys-msg {
  padding: 2rem;
  text-align: center;
}
</style>
