// Copyright (C) 2022 by Posit Software, PBC.

export const JUPYTER_NOTEBOOK = 'JUPYTER_NOTEBOOK';
export const JUPYTER_VOILA = 'JUPYTER_VOILA';
export const PIN = 'PIN';
export const PLOT = 'PLOT';
export const PLUMBER_API = 'PLUMBER_API';
export const PYTHON_API = 'PYTHON_API';
export const PYTHON_FASTAPI = 'PYTHON_FASTAPI';
export const PYTHON_DASH = 'PYTHON_DASH';
export const PYTHON_STREAMLIT = 'PYTHON_STREAMLIT';
export const PYTHON_BOKEH = 'PYTHON_BOKEH';
export const PYTHON_SHINY = 'PYTHON_SHINY';
export const PYTHON_VETIVER = 'PYTHON_VETIVER';
export const RMARKDOWN = 'RMARKDOWN';
export const RMARKDOWN_PARAMS = 'RMARKDOWN_PARAMS';
export const SHINY_APP = 'SHINY_APP';
export const SHINY_RMARKDOWN = 'SHINY_RMARKDOWN';
export const TENSOR_FLOW = 'TENSOR_FLOW';
export const R_VETIVER = 'R_VETIVER';
export const QUARTO = 'QUARTO';
export const QUARTO_R = 'QUARTO_R';
export const QUARTO_R_SCRIPT = 'QUARTO_R_SCRIPT';
export const QUARTO_PYTHON = 'QUARTO_PYTHON';
export const QUARTO_PYTHON_SCRIPT = 'QUARTO_PYTHON_SCRIPT';
export const QUARTO_WEBSITE = 'QUARTO_WEBSITE';

export const BY_LANGUAGE = {
  // How would we handle multiple Jump Start examples for one item?
  R: [
    PIN, // TODO: separate R and Python flavored pin examples?
    PLOT,
    PLUMBER_API,
    RMARKDOWN,
    RMARKDOWN_PARAMS,
    SHINY_APP,
    SHINY_RMARKDOWN,
    R_VETIVER,
    QUARTO_R,
    QUARTO_R_SCRIPT,
  ],
  PYTHON: [
    JUPYTER_NOTEBOOK,
    JUPYTER_VOILA,
    PYTHON_API,
    PYTHON_DASH,
    PYTHON_FASTAPI,
    PYTHON_STREAMLIT,
    PYTHON_BOKEH,
    PYTHON_SHINY,
    PYTHON_VETIVER,
    QUARTO_PYTHON,
    QUARTO_PYTHON_SCRIPT,
    QUARTO_WEBSITE,
  ],
  QUARTO: [
    QUARTO,
    QUARTO_R,
    QUARTO_R_SCRIPT,
    QUARTO_PYTHON,
    QUARTO_PYTHON_SCRIPT,
    QUARTO_WEBSITE
  ],
};

export default [
  BY_LANGUAGE,
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLOT,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_STREAMLIT,
  PYTHON_BOKEH,
  PYTHON_SHINY,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_R,
  QUARTO_R_SCRIPT,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  SHINY_APP,
  SHINY_RMARKDOWN,
  TENSOR_FLOW,
  R_VETIVER,
];
