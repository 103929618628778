<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div class="rs-field">
      <label
        for="schedule-interval-weekly"
        class="rs-radio__label"
      >
        <input
          id="schedule-interval-weekly"
          v-model="scheduleType"
          type="radio"
          class="rs-radio__input interval-check"
          name="schedule-weekly"
          data-automation="schedule-weekly__by-week"
          :disabled="disabled"
          :value="WEEK"
        >
        <IntervalScheduleInput
          v-model.number="schedInterval"
          :term="intervalTerm"
          :disabled="disabled"
          @change="intervalChange"
        />
      </label>
    </div>

    <RSInputRadio
      v-model="scheduleType"
      name="schedule-weekly"
      class="schedule-week-days"
      data-automation="schedule-weekly__days-pick"
      :label="`${$t('appSettings.schedule.inputLabels.every')}...`"
      :disabled="disabled"
      :value="DAYOFWEEK"
    />

    <RSCheckboxGroup
      name="schedule-days-of-week"
      data-automation="schedule-weekly__days-of-week"
      :class="{'invalid-day-of-week-selection': invalidSelection}"
      :options="dow"
      :read-only="disabled"
      @change="daysOfWeekChange"
    />
  </div>
</template>

<script>
import { ScheduleTypes, DaysOfWeek } from '@/api/dto/schedule';
import RSInputRadio from '@/elements/RSInputRadio';
import RSCheckboxGroup from '@/elements/RSCheckboxGroup';
import IntervalScheduleInput from '@/components/Schedule/IntervalScheduleInput';

export default {
  name: 'WeeklySchedule',
  components: {
    RSInputRadio,
    RSCheckboxGroup,
    IntervalScheduleInput,
  },
  props: {
    type: {
      type: String,
      default: ScheduleTypes.Week,
    },
    interval: {
      type: Number,
      default: 1,
    },
    days: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'invalid'],
  data() {
    const dayLabel = term => this.$t(`appSettings.schedule.inputLabels.${term}`);
    const dow = DaysOfWeek.options();
    return {
      scheduleType: this.type,
      schedInterval: this.interval,
      choosenDays: [...this.days],
      dow: dow.map((d, i) => ({
        id: i,
        label: dayLabel(d),
        checked: this.days.includes(i),
      })),
    };
  },
  computed: {
    isDayOfWeek() {
      return this.scheduleType === ScheduleTypes.DayOfWeek;
    },
    invalidSelection() {
      return this.isDayOfWeek && !this.choosenDays.length;
    },
    intervalTerm() {
      if (this.schedInterval > 1) {
        return this.$t(`appSettings.schedule.inputLabels.plural.week`);
      }
      return this.$t(`appSettings.schedule.inputLabels.singular.week`);
    },
  },
  watch: {
    scheduleType(type){
      this.handleTypeChange(type);
    },
  },
  created() {
    this.WEEK = ScheduleTypes.Week;
    this.DAYOFWEEK = ScheduleTypes.DayOfWeek;
  },
  methods: {
    daysOfWeekChange(v) {
      // If days of week isn't selected, do it automatically
      if (this.scheduleType === ScheduleTypes.Week) {
        this.scheduleType = ScheduleTypes.DayOfWeek;
      }

      this.updateDaysOfWeek(v.selectionTree);

      if (this.invalidSelection) {
        this.$emit('invalid');
      } else {
        this.emitChange();
      }
    },
    updateDaysOfWeek(selectionTree) {
      this.choosenDays = selectionTree.map(d => d.id);
    },
    handleTypeChange(type) {
      // When interval changes to days of week
      // pick Monday by default.
      if (type === ScheduleTypes.DayOfWeek) {
        this.dow[1].checked = true;
        this.choosenDays = [1];
      }

      // When interval changes to every X weeks
      // clear up selected days.
      if (type === ScheduleTypes.Week) {
        this.dow.forEach(d => (d.checked = false));
        this.choosenDays = [];
      }

      this.emitChange();
    },
    intervalChange() {
      if (this.scheduleType === ScheduleTypes.Week) {
        this.emitChange();
      }
    },
    async emitChange() {
      // Wait for model values to update before firing the event
      await this.$nextTick();
      this.$emit('change', {
        interval: Number(this.schedInterval),
        type: this.scheduleType,
        days: this.choosenDays,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';

.invalid-day-of-week-selection {
  .rs-checkbox__label {
    color: $color-alert-error;
  }
}
</style>

<style lang="scss" scoped>
input.interval-check[type="radio"] {
  vertical-align: middle;
}

.rs-field.schedule-week-days {
  margin-bottom: 0;
}

.rs-checkboxgroup {
  padding-left: 1rem;
}
</style>
