<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script>
// This compat config is required until we remove vue/compat
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false,
    INSTANCE_LISTENERS: false,
  }
};
</script>
<script setup>
import {
  ref,
  computed,
  nextTick,
  onMounted,
  onBeforeUpdate,
  onBeforeUnmount,
} from 'vue';
import { debounce } from '@/utils/debounce';

// Props
const props = defineProps({
  modelValue: {
    type: [String, Array],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  searchLabel: {
    type: String,
    default: '',
  },
  options: {
    type: Array, // [{ label, value, sub (optional) }]
    required: true,
  },
  search: {
    type: Function,
    default: null,
  },
});

// Emits
const emit = defineEmits(['update:modelValue']);

const dropdown = ref(null);
const dropdownItems = ref([]);
const dropdownButton = ref(null);
const searchInput = ref(null);
const openDropdown = ref(false);
const searchPrefix = ref('');

const isMultiValue = computed(() => Array.isArray(props.modelValue));
const selectionSubindex = computed(() => {
  const valueLen = props.modelValue.length;
  if (!valueLen) {
    // Show nothing if nothing is selected
    return '';
  } else if (isMultiValue.value) {
    // Multiple values selected, show count
    return `${valueLen} selected`;
  }
  // Attempt to get sub label from options
  return props.options.find(op => op.value === props.modelValue)?.label || '';
});

/**
 * Check whether the given option is currently selected or not.
 * @param {String} optValue Option value to check.
 * @returns {Boolean}
 */
const isOptionSelected = optValue => {
  if (isMultiValue.value) {
    return props.modelValue.includes(optValue);
  }
  return optValue === props.modelValue;
};

/**
 * Handler of this component's v-model. Emitting update:modelValue.
 *
 * When the v-model is an Array, it adds the given value to it
 * or removes it when the value already existed.
 *
 * When the v-model is a String, it selects the given value as the value
 * or if it already was the case removes it and defaults to the first option value.
 *
 * For a single value selection dropdown, it automatically closes the options after updating.
 * @param {Object} option The option value selected.
 */
const selectOption = ({ value: newVal, label }) => {
  let newModelValue;
  const isSelected = isOptionSelected(newVal);

  if (isMultiValue.value) {
    if (isSelected) {
      newModelValue = props.modelValue.filter(v => v !== newVal);
    } else {
      newModelValue = [...props.modelValue, newVal];
    }
  } else {
    newModelValue = isSelected ? props.options[0].value : newVal;
  }

  emit('update:modelValue', newModelValue);

  if (!isMultiValue.value) {
    dropdownButton.value.focus();
    openDropdown.value = false;
  }
};

/**
 * Emit search event with search prefix value after debounce settles.
 */
const searchOptions = debounce(300, () => {
  props.search(searchPrefix.value);
});

/**
 * Toggle the dropdown visibility.
 * Automatically triggers focus on the first option, or the input search when applicable.
 */
const toggleDropdown = () => {
  openDropdown.value = !openDropdown.value;
  if (openDropdown.value) {
    nextTick(() => {
      (props.search ?
        searchInput.value :
        dropdownItems.value[0]
      ).focus();
    });
  }
};

/**
 * Method used to close the dropdown for clicks and focus outside the dropdown bounds.
 * @param {ClickEvent} ev
 */
const handleOutsideEv = ev => {
  const evTarget = ev.type === 'blur' ? ev.relatedTarget : ev.target;
  if (!dropdown.value.contains(evTarget) && openDropdown.value){
    openDropdown.value = false;
  }
};

/**
 * Method to focus on the given option index. Mainly for keyboard navigation purposes.
 * @param {Number} optIndex Option index to focus on.
 */
const handleKeyboardFocus = (optIndex = 0) => {
  if (optIndex > -1 && optIndex < dropdownItems.value.length) {
    dropdownItems.value[optIndex].focus();
  }
};

/**
 * Keyboard "esc" press handler.
 * Closes the dropdown and gives focus to the main toggle button.
 */
const handleEsc = () => {
  dropdownButton.value.focus();
  openDropdown.value = false;
};

// Since options can change via search filtering,
// it is required to re-generate the options refs
// that are used for keyboard navigation.
const dropdownGenRef = (el, index) => {
  dropdownItems.value[index] = el;
};
onBeforeUpdate(() => {
  dropdownItems.value = [];
});

onMounted(() => {
  window.addEventListener('click', handleOutsideEv);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', handleOutsideEv);
});
</script>

<template>
  <div
    ref="dropdown"
    class="dropdown"
  >
    <button
      ref="dropdownButton"
      class="dropdown__button"
      aria-haspopup="menu"
      :aria-expanded="openDropdown"
      :data-automation="`${name}-dropdown`"
      @click.prevent="toggleDropdown"
      @keypress.enter.prevent="toggleDropdown"
      @keypress.space.prevent="toggleDropdown"
    >
      {{ label }}
      <sub>
        {{ selectionSubindex }}
      </sub>
      <span class="dropdown__label-icon" />
    </button>
    <menu
      v-if="openDropdown"
      class="dropdown__menu"
      :data-automation="`${name}-dropdown-menu`"
    >
      <div
        v-if="search"
        class="dropdown__menu-search"
      >
        <label :for="name">
          {{ searchLabel }}
        </label>
        <input
          :id="name"
          ref="searchInput"
          v-model="searchPrefix"
          type="text"
          :data-automation="`${name}-dropdown-search`"
          @input="searchOptions"
          @blur="handleOutsideEv"
          @keydown.down.prevent="handleKeyboardFocus(0)"
          @keydown.esc.prevent="handleEsc"
        >
      </div>
      <div
        class="dropdown__menu-options"
        role="listbox"
        tabindex="0"
        @keydown.down.prevent="handleKeyboardFocus(0)"
        @blur="handleOutsideEv"
      >
        <div
          v-for="(opt, index) in options"
          :id="`${name}-${opt.value}`"
          :key="opt.value"
          :ref="el => dropdownGenRef(el, index)"
          role="option"
          tabindex="-1"
          :class="{ selected: isOptionSelected(opt.value) }"
          :aria-selected="isOptionSelected(opt.value)"
          @blur="handleOutsideEv"
          @click="selectOption(opt)"
          @keypress.enter="selectOption(opt)"
          @keydown.up.prevent.stop="handleKeyboardFocus(index - 1)"
          @keydown.down.prevent.stop="handleKeyboardFocus(index + 1)"
          @keydown.esc.prevent.stop="handleEsc"
        >
          <span>
            {{ opt.label }}
            <sub
              v-if="opt.sub"
            >
              {{ opt.sub }}
            </sub>
          </span>
        </div>
      </div>
    </menu>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

$entry-height: 2.07rem;

.dropdown {
  color: $color-heading;
  position: relative;
  margin-left: 1.44rem;

  &__button {
    background: none;
    color: $color-heading;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;

    & sub {
      color: $color-dark-grey;
      position: absolute;
      top: 1rem;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline 0.1rem;
    }

    &::-webkit-details-marker {
      display:none;
    }
  }

  &__label-icon {
    background-position: center center;
    background-size: 30px 30px;
    background-image: url('/images/elements/actionMenuDropdownAlt.svg');
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 0.2rem;
    height: 1rem;
    width: 1rem;
  }

  &__menu {
    background: #fff;
    border: 1px solid $color-light-grey-4;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(#000, 0.2);
    border-radius: 0.3rem;
    position: absolute;
    top: 1.2rem;
    right: 0;
    width: 16rem;
    z-index: 1; // Required due to this being a pop-over

    &-options {
      max-height: $entry-height * 6;
      overflow-y: auto;

      & [role="option"] {
        border-bottom: 1px solid $color-light-grey-4;
        display: flex;
        align-items: center;
        line-height: $entry-height;
        padding: 0 1rem 0 2rem;
        position: relative;
        height: 2.07rem;

        & span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover {
          background: $color-light-grey-2;
          cursor: pointer;
        }

        &:focus {
          background: $color-light-grey-4;
        }
      }

      & sub {
        color: $color-dark-grey;
        margin-left: 0.3rem;
        margin-top: 0.1rem;
      }

      & .selected {
        font-weight: bold;

        &::before {
          content: '';
          display: inline-block;
          background-position: center center;
          background-size: 1.44rem 1.44rem;
          background-image: url('/images/elements/check.svg');
          background-repeat: no-repeat;
          position: absolute;
          display: inline-block;
          height: 1rem;
          width: 1rem;
          left: 0.5rem;
        }
      }
    }

    &-search {
      border-bottom: 1px solid $color-light-grey-4;
      padding: 0.8rem;

      & label {
        display: inline-block;
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 0.3rem;
      }

      & input {
        // cursor: default;
        border: 1px solid $color-medium-grey;
        color: $color-dark-grey-3;
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
