<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__rmarkdown-params-intro wizard-panel--blue">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p><span>{{ $t('publish.rmarkdownParamsIntro.body') }}</span></p>
      <p><span>{{ $t('publish.rmarkdownParamsIntro.howToPublish') }}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RMarkdownParamsIntro',
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__rmarkdown-params-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &__content, &__content * {
    background-color: transparent;
    span {
      background-color: $color-primary-dark;
      display: inline;
    }
  }
}
</style>
