<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="$t('appSettings.vars.labels.conflict')"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p
          v-for="(item, i) in $tm('appSettings.vars.messages.conflict')"
          :key="i"
        >
          {{ $rt(item) }}
        </p>
        <pre>{{ variables }}</pre>
      </div>
    </template>

    <template #controls>
      <RSButton
        id="ecd-reload"
        :label="$t('common.buttons.reload')"
        @click="$emit('close')"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';

export default {
  name: 'EnvironmentConflictDialog',
  components: { RSButton, RSModal },
  props: {
    variables: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  mounted() {
    this.$el.querySelector('button#ecd-reload').focus();
  },
};
</script>
