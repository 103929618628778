<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModalForm
    v-if="loaded"
    :active="true"
    :subject="$t('renderSender.title')"
    data-automation="email-report-modal"
    @close="$emit('hide')"
  >
    <template #content>
      <div class="indented">
        <RSRadioGroup
          v-model="recipients"
          :title="app.isSite() ? $t('renderSender.details.isSite') : $t('renderSender.details.notSite')"
          name="sendReport"
          :options="radioOptions"
        />
      </div>
    </template>
    <template #controls>
      <div class="actions">
        <RSButton
          type="primary"
          :label="$t('renderSender.send')"
          @click="submit"
        />
      </div>
    </template>
  </RSModalForm>
</template>

<script>
import RSButton from '@/elements/RSButton';
import RSModalForm from '@/elements/RSModal';
import RSRadioGroup from '@/elements/RSRadioGroup';
import AppRoles from '@/api/dto/appRole';
import { getApp } from '@/api/app';
import { sendEmail } from '@/api/email';
import { SHOW_INFO_MESSAGE, SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'EmailDialog',
  components: {
    RSButton,
    RSModalForm,
    RSRadioGroup,
  },
  emits: ['hide'],
  data() {
    return {
      app: null,
      recipients: 'me',
    };
  },
  computed: {
    ...mapState({
      guid: state => state.contentView.app.guid,
      variant: state => state.parameterization.currentVariant,
      temporalId: state => state.parameterization.currentVariant.temporalId,
      isDirtyReportOutput: state => state.legacyParams.form.dirty,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
      variantHistoryDisplayedId: state => state.contentView.renderingHistory.displayedId
    }),
    loaded() {
      return this.serverSettings?.mailConfigured && this.app?.isRenderable;
    },
    listPrincipals() {
      return this.app.groups.concat(this.app.users);
    },
    isCollaborator() {
      return this.currentUser?.isAppEditor(this.app);
    },
    hasCollaborators() {
      return this.listPrincipals.find(p => AppRoles.isCollaborator(p.appRole));
    },
    hasViewers() {
      return this.listPrincipals.find(p => AppRoles.isViewer(p.appRole));
    },
    mailCollaborators() {
      return this.isCollaborator && this.hasCollaborators && !this.isDirtyReportOutput;
    },
    mailCollaboratorsTitle() {
      if (!this.isCollaborator) {
        return this.$t('renderSender.titles.notCollaborator');
      } else if (this.isDirtyReportOutput) {
        return this.$t('renderSender.titles.isDirtyReportOutput');
      } else if (!this.hasCollaborators) {
        return this.$t('renderSender.titles.noCollaborators');
      }
      return '';
    },
    mailViewers() {
      return this.isCollaborator && this.hasViewers && !this.isDirtyReportOutput;
    },
    mailViewersTitle() {
      if (!this.isCollaborator) {
        return this.$t('renderSender.titles.notCollaborator');
      } else if (this.isDirtyReportOutput) {
        return this.$t('renderSender.titles.isDirtyReportOutput');
      } else if (!this.hasViewers) {
        return this.$t('renderSender.titles.noViewers');
      }
      return '';
    },
    mailAll() {
      return this.serverSettings.mailAll && this.app.accessType !== 'acl';
    },
    mailAllTitle() {
      if (!this.isCollaborator) {
        return this.$t('renderSender.titles.notCollaborator');
      } else if (this.isDirtyReportOutput) {
        return this.$t('renderSender.titles.isDirtyReportOutput');
      }
      return '';
    },
    radioOptions() {
      const collabTitle = this.mailCollaboratorsTitle;
      const canMailCollab = this.mailCollaborators;
      const viewersTitle = this.mailViewersTitle;
      const canMailViewers = this.mailViewers;
      const allTitle = this.mailAllTitle;
      const options = [
        {
          label: this.$t('renderSender.options.me'),
          value: 'me',
          disabled: false,
        },
        {
          label: this.$t('renderSender.options.collaborators'),
          title: collabTitle,
          value: 'collaborators',
          disabled: !canMailCollab,
        },
        {
          label: this.$t('renderSender.options.collaboratorsViewers'),
          title: viewersTitle,
          value: 'collaborators_viewers',
          disabled: !canMailViewers,
        },
      ];
      if (this.serverSettings.mailAll) {
        options.push(
          {
            label: this.$t('renderSender.options.all'),
            title: allTitle,
            value: 'all',
            disabled: !this.serverSettings.mailAll,
          }
        );
      }
      return options;
    },
  },
  async created() {
    this.app = await getApp(this.guid);

    if (!this.serverSettings.mailConfigured) {
      if (this.currentUser?.isAdmin()) {
        this.setErrorMessage({ message: this.$t('renderSender.noEmailAdmin') });
      } else {
        this.setErrorMessage({ message: this.$t('renderSender.noEmail') });
      }
    }
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    async submit() {
      const mailOptions = {
        variantId: this.isDirtyReportOutput ? this.temporalId : this.variant.id,
        renderingId: this.variantHistoryDisplayedId,
        email: this.recipients,
      };
      await sendEmail(mailOptions);
      this.$emit('hide');
      this.setInfoMessage({ message: this.$t('renderSender.emailSubmitted') });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.indented {
  text-align: left;
  :deep(.rs-radio__label):has(input:disabled) {
    color: $color-dark-grey;
    cursor: default;
    input:disabled {
      box-shadow: 0 0 0 2px $color-medium-grey-2;
      cursor: default;
    }
  }
}
</style>
