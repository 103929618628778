<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div v-if="!!app">
    <BundleLog
      v-if="showingLogs"
      :selected-bundle-id="selectedBundleId"
      @close="showingLogs = false"
    />
    <RSModal
      v-else-if="!!bundles"
      :active="true"
      :subject="$t('sourceVersions.title')"
      data-automation="source-versions-modal"
      @close="$emit('source-versions')"
    >
      <template #content>
        <div class="listbox jobsList">
          <!-- eslint-disable vuejs-accessibility/interactive-supports-focus -->
          <ul
            role="menu"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
            <li
              v-for="(bundle, index) in bundles"
              :key="bundle.id"
              :ref="`option-${index}`"
              role="menuitem"
              :tabindex="index === 0 ? 0 : -1"
              @click="handleClick(bundle.id)"
              @keyup.enter="handleClick(bundle.id)"
            >
              <!-- eslint-enable vuejs-accessibility/interactive-supports-focus -->
              <div
                class="item version"
                :class="{selected: isSelected(bundle)}"
              >
                <div
                  class="versionId"
                  data-automation="source-version__version-id"
                >
                  <div>
                    <span
                      class="pId"
                      data-automation="source-version__bundle-id"
                    >
                      {{ bundle.id }}
                    </span>
                    <span v-if="bundle.size"> ({{ bundleSize(bundle.size) }})</span>
                    <span v-if="!bundle.size"> &mdash; {{ $t('sourceVersions.unknownSize') }}</span>
                  </div>
                  <div
                    v-if="bundle.active"
                    class="activeVersion"
                  >
                    {{ $t('sourceVersions.active') }}
                  </div>
                </div>
                <div class="operation">
                  {{ firstOperationText(bundle.createdTime) }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <template #controls>
        <div
          v-if="!isDeleting"
          class="actions flex"
        >
          <div class="actionBar showTitles">
            <a
              :href="!selectedBundleId ? '' : downloadUrl(selectedBundleId)"
              class="action download"
              :class="{disabled: disableActions}"
              data-automation="bundle-download"
              :title="$t('sourceVersions.download')"
            >
              <span class="actionTitle">{{ $t('sourceVersions.download') }}</span>
            </a>
            <button
              :disabled="disableActions"
              :class="{ disabled: disableActions }"
              :title="$t('sourceVersions.view')"
              class="action viewLog actionTitle"
              data-automation="bundle-logs"
              @click="showingLogs = true"
            >
              {{ $t('sourceVersions.view') }}
            </button>
            <button
              :disabled="disableDelete"
              :class="{ disabled: disableDelete }"
              :title="$t('sourceVersions.delete')"
              class="action delete actionTitle"
              data-automation="bundle-delete"
              @click="isDeleting = true"
            >
              {{ $t('sourceVersions.delete') }}
            </button>
          </div>
          <RSButton
            type="primary"
            :disabled="disableActions"
            :class="{ disabled: disableActions }"
            :label="activateButtonLabel"
            :title="activateButtonLabel"
            data-automation="bundle-publish"
            @click="publishBundle"
          >
            {{ activateButtonLabel }}
          </RSButton>
        </div>

        <div
          v-else
          class="actions"
        >
          <div class="actionBar delete-controls">
            <p class="delete-prompt">
              Delete this permanently?
            </p>

            <div
              class="confirm-buttons"
            >
              <RSButton
                label="No"
                type="primary"
                :use-label-width="true"
                data-automation="delete-source-cancel__button"
                @click="isDeleting = false"
              />
              <RSButton
                label="Yes"
                type="secondary"
                :use-label-width="true"
                data-automation="delete-source-version__button"
                @click="onDeleteBundle(selectedBundleId)"
              />
            </div>
          </div>
        </div>
      </template>
    </RSModal>
  </div>
</template>

<script>
import { getBundleDownloadUrl } from '@/api/bundle';
import RSButton from '@/elements/RSButton';
import RSModal from '@/elements/RSModal';
import {
  DELETE_BUNDLE,
  DEPLOY_BUNDLE,
  SELECT_BUNDLE,
} from '@/store/modules/bundles';
import { humanizeBytesDecimal } from '@/utils/bytes.filter';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { mapActions, mapMutations, mapState } from 'vuex';
import BundleLog from './BundleLog';

dayjs.extend(relativeTime);

export default {
  name: 'SourceVersions',
  components: { RSButton, RSModal, BundleLog },
  emits: ['source-versions'],
  data() {
    return {
      isDeleting: false,
      showingLogs: false,
      currentItem: 0,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      bundles: state => state.bundles.items,
      activeBundle: state => state.bundles.items.find(b => b.active),
      selectedBundleId: state => state.bundles.selected.bundleId,
    }),
    activeBundleId() {
      return Number(this.activeBundle?.id) || null;
    },
    disableActions() {
      return !this.selectedBundleId;
    },
    disableDelete() {
      return !this.selectedBundleId ||
        this.activeBundleId === this.selectedBundleId ||
        this.bundles.length === 1;
    },
    activateButtonLabel() {
      return this.activeBundleId === this.selectedBundleId ? this.$t('sourceVersions.reactivate') : this.$t('sourceVersions.activate');
    }
  },
  methods: {
    ...mapMutations({
      setBundlePosition: SELECT_BUNDLE,
    }),
    ...mapActions({
      deleteBundle: DELETE_BUNDLE,
      deployBundle: DEPLOY_BUNDLE,
    }),
    isSelected(bundle) {
      return Number(bundle.id) === this.selectedBundleId;
    },
    firstOperationText(when) {
      const mnt = dayjs(when);
      return `${ this.$t('sourceVersions.published') } ${ mnt.fromNow() }`;
    },
    bundleSize(size) {
      return humanizeBytesDecimal(size);
    },
    handleClick(id) {
      if (this.isDeleting) { return; }
      this.setBundlePosition(id);
    },
    downloadUrl(bundleId) {
      return getBundleDownloadUrl(this.app.guid, bundleId);
    },
    async onDeleteBundle(bundleId) {
      await this.deleteBundle({ appGuid: this.app.guid, bundleId });
      this.isDeleting = false;
    },
    publishBundle() {
      this.deployBundle({ bundleId: this.selectedBundleId, appId: this.app.id });
      this.showingLogs = true;
    },
    focusItem() {
      this.$nextTick(() => this.$refs[`option-${this.currentItem}`][0].focus());
    },
    onArrowUp() {
      const currentItem = this.currentItem - 1;
      this.currentItem = currentItem < 0 ? this.bundles.length - 1 : currentItem;
      this.focusItem();
    },
    onArrowDown() {
      const currentItem = this.currentItem + 1;
      this.currentItem = currentItem > this.bundles.length - 1 ? 0 : currentItem;
      this.focusItem();
    },
  }
};
</script>

<style scoped lang="scss">
  .listbox li {
    margin: 2px;
  }
  .listbox .item {
    margin: 0 -2px;
  }
  .rs-modal {
    text-align: left;
  }

  .actions {
    width: 100%;
    .actionBar {
      padding-left: 0;
    }

    .delete-controls {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .confirm-buttons {
        display: flex;
        justify-content: space-between;

        .rs-button {
          margin-left: 1rem;
        }
      }
    }

    .delete-prompt {
      font-size: 1.1rem;
      font-weight: 600
    }
  }
</style>
