<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <h2 class="sectionTitle">
      {{ $t('users.title.publishedContent') }}
    </h2>
    <div
      v-if="status.message"
      class="rs-field"
    >
      <EmbeddedStatusMessage
        :type="status.type"
        :message="status.message"
        :show-close="false"
      />
    </div>
    <div
      v-if="!api.applications.length"
      class="emptyListMessage"
    >
      {{ $t('content.list.labels.emptyForUser') }}
    </div>
    <RSTable
      v-if="api.applications.length"
      :columns="tableColumns"
      :table-name="$t('users.title.publishedContent')"
    >
      <RSTableRow
        v-for="app in api.applications"
        :key="app.guid"
      >
        <RSTableCell
          :cell-id="`app-name-${app.guid}`"
          :has-icon="true"
          :fill="true"
          data-automation="pcl-content-name"
        >
          <router-link
            :to="{ name: 'apps.access', params: { idOrGuid: app.guid } }"
          >
            <div class="content-table__content-name">
              <div>
                <i
                  :class="app.iconClass()"
                  class="rs-icon rs-icon--large"
                />
              </div>
              <div class="content-table__display-name">
                {{ app.displayName || $t('content.untitled') }}
              </div>
            </div>
          </router-link>
        </RSTableCell>
        <RSTableCell data-automation="pcl-content-type">
          {{ $t(`content.type.description.${app.contentType()}`) }}
        </RSTableCell>
        <RSTableCell data-automation="pcl-content-deployed-time">
          {{ lastDeployedTime(app) }}{{ fromGit(app.git) }}
        </RSTableCell>
      </RSTableRow>
    </RSTable>

    <RSPager
      v-show="showPager"
      :disable-left-actions="disablePreviousPagination"
      :disable-right-actions="disableNextPagination"
      :labels="pagerLabels"
      @first-page="gotoPage('first')"
      @previous-page="gotoPage('previous')"
      @next-page="gotoPage('next')"
      @last-page="gotoPage('last')"
    />
  </div>
</template>

<script>
import RSPager from '@/elements/RSPager';
import RSTable from '@/elements/RSTable';
import RSTableRow from '@/elements/RSTableRow';
import RSTableCell from '@/elements/RSTableCell';

import { getPublishedContentForUser } from '@/api/content';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { activeTime } from '@/utils/activeTime.filter';
import { fromGit } from '@/utils/contentList.js';

// number of records to display at a time
const maxRecords = 10;

export default {
  name: 'PublishedContentList',
  components: {
    EmbeddedStatusMessage,
    RSPager,
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    userGuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      status: {
        message: null,
        type: null,
      },
      api: {
        applications: [],
        start: 0,
        count: 0,
        total: 0,
        continuation: '',
      },
    };
  },
  computed: {
    showPager() {
      return this.api.total > maxRecords;
    },
    pagerLabels() {
      return {
        first: this.$t('navigation.pagination.temporal.first'),
        previous: this.$t('navigation.pagination.temporal.previous'),
        next: this.$t('navigation.pagination.temporal.next'),
        last: this.$t('navigation.pagination.temporal.last'),
      };
    },
    disablePreviousPagination() {
      return this.api.start === 0;
    },
    disableNextPagination() {
      return this.api.start + this.api.count >= this.api.total;
    },
  },
  created() {
    this.tableColumns = [
      {
        label: this.$t('content.list.headers.name'),
        size: 1,
        width: '100%',
      },
      { label: this.$t('content.list.headers.type') },
      { label: this.$t('content.list.headers.lastDeployedTime') },
    ];
    return this.fetchData();
  },
  methods: {
    fetchData() {
      const timeoutId = setTimeout(() => {
        this.setStatus(
          'activity',
          this.$t('users.title.loadingPublishedContent')
        );
      }, 300);

      return getPublishedContentForUser(this.userGuid, {
        count: maxRecords,
        start: this.api.start,
        cont: this.api.continuation,
      })
        .then(({ applications, count, total, continuation }) => {
          this.api.applications = applications;
          this.api.count = count;
          this.api.total = total;
          this.api.continuation = continuation;
          this.hideStatus();
        })
        .catch(err => {
          this.setStatus('error', safeAPIErrorMessage(err));
        })
        .finally(() => {
          clearTimeout(timeoutId);
        });
    },
    setStatus(type, message) {
      this.status.type = type;
      this.status.message = message;
    },
    hideStatus() {
      this.setStatus(null, null);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    fromGit,
    gotoPage(direction) {
      switch (direction) {
        case 'first':
          this.api.start = 0;
          break;
        case 'previous':
          this.api.start -= maxRecords;
          break;
        case 'next':
          this.api.start += maxRecords;
          break;
        case 'last':
          this.api.start = Math.floor(this.api.total / maxRecords) * maxRecords;
          break;
      }
      return this.fetchData();
    },
  },
};
</script>
