<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__run-example wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.runExample.title') }}
      </StepHeading>
      <i18n-t
        keypath="publish.runExample.body.open"
        tag="p"
        class="wizard-panel__run-example-description"
      >
        <template #source>
          <code>{{ source }}</code>
        </template>
        <template #button>
          <span class="emphasize">{{ buttonAction }}</span>
        </template>
      </i18n-t>
      <img
        :src="imageSrc"
        :alt="$t('publish.runExample.alt')"
        class="wizard-panel__run-example-image"
      >
      <p class="wizard-panel__notice">
        <span class="emphasize">{{ $t('publish.runExample.body.warning.note') }}</span>
        {{ $t('publish.runExample.body.warning.text') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'RunExample',
  components: { StepHeading },
  mixins: [PanelMixin],
  computed: {
    ...mapState({
      example: state => state.publish.copyFrom,
    }),
    buttonAction() {
      return this.$t(`content.types.${this.example.type}.runAction`);
    },
    imageSrc() {
      const locale = this.$t('publish.runExample.screenshotLocale');
      return `images/jumpstart/screenshot-${this.example.type}.${locale}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.wizard-panel {
  &__run-example {
    &-description {
      margin-bottom: 0.5rem;
    }

    &-image {
      width: 100%;
    }
  }
}
</style>
